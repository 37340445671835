import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { TableBody, LinearProgress, Grid } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    selectable,
    onRequestSort,
    headRows,
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {selectable && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'Selecionar todos' }}
            />
          </TableCell>
        )}
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={row.numeric ? 'right' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              <Typography noWrap>{row.label}</Typography>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  selectable: PropTypes.bool,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headRows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected, title, renderActions } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected}
            {' registro(s) selecionado(s)'}
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {title}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>{renderActions && renderActions()}</div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  title: PropTypes.string,
  numSelected: PropTypes.number.isRequired,
  renderActions: PropTypes.func,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  grid: {
    padding: theme.spacing(2),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  subTitle: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  table: {},
  tableWrapper: {
    overflowX: 'auto',
  },
  noRecords: {
    wordBreak: 'normal',
  },
}));

function EnhancedTable(props) {
  const {
    title,
    headRows,
    renderCells,
    renderSubTitle,
    rows,
    loading,
    selectable,
    renderActions,
    onRowClick,
    onSelectionChange,
  } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      onSelectionChange(newSelecteds);
      return;
    }
    setSelected([]);
    onSelectionChange([]);
  }

  function handleClick(event, id) {
    if (onRowClick) {
      onRowClick(id);
    }
    if (!selectable) return;

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    onSelectionChange(newSelected);
  }

  // function handleChangePage(event, newPage) {
  //   setPage(newPage);
  // }

  // function handleChangeRowsPerPage(event) {
  //   setRowsPerPage(+event.target.value);
  // }

  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2} className={classes.grid}>
          <Grid item xs={12}>
            <EnhancedTableToolbar
              title={title}
              numSelected={selected.length}
              renderActions={renderActions}
            />
          </Grid>

          {renderSubTitle && (
            <Grid item xs={12}>
              <div className={classes.subTitle}>{renderSubTitle()}</div>
            </Grid>
          )}
          {loading && (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          )}

          {!loading && (
            <Grid item xs={12}>
              <div className={classes.tableWrapper}>
                {rows && rows.length > 0 && (
                  <Table className={classes.table} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                      headRows={headRows}
                      selectable={selectable}
                    />
                    <TableBody>
                      {/* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                      {stableSort(rows, getSorting(order, orderBy)).map(
                        (row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={event => handleClick(event, row.id)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              {selectable && (
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                </TableCell>
                              )}

                              {renderCells(row)}
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                )}

                {!rows ||
                  (rows.length === 0 && (
                    <Typography className={classes.noRecords}>
                      Não existem registros cadastrados.
                    </Typography>
                  ))}
              </div>
            </Grid>
          )}

          {/* <TablePagination
          style={{ ofLabel: 'a' }}
          rowsPerPageOptions={[]}
          component="div"
          count={rows.length}
          variant="caption"
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Página anterior',
          }}
          nextIconButtonProps={{
            'aria-label': 'Próxima página',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          // labelRowsPerPage="Resultados"
          // labelDisplayedRows={}
        /> */}
        </Grid>
      </Paper>
    </div>
  );
}

EnhancedTable.propTypes = {
  title: PropTypes.string,
  headRows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  renderCells: PropTypes.func.isRequired,
  renderSubTitle: PropTypes.func,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  renderActions: PropTypes.func,
  onRowClick: PropTypes.func,
  loading: PropTypes.bool,
  selectable: PropTypes.bool,
  onSelectionChange: PropTypes.func,
};

export default EnhancedTable;
