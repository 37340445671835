import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import PlanItem from './PlanItem';

const PlansList = memo(props => {
  const { plans, selected, onSelect } = props;
  return (
    <Grid container spacing={2} justify="center">
      {plans.map(plan => (
        <Grid item key={plan.id}>
          <PlanItem
            plan={plan}
            selected={plan === selected}
            onSelect={onSelect}
          />
        </Grid>
      ))}
    </Grid>
  );
});

PlansList.propTypes = {
  selected: PropTypes.shape({}),
  onSelect: PropTypes.func.isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PlansList;
