import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Paper, Grid, LinearProgress, Typography } from '@material-ui/core';
import CompanyStep from './CompanyStep';
import ManagerStep from './ManagerStep';
import PlansStep from './PlansStep';
import loadPlans from '../../operators/plansOperator';
import { signup } from '../../operators/signupOperator';
import ReviewStep from './ReviewStep';
import AgreementStep from './AgreementStep';
import UnloggedTopBar from '../unlogged-top-bar/UnloggedTopBar';

const getParamPlanId = props => {
  const parsed = queryString.parse(props.location.search);
  if (parsed.selectedPlan) return Number(parsed.selectedPlan);

  return undefined;
};

const useStyles = makeStyles(theme => {
  return {
    paper: {
      marginLeft: 'auto',
      marginRight: 'auto',

      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      width: '90%',

      [theme.breakpoints.up(600 + theme.spacing(3 * 2))]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        width: '80%',
      },

      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
        3
      )}px`,
    },
  };
});

const companyStep = {
  id: 0,
  key: 'COMPANY',
  label: 'Dados da empresa',
};

const managerStep = {
  id: 1,
  key: 'MANAGER',
  label: 'Dados do responsável',
};
const planStep = {
  id: 2,
  key: 'PLANS',
  label: 'Confirme seu Plano',
};

const agreementStep = {
  id: 3,
  key: 'AGREEMENT',
  label: 'Termos de Uso',
};

const reviewStep = {
  id: 4,
  key: 'REVIEW',
  label: 'Resumo',
};

const steps = [companyStep, managerStep, planStep, agreementStep, reviewStep];

const Signup = props => {
  const classes = useStyles();
  const { loadPlans, signup, plans, loadingPlans, loadingSignup } = props;

  const [state, setState] = useState({
    activeStep: companyStep.id,
  });

  const { activeStep, company, manager, plan } = state;

  function goToStep(step) {
    return () => setState({ ...state, activeStep: step });
  }

  function onCompanyStepComplete(company) {
    setState({ ...state, company, activeStep: managerStep.id });
  }

  function onManagerStepComplete(manager) {
    setState({ ...state, manager, activeStep: planStep.id });
  }

  function onPlanSelect(plan, goNext = true) {
    const newState = { ...state, plan };

    if (goNext) newState.activeStep = agreementStep.id;
    setState(newState);
  }

  function onAgreementAccept() {
    setState({ ...state, manager, activeStep: reviewStep.id });
  }

  function onBack() {
    setState({ ...state, activeStep: activeStep - 1 });
  }

  function onConfirm(reCaptcha) {
    signup({ ...state, reCaptcha });
  }

  useEffect(() => {
    loadPlans();
  }, [loadPlans]);

  useEffect(() => {
    if (plans) {
      const paramPlanId = getParamPlanId(props);
      const selected = plans.find(plan => plan.id === paramPlanId);

      if (selected) onPlanSelect(selected, false);
    }
  }, [plans]);

  const completed = ((activeStep + 1) * 100) / steps.length;

  return (
    <UnloggedTopBar>
      <Paper className={classes.paper}>
        {loadingPlans && <LinearProgress />}

        {!loadingPlans && plans && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                {steps[activeStep].label}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <LinearProgress variant="determinate" value={completed} />
            </Grid>
            <Grid item xs={12}>
              {activeStep === companyStep.id && (
                <CompanyStep company={company} onNext={onCompanyStepComplete} />
              )}

              {activeStep === managerStep.id && (
                <ManagerStep
                  manager={manager}
                  onNext={onManagerStepComplete}
                  onBack={onBack}
                />
              )}
              {activeStep === agreementStep.id && (
                <AgreementStep onNext={onAgreementAccept} onBack={onBack} />
              )}
              {activeStep === planStep.id && (
                <PlansStep
                  plan={plan}
                  onSelect={onPlanSelect}
                  onBack={onBack}
                />
              )}
              {activeStep === reviewStep.id && (
                <ReviewStep
                  company={company}
                  onEditCompany={goToStep(companyStep.id)}
                  manager={manager}
                  onEditManager={goToStep(managerStep.id)}
                  plan={plan}
                  onEditPlan={goToStep(planStep.id)}
                  onBack={onBack}
                  onConfirm={onConfirm}
                  loading={loadingSignup}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Paper>
    </UnloggedTopBar>
  );
};

Signup.propTypes = {
  loadPlans: PropTypes.func.isRequired,
  signup: PropTypes.func.isRequired,
  loadingPlans: PropTypes.bool,
  loadingSignup: PropTypes.bool,
  plans: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = state => ({
  loadingPlans: state.plans.loadingPlans,
  loadingSignup: state.signup.loadingSignup,
  plans: state.plans.plans,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadPlans,
      signup,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
