import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    border: '1px solid rgba(255, 255, 255, 0.12)',
    padding: 24,
    width: '100%',
  },
});
const ZeptoBorder = memo(props => {
  const { children } = props;
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
});

ZeptoBorder.propTypes = {
  children: PropTypes.node,
};

export default ZeptoBorder;
