import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import StepperButtonBar from '../../components/StepperButtonBar';
import PlansList from './PlansList';

const PlansStep = memo(props => {
  const [selected, setSelected] = useState(undefined);
  const { plan, onSelect, plans, onBack } = props;

  function onNextClick() {
    onSelect(selected);
  }

  function onSelectPlan(plan) {
    setSelected(plan);
  }

  useEffect(() => setSelected(plan), [plan]);

  return (
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <PlansList plans={plans} onSelect={onSelectPlan} selected={selected} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" component="h2" align="center">
          Pagamento via Boleto bancário. Cancele a qualquer momento.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <StepperButtonBar
          onBack={onBack}
          onNext={onNextClick}
          nextEnabled={!!selected}
        />
      </Grid>
    </Grid>
  );
});

PlansStep.propTypes = {
  plans: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  plan: PropTypes.shape({}),
  onSelect: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  plans: state.plans.plans,
});

export default connect(mapStateToProps)(PlansStep);
