import React, { useState, useEffect } from 'react';
import { Grid, LinearProgress, MenuItem } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { loadUsers, generateReport } from '../../../operators/usersOperator';
import { browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import store from '../../../store/store';
import {
  generateAFD,
  generateAFDT,
  generateACJEF,
  generateAgreementTerm,
} from '../../../operators/companyReportOperator';
import { showWarn } from '../../../operators/appOperator';
import ZeptoButton from '../../../components/ZeptoButton';
import UserList from '../../users/list/UserList';
import { user } from '../../../model/User';
import { ZeptoSelect, ZeptoPaper } from '../../../components';
const history = syncHistoryWithStore(browserHistory, store);

const POINTS = 'POINTS';
const RD = 'RD';
const AFD = 'AFD';
const AFDT = 'AFDT';
const ACJEF = 'ACJEF';
const AGREEMENT_TERM = 'AGREEMENT_TERM';

const pointsReport = { id: POINTS, label: 'Relatório de folha de ponto' };
const reports = [
  pointsReport,
  { id: RD, label: 'Relatório de Ponto Resumido' },
  { id: AFD, label: 'Arquivo-Fonte de Dados' },
  { id: AFDT, label: 'Arquivo-Fonte de Dados Tratados' },
  { id: ACJEF, label: 'Arquivo de Controle de Jornada para Efeitos Fiscais' },
  { id: AGREEMENT_TERM, label: 'Termo de aceite' },
];

const Report = props => {
  const [report, setReport] = useState(POINTS);
  const [ids, setIds] = useState([]);
  const [start, setStart] = useState(moment().startOf('month'));
  const [end, setEnd] = useState(moment());

  const {
    company,
    authenticatedUser,
    loadUsers,
    processingLoadUsers,
    processingGenerateReport,
    showWarn,
    users,
    generateAFD,
    generateAFDT,
    generateACJEF,
    generateAgreementTerm,
    generateReport,
    loadingAFD,
    loadingAFDT,
    loadingACJEF,
    loadingAgreementTerm,
  } = props;

  useEffect(() => {
    if (company && authenticatedUser.role !== 'USER') {
      loadUsers(company.id, user, true);
    } else if (authenticatedUser.role === 'USER') {
      history.goBack();
    }
  }, [company]);

  function onGenerateClick() {
    if (ids.length === 0) {
      showWarn('Escolha pelo menos um funcionário.');
      return;
    }

    const companyId = company ? company.id : authenticatedUser.companyId;

    switch (report) {
      case POINTS:
        generateReport(companyId, ids, start, end, 'points');
        break;
      case RD:
        generateReport(companyId, ids, start, end, 'reduced_points');
        break;
      case AFD:
        generateAFD(companyId, ids, start, end);
        break;
      case AFDT:
        generateAFDT(companyId, ids, start, end);
        break;
      case ACJEF:
        generateACJEF(companyId, ids, start, end);
        break;
      case AGREEMENT_TERM:
        generateAgreementTerm(companyId, ids);
        break;

      default:
        throw new Error('Something is wrong!');
    }
  }

  const loading =
    loadingAFD ||
    loadingAFDT ||
    loadingACJEF ||
    loadingAgreementTerm ||
    processingGenerateReport;

  const reportList = reports;
  const userList = users;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {loading && <LinearProgress />}
      </Grid>
      <Grid item xs={12}>
        <ZeptoPaper>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ZeptoSelect
                required
                label="Relatório"
                value={report}
                onChange={e => setReport(e.target.value)}
              >
                {reportList.map(r => (
                  <MenuItem key={r.id} value={r.id}>
                    {r.label}
                  </MenuItem>
                ))}
              </ZeptoSelect>
            </Grid>

            {report !== AGREEMENT_TERM && (
              <>
                <Grid item xs={12} sm={6} md={6}>
                  <KeyboardDatePicker
                    label="Data de Início"
                    autoOk
                    fullWidth
                    ampm={false}
                    format="DD/MM/YYYY"
                    value={start !== null ? moment(start, 'DD/MM/YYYY') : null}
                    onChange={date => setStart(date)}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <KeyboardDatePicker
                    label="Data de fim"
                    autoOk
                    fullWidth
                    ampm={false}
                    format="DD/MM/YYYY"
                    value={end !== null ? moment(end, 'DD/MM/YYYY') : null}
                    onChange={date => setEnd(date)}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <ZeptoButton
                style={{ float: 'right' }}
                label="Gerar"
                loading={loading}
                onClick={onGenerateClick}
              />
            </Grid>
          </Grid>
        </ZeptoPaper>
      </Grid>
      <Grid item xs={12}>
        <UserList
          selectable
          loading={processingLoadUsers}
          onSelectionChange={ids => setIds(ids)}
          users={userList}
        />
      </Grid>

      <Grid item xs={12}>
        {loading && <LinearProgress />}
      </Grid>
    </Grid>
  );
};

Report.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})),
  company: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  loadUsers: PropTypes.func.isRequired,
  generateReport: PropTypes.func.isRequired,
  showWarn: PropTypes.func.isRequired,
  processingLoadUsers: PropTypes.bool,
  processingGenerateReport: PropTypes.bool,
  generateAFD: PropTypes.func.isRequired,
  generateAFDT: PropTypes.func.isRequired,
  generateACJEF: PropTypes.func.isRequired,
  loadingAFD: PropTypes.bool,
  loadingAFDT: PropTypes.bool,
  loadingACJEF: PropTypes.bool,
  loadingAgreementTerm: PropTypes.bool,
};

const mapStateToProps = state => ({
  users: state.users.users,
  authenticatedUser: state.auth.authenticatedUser,
  company: state.companies.selected,
  processingLoadUsers: state.users.processingLoadUsers,
  processingGenerateReport: state.users.processingGenerateReport,
  loadingAFD: state.companyReports.loadingAFD,
  loadingAFDT: state.companyReports.loadingAFDT,
  loadingACJEF: state.companyReports.loadingACJEF,
  loadingAgreementTerm: state.companyReports.loadingAgreementTerm,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadUsers,
      generateReport,
      showWarn,
      generateAFD,
      generateAFDT,
      generateACJEF,
      generateAgreementTerm,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Report);
