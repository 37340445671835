import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Circle,
  Marker,
} from 'react-google-maps';
import { Typography } from '@material-ui/core';
import React, { useState, memo } from 'react';
import { PropTypes } from 'prop-types';
import { ZeptoLocation } from '.';

const MapComponent = withScriptjs(
  withGoogleMap(
    memo(props => {
      const { position, onChange, userLocation } = props;

      const [ref, setRef] = useState(undefined);

      function onPositionChange() {
        const center = ref.getCenter();
        onChange({
          radius: ref.getRadius(),
          latitude: center.lat(),
          longitude: center.lng(),
        });
      }
      return (
        <GoogleMap
          defaultZoom={17}
          defaultCenter={{
            lat: Number(position.latitude),
            lng: Number(position.longitude),
          }}
          defaultOptions={{
            mapTypeControl: false,
            streetViewControl: false,
            zoomControl: false,
            fullscreenControl: false,
          }}
        >
          <Marker
            position={{
              lat: Number(userLocation.latitude),
              lng: Number(userLocation.longitude),
            }}
          />
          <Circle
            defaultCenter={{
              lat: Number(position.latitude),
              lng: Number(position.longitude),
            }}
            radius={position.radius}
            editable
            draggable
            onRadiusChanged={onPositionChange}
            onCenterChanged={onPositionChange}
            ref={setRef}
          />
        </GoogleMap>
      );
    })
  )
);

MapComponent.propTypes = {
  position: PropTypes.shape({
    latitude: PropTypes.string.isRequired,
    longitude: PropTypes.string.isRequired,
    radius: PropTypes.number.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

const ZeptoPositionPicker = props => {
  const { position, onChange } = props;

  const [positionLoadError, setPositionLoadError] = useState(false);
  const [userLocation, setUserLocation] = useState(undefined);

  function onPositionLoad(newPosition) {
    if (
      !position ||
      position.latitude === null ||
      position.longitude === null
    ) {
      onChange({
        latitude: newPosition.coords.latitude,
        longitude: newPosition.coords.longitude,
        radius: 100,
      });
    }

    setUserLocation({
      latitude: newPosition.coords.latitude,
      longitude: newPosition.coords.longitude,
    });
  }

  if (positionLoadError)
    return <Typography>Não foi possível carregar a localização.</Typography>;

  if (!userLocation) {
    return (
      <ZeptoLocation
        onLoad={onPositionLoad}
        onCancel={() => setPositionLoadError(true)}
      />
    );
  }

  return (
    <MapComponent
      {...props}
      userLocation={userLocation}
      position={position}
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBHOri2pRdI5cwDTgC8n2tFWms90Ddxgyg&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<div style={{ height: '100%' }} />}
      containerElement={<div style={{ height: '300px' }} />}
      mapElement={<div style={{ height: '100%' }} />}
    />
  );
};

ZeptoPositionPicker.propTypes = {
  position: PropTypes.shape({
    latitude: PropTypes.string.isRequired,
    longitude: PropTypes.string.isRequired,
    radius: PropTypes.number.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(ZeptoPositionPicker);
