import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { Grid, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { observer } from 'mobx-react';

import { ZeptoDatePicker, ZeptoFab } from '../../../components';
import CompanyStore from '../../../stores/CompanyStore';
import HolidayStore from '../../../stores/HolidayStore';
import HolidayForm from './HolidayForm';
import { dateFormat } from '../../../utils/Constants';

const search = '1';
const form = '2';

const useStyles = makeStyles(theme => ({
  fab1: {
    margin: theme.spacing(1),
  },
}));

const CompanyHolidayActions = observer(props => {
  const classes = useStyles();

  const holidayStore = useContext(HolidayStore);
  const companyStore = useContext(CompanyStore);

  const [mode, setMode] = useState(search);
  const [selected, setSelected] = useState(null);
  const [date, setDate] = useState(moment());

  const { inEdition } = props;

  const {
    creatingHoliday,
    updatingHoliday,
    lastSaved,
    loadingHolidays,
  } = holidayStore;

  const { company } = companyStore;

  function onAdd() {
    setMode(form);
    setSelected({ date: moment() });
  }

  function onCancelEdit() {
    setMode(search);
  }

  function onSaveHoliday(holiday) {
    if (holiday.id) holidayStore.update(holiday);
    else holidayStore.create({ ...holiday, companyId: company.id });
  }

  useEffect(() => {
    if (!lastSaved) setMode(search);
  }, [lastSaved]);

  useEffect(() => {
    holidayStore.loadHolidays(company.id, date.year());
  }, [date]);

  useEffect(() => {
    if (inEdition) {
      setMode(form);
      setSelected({ ...inEdition, date: moment(inEdition.date, dateFormat) });
      window.scrollTo(0, 0);
    }
  }, [inEdition]);

  if (mode === form)
    return (
      <HolidayForm
        holiday={selected}
        onSave={onSaveHoliday}
        onCancel={onCancelEdit}
        loading={creatingHoliday || updatingHoliday}
        success={!!lastSaved}
      />
    );

  return (
    <Grid container alignItems="center" justify="space-between">
      <Grid item xs={4}>
        <ZeptoDatePicker
          value={date}
          onChange={setDate}
          disabled={loadingHolidays}
          views={['year']}
          format="YYYY"
          label="Ano"
        />
      </Grid>

      <Grid item xs={4}>
        <Grid container justify="flex-end">
          <Grid item className={classes.fab1}>
            <ZeptoFab onClick={onAdd} icon={<AddIcon />} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

CompanyHolidayActions.propTypes = {};

export default CompanyHolidayActions;
