import React, { useContext } from 'react';

import { IconButton } from '@material-ui/core';
import { observer } from 'mobx-react';
import Brightness5Icon from '@material-ui/icons/Brightness7';
import Brightness2Icon from '@material-ui/icons/Brightness2';

import AppStore, { DARK_THEME, LIGHT_THEME } from '../../stores/AppStore';

const ThemeSelect = observer(() => {
  const { theme, setTheme } = useContext(AppStore);

  function toggleTheme() {
    const newTheme = theme === DARK_THEME ? LIGHT_THEME : DARK_THEME;

    setTheme(newTheme);
  }

  return (
    <IconButton aria-haspopup="true" onClick={toggleTheme} color="inherit">
      {theme === DARK_THEME && <Brightness5Icon />}
      {theme === LIGHT_THEME && <Brightness2Icon />}
    </IconButton>
    // <Typography component="div" className={classes.root}>
    //   <ZeptoSwitcher
    //     checked={theme === DARK_THEME}
    //     onChange={changeTheme}
    //     label="Tema:"
    //     optionA={<Brightness5Icon />}
    //     optionB={<Brightness2Icon />}
    //   />
    // </Typography>
  );
});

ThemeSelect.propTypes = {};

export default ThemeSelect;
