import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReplayIcon from '@material-ui/icons/Replay';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/styles';
import Camera from 'react-html5-camera-photo';
import downscaleImage from '../utils/ImageUtils';

const useStyles = makeStyles({
  cameraParent: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 9000,
    backgroundColor: 'black',
    overflow: 'hidden',
    backgroundSize: 'cover',
  },
  cancelButtonParent: {
    zIndex: 9001,
    position: 'fixed',
    color: 'white',
  },
  cancelButton: {
    fontSize: 40,
  },

  button: {
    color: 'white',
    fontSize: 40,
  },
  preview: {
    maxWidth: 760,
    width: '100%',
    margin: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  buttonBar: {
    bottom: 20,
    position: 'absolute',
    left: '50%',
    zIndex: 9001,
  },
});

const ZeptoCamera = props => {
  const classes = useStyles();
  const { onCancel, onTakePhoto } = props;

  const [state, setState] = useState({
    photo: undefined,
  });

  const { photo } = state;

  function onTakePhotoClick(photo) {
    setState({ ...state, photo });
  }

  async function resizePhoto() {
    downscaleImage(state.photo).then(newData => onTakePhoto(newData));
  }

  function onConfirmClick() {
    resizePhoto();
  }

  async function onRetakeClick() {
    setState({ ...state, photo: null });
  }

  return (
    <div className={classes.cameraParent}>
      <IconButton onClick={onCancel} className={classes.cancelButtonParent}>
        <CloseIcon className={classes.cancelButton} />
      </IconButton>

      {!photo && (
        <Camera
          style={{ height: '100vh' }}
          onTakePhoto={onTakePhotoClick}
          isSilentMode
        />
      )}

      {photo && (
        <div>
          <div className={classes.buttonBar}>
            <div style={{ position: 'relative', left: '-50%' }}>
              <IconButton onClick={onRetakeClick}>
                <ReplayIcon className={classes.button} />
              </IconButton>
              <IconButton onClick={onConfirmClick}>
                <CheckIcon className={classes.button} />
              </IconButton>
            </div>
          </div>
          <img className={classes.preview} src={photo} alt="" />
        </div>
      )}
    </div>
  );
};

ZeptoCamera.propTypes = {
  onTakePhoto: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default memo(ZeptoCamera);
