import { createActions, createReducer } from 'reduxsauce';

/* #region Action types & creators */
export const { Types, Creators } = createActions({
  clearCNPJCheck: null,
  clearUserCheck: null,
  clearLastError: null,

  checkCNPJRequest: null,
  checkCNPJSuccess: ['cnpjCheck'],
  checkCNPJFailure: null,

  checkUserRequest: null,
  checkUserSuccess: ['userCheck'],
  checkUserFailure: null,

  signupRequest: null,
  signupSuccess: null,
  signupFailure: ['error'],
});
/* #endregion */

/* #region Handlers */
const INITIAL_STATE = {};

/* #region checkCNPJ */
const clearCNPJCheck = (state = INITIAL_STATE) => ({
  ...state,
  cnpjCheck: undefined,
});

const checkCNPJRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingcheckCNPJ: true,
});

const checkCNPJSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingcheckCNPJ: false,
  cnpjCheck: action.cnpjCheck,
});

const checkCNPJFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingcheckCNPJ: false,
});
/* #endregion */

/* #region checkCNPJ */

const clearUserCheck = (state = INITIAL_STATE) => ({
  ...state,
  userCheck: undefined,
});

const checkUserRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingCheckUser: true,
});

const checkUserSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingCheckUser: false,
  userCheck: action.userCheck,
});

const checkUserFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingCheckUser: false,
});
/* #endregion */

/* #region signup */

const signupRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingSignup: true,
});

const signupSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loadingSignup: false,
});

const signupFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingSignup: false,
  lastError: action.error,
});
/* #endregion */

const clearLastError = (state = INITIAL_STATE) => ({
  ...state,
  lastError: undefined,
});

export default createReducer(INITIAL_STATE, {
  [Types.CLEAR_CNPJ_CHECK]: clearCNPJCheck,
  [Types.CLEAR_USER_CHECK]: clearUserCheck,
  [Types.CLEAR_LAST_ERROR]: clearLastError,

  [Types.CHECK_CNPJ_REQUEST]: checkCNPJRequest,
  [Types.CHECK_CNPJ_SUCCESS]: checkCNPJSuccess,
  [Types.CHECK_CNPJ_FAILURE]: checkCNPJFailure,

  [Types.CHECK_USER_REQUEST]: checkUserRequest,
  [Types.CHECK_USER_SUCCESS]: checkUserSuccess,
  [Types.CHECK_USER_FAILURE]: checkUserFailure,

  [Types.SIGNUP_REQUEST]: signupRequest,
  [Types.SIGNUP_SUCCESS]: signupSuccess,
  [Types.SIGNUP_FAILURE]: signupFailure,
});
