import 'react-app-polyfill/stable';
import React, { memo } from 'react';
import { Provider } from 'react-redux';
import { Router, Route, browserHistory, IndexRedirect } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import Login from './login/Login';
import Main from './Main';
import Me from './users/Me';
import CompaniesList from './companies/CompaniesList';
import EmployersList from './users/employers/EmployersList';
import CompaniesViewer from './companies/viewer/CompanyViewer';
import CompanyForm from './companies/CompanyForm';
import CompanyJourneyForm from './companies/journeys/form/CompanyJourneyForm';
import UserForm from './users/UserForm';
import PointForm from './point/PointForm';
import store from '../store/store';
import 'moment/locale/pt-br';
import ZeptoSnackbar from '../components/ZeptoSnackbar';
import setUpRules from '../utils/ValidatorUtils';
import ZeptoTheme from '../styles/ZeptoTheme';
import RequestForm from './request/RequestForm';
import AllowanceForm from './users/allowances/AllowanceForm';
import CompanyUsersList from './companies/viewer/CompanyUsersList';
import CompanyDaily from './companies/viewer/CompanyDaily';
import CompanyDashboard from './companies/viewer/CompanyDashboard';
import CompanyJourneysList from './companies/viewer/CompanyJourneysList';
import CompanyReport from './companies/report/CompanyReport';
import UserViewer from './users/UserViewer';
import Signup from './signup/Signup';
import CompanyFinance from './companies/finance/CompanyFinance';
import CompanyHolidays from './companies/holiday/CompanyHolidays';
import RedefinePassword from './login/RedefinePassword';
import CompanySettings from './companies/settings/CompanySettings';
import CompanyClosures from './companies/closure/CompanyClosures';
import Website from './website/Website';

setUpRules();

const history = syncHistoryWithStore(browserHistory, store);

const handleLoggedOut = (nextState, replace) => {
  if (!store.getState().auth.isAuthenticated) {
    replace({
      pathname: '/login',
      state: { nextPathname: nextState.location.pathname },
    });
  }
};

const handleLoggedIn = (nextState, replace) => {
  if (store.getState().auth.isAuthenticated) {
    replace({
      pathname: '/me',
      state: { nextPathname: nextState.location.pathname },
    });
  }
};

const closeMobile = () => {
  window.ReactNativeWebView.postMessage(
    JSON.stringify({ type: 'MINIMIZE_APP' })
  );
};

const onMobileNavigationBack = () => {
  if (history.getCurrentLocation().pathname === '/me') {
    closeMobile();
  } else {
    history.goBack();
  }
};

window.onMobileNavigationBack = onMobileNavigationBack;

function App() {
  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        locale="pt-br"
        libInstance={moment}
      >
        <ZeptoTheme>
          <ZeptoSnackbar />
          <Router history={history}>
            <Route
              path="/"
              component={Website}
              exact
              onEnter={handleLoggedIn}
            />
            <Route path="/login" component={Login} onEnter={handleLoggedIn} />
            <Route path="/signup" component={Signup} />
            <Route
              path="/redefine-password/(:token)"
              component={RedefinePassword}
            />
            <Route path="/" component={Main} onEnter={handleLoggedOut}>
              <IndexRedirect to="me" />
              <Route path="me" component={Me} />
              <Route path="me/register" component={PointForm} />
              <Route path="me/register/request" component={RequestForm} />
              <Route path="employers" component={EmployersList} />
              <Route path="companies" component={CompaniesList} />

              <Route path="/companies/:id/reports" component={CompanyReport} />

              <Route path="/companies/:id/edit" component={CompanyForm} />
              <Route path="/companies/:id" component={CompaniesViewer}>
                <IndexRedirect to="/companies/:id/daily" />
                
                <Route path="/companies/:id/daily" component={CompanyDaily} />

                <Route path="/companies/:id/dashboard" component={CompanyDashboard} />

                <Route
                  path="/companies/:id/users"
                  component={CompanyUsersList}
                />

                <Route
                  path="/companies/:id/journeys"
                  component={CompanyJourneysList}
                />
                <Route
                  path="/companies/:id/finance"
                  component={CompanyFinance}
                />
                <Route
                  path="/companies/:id/holidays"
                  component={CompanyHolidays}
                />
                <Route
                  path="/companies/:id/settings"
                  component={CompanySettings}
                />
                <Route
                  path="/companies/:id/closures"
                  component={CompanyClosures}
                />
              </Route>
              <Route
                path="companies/:id/journeys/:journeyId/edit"
                component={CompanyJourneyForm}
              />
              <Route path="users/:id/view" component={UserViewer} />
              <Route path="users/:id/edit" component={UserForm} />
              <Route
                path="users/:id/allowances/new"
                component={AllowanceForm}
              />
              <Route path="report" component={CompanyReport} />
            </Route>
          </Router>
        </ZeptoTheme>
      </MuiPickersUtilsProvider>
    </Provider>
  );
}
export default memo(App);
