import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardHeader, IconButton, CardContent } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const ZeptoActionCard = memo(props => {
  const { avatar, title, subheader, onEdit, children, enabled } = props;
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={avatar}
        action={
          <IconButton
            aria-label="settings"
            onClick={onEdit}
            disabled={!enabled}
          >
            <EditIcon />
          </IconButton>
        }
        title={title}
        subheader={subheader}
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
});

ZeptoActionCard.propTypes = {
  enabled: PropTypes.bool,
  avatar: PropTypes.node,
  title: PropTypes.string,
  subheader: PropTypes.string,
  onEdit: PropTypes.func,
  children: PropTypes.node,
};

export default ZeptoActionCard;
