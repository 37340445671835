import React, { useState, useEffect, useContext } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react';
import { LinearProgress } from '@material-ui/core';

import { ZeptoButton, ZeptoPaper } from '../../../../components';
import useValidator from '../../../../hooks/useValidator';

import CompanyJourneyStore from '../../../../stores/CompanyJourneyStore';

import JourneyInput from './JourneyInput';
import { journeyToForm, journeyToJson } from '../../../../utils/JourneyUtils';
import { timeFormat } from '../../../../utils/Constants';
import AppStore from '../../../../stores/AppStore';

const RULES = {
  journey: {
    name: 'required|min:1|max:20',
    shiftTime: 'required|time',
    type: 'required',
    'days.*.periods.*.start': 'required|time',
    'days.*.periods.*.end': 'required|time',
  },
};

const mindnight = new Date();
mindnight.setHours(0, 0, 0, 0);

const initial = {
  journey: {
    name: '',
    type: '',
    shiftTime: moment('00:00', timeFormat),
    days: [],
  },
};

const JourneyForm = props => {
  // -------------------------------
  // Propriedades
  // -------------------------------

  const [data, setData] = useState(initial);

  const {
    companyJourney,
    loadCompanyJourney,
    clearCompanyJourney,
    loadingCompanyJourney,
    creatingCompanyJourney,
    updatingCompanyJourney,
    update,
    create,
  } = useContext(CompanyJourneyStore);

  const { showWarn } = useContext(AppStore);

  const { params } = props;

  useEffect(() => {
    const id = Number(params.journeyId);
    const companyId = Number(params.id);

    if (id) loadCompanyJourney(companyId, id);

    return clearCompanyJourney;
  }, []);

  useEffect(() => {
    if (companyJourney) {
      setData(journeyToForm({ ...companyJourney }));
    }
  }, [companyJourney]);

  const loading =
    loadingCompanyJourney || creatingCompanyJourney || updatingCompanyJourney;

  const [isValid, getError] = useValidator(data, RULES);

  // -------------------------------
  // Operações
  // -------------------------------

  function handleSubmit() {
    if (!isValid()) return;

    if (data.journey.days.length === 0) {
      showWarn('Adicione pelo menos um dia.');
      return;
    }

    const newData = { ...journeyToJson(data), companyId: Number(params.id) };
    if (newData.id) {
      update(newData);
    } else {
      create(newData);
    }
  }

  function onChangeJourney(newData) {
    setData({ ...data, journey: newData });
  }

  return (
    <ZeptoPaper>
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={12}>
          {loading && <LinearProgress />}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Formulário de Jornada
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <JourneyInput
            value={data.journey}
            onChange={onChangeJourney}
            errors={getError}
            disabled={loading}
          />
        </Grid>

        <Grid item xs={12}>
          {loading && <LinearProgress />}
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item>
              <ZeptoButton
                name="type"
                label="Salvar"
                loading={loading}
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ZeptoPaper>
  );
};

JourneyForm.propTypes = {
  // errorMessage: PropTypes.string,
  params: PropTypes.shape({
    id: PropTypes.string,
    journeyId: PropTypes.string,
  }).isRequired,
  // processingLoadJourney: PropTypes.bool,
  // processingCreateJourney: PropTypes.bool,
  // processingUpdateJourney: PropTypes.bool,
  // editJourney: PropTypes.func.isRequired,
  // createJourney: PropTypes.func.isRequired,
  // updateJourney: PropTypes.func.isRequired,
  // selected: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  // processingLoadJourney: state.journeys.processingLoadJourney,
  // processingCreateJourney: state.journeys.processingCreateJourney,
  // processingUpdateJourney: state.journeys.processingUpdateJourney,
  // selected: state.journeys.selected,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(observer(JourneyForm));
