import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import CompanyUserPointsList from './CompanyUserPointsList';
import CompanyRequests from '../requests/CompanyRequests';

const CompanyDaily = props => {
  const { company } = props;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CompanyRequests company={company} />
      </Grid>
      <Grid item xs={12}>
        <CompanyUserPointsList company={company} />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = store => ({
  company: store.companies.selected,
});

CompanyDaily.propTypes = {
  company: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps, null)(memo(CompanyDaily));
