import { createContext } from 'react';
import { decorate, observable } from 'mobx';
import { jsonFetch, jsonPut } from '../utils/http';
import { appStore } from './AppStore';

const apiPath = companyId =>
  `${process.env.REACT_APP_API_PATH}/api/companies/${companyId}/billing-data`;

class BillingDataStore {
  billingData;

  loadingBillingData;

  updatingBillingData;

  load = async companyId => {
    this.loadingBillingData = true;
    try {
      this.billingData = await jsonFetch(apiPath(companyId));
    } catch (e) {
      appStore.showError(
        `Ocorreu um erro ao carregar as informações de cobrança: ${e.message}`
      );
    }
    this.loadingBillingData = false;
  };

  clear = () => {
    this.billingData = null;
  };

  update = async (companyId, billingData) => {
    this.updatingBillingData = true;

    try {
      this.billingData = await jsonPut(apiPath(companyId), billingData);

      appStore.showSuccess('Dados de cobrança atualizados com sucesso.');
    } catch (e) {
      appStore.showError(
        `Ocorreu um erro ao atualizar os dados de cobrança: ${e.message}`
      );
    }

    this.updatingBillingData = false;
  };
}

decorate(BillingDataStore, {
  billingData: observable,
  loadingBillingData: observable,
  updatingBillingData: observable,
});

export const billingDataStore = new BillingDataStore();

export default createContext(billingDataStore);
