import { createContext } from 'react';
import { decorate, observable } from 'mobx';
import { jsonFetch, jsonPost, jsonPut, jsonDelete } from '../utils/http';
import { appStore } from './AppStore';

const apiPath = companyId =>
  `${process.env.REACT_APP_API_PATH}/api/companies/${companyId}/journeys`;

class CompanyJourneyStore {
  companyJourneys = [];

  companyJourney;

  loadingCompanyJourneys;

  loadingCompanyJourney;

  creatingCompanyJourney;

  updatingCompanyJourney;

  disablingCompanyJourney;

  enablingCompanyJourney;

  lastSaved;

  loadCompanyJourneys = async companyId => {
    this.loadingJourneys = true;
    try {
      this.companyJourneys = await jsonFetch(`${apiPath(companyId)}`);
    } catch (e) {
      appStore.showError(
        `Ocorreu um erro ao carregar as jornadas da empresa: ${e.message}`
      );
    }
    this.loadingJourneys = false;
  };

  clearCompanyJourneys = () => {
    this.companyJourneys = null;
  };

  loadCompanyJourney = async (companyId, id) => {
    this.loadingCompanyJourney = true;
    try {
      this.companyJourney = await jsonFetch(`${apiPath(companyId)}/${id}`);
    } catch (e) {
      appStore.showError(`Ocorreu um erro ao carregar a jornada: ${e.message}`);
    }
    this.loadingCompanyJourney = false;
  };

  create = async companyJourney => {
    this.creatingCompanyJourney = true;

    try {
      this.lastSaved = await jsonPost(
        `${apiPath(companyJourney.companyId)}`,
        companyJourney
      );

      appStore.showSuccess('Jornada cadastrada com sucesso.');
    } catch (e) {
      appStore.showError(
        `Ocorreu um erro ao cadastrar a jornada: ${e.message}`
      );
    }

    this.creatingCompanyJourney = false;
  };

  update = async companyJourney => {
    this.updatingCompanyJourney = true;

    try {
      this.lastSaved = await jsonPut(
        `${apiPath(companyJourney.companyId)}/${companyJourney.id}`,
        companyJourney
      );

      appStore.showSuccess('Jornada atualizada com sucesso.');

      if (this.companyJourneys) {
        const index = this.companyJourneys.findIndex(
          h => h.id === this.lastSaved.id
        );

        if (index !== -1) {
          this.companyJourneys.splice(index, 1, this.lastSaved);
        }
      }
    } catch (e) {
      appStore.showError(
        `Ocorreu um erro ao atualizar a jornada: ${e.message}`
      );
    }

    this.updatingCompanyJourney = false;
  };

  disable = async companyJourney => {
    this.disablingCompanyJourney = true;

    try {
      await jsonDelete(
        `${apiPath(companyJourney.companyId)}/${companyJourney.id}`
      );

      if (this.companyJourneys) {
        const disabled = this.companyJourneys.find(
          compJourney => compJourney.id === companyJourney.id
        );
        if (disabled) disabled.enabled = false;

        appStore.showSuccess('Jornada apagada com sucesso');
      }
    } catch (e) {
      appStore.showError(`Ocorreu um erro ao apagar a jornada: ${e.message}`);
    }

    this.disablingCompanyJourney = false;
  };

  enable = async companyJourney => {
    this.enablingCompanyJourney = true;

    try {
      await jsonPut(
        `${apiPath(companyJourney.companyId)}/${companyJourney.id}/enable`
      );

      if (this.companyJourneys) {
        const enabled = this.companyJourneys.find(
          compJourney => compJourney.id === companyJourney.id
        );
        if (enabled) enabled.enabled = true;
        appStore.showSuccess('Jornada restaurada com sucesso');
      }
    } catch (e) {
      appStore.showError(
        `Ocorreu um erro ao restaurar a jornada: ${e.message}`
      );
    }

    this.enablingCompanyJourney = false;
  };

  clearCompanyJourney = () => {
    this.companyJourney = null;
  };
}

decorate(CompanyJourneyStore, {
  companyJourneys: observable,
  companyJourney: observable,
  loadingCompanyJourneys: observable,
  loadingCompanyJourney: observable,
  creatingCompanyJourney: observable,
  updatingCompanyJourney: observable,
  disablingCompanyJourney: observable,
  enablingCompanyJourney: observable,
  lastSaved: observable,
});

export const companyJourneyStore = new CompanyJourneyStore();

export default createContext(companyJourneyStore);
