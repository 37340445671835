import { createActions, createReducer } from 'reduxsauce';

/* #region Action types & creators */
export const { Types, Creators } = createActions({
  loginRequest: null,
  loginSuccess: ['token'],
  loginFailure: ['error'],
  authenticatedRequest: null,
  authenticatedSuccess: ['user'],
  authenticatedFailure: ['error'],

  forgotPasswordRequest: null,
  forgotPasswordSuccess: null,
  forgotPasswordFailure: null,
  clearLastForgotPasswordSuccess: null,

  validateRedefinePasswordTokenRequest: null,
  validateRedefinePasswordTokenSuccess: ['result'],
  validateRedefinePasswordTokenFailure: null,
  clearRedefinePasswordTokenValidation: null,

  redefinePasswordRequest: null,
  redefinePasswordSuccess: ['result'],
  redefinePasswordFailure: null,

  logout: null,
  onUserUploadPhoto: null,
});
/* #endregion */

/* #region Handlers */
const INITIAL_STATE = {
  loading: false,
  isAuthenticated: !!localStorage.getItem('id_token'),
  token: localStorage.getItem('id_token'),
};

/* #region Login */
const loginRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  isAuthenticated: false,
});

const loginSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  isAuthenticated: true,
  errorMessage: '',
  token: action.token,
});

const loginFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  isAuthenticated: false,
  errorMessage: action.error,
});
/* #endregion */

/* #region Authenticated User */
const authenticatedRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const authenticatedSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  errorMessage: '',
  authenticatedUser: action.user,
});

const authenticatedFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  errorMessage: action.error,
});
/* #endregion */

/* #region Forgot password */
const forgotPasswordRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingForgotPassword: true,
});

const forgotPasswordSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loadingForgotPassword: false,
  lastForgotPasswordSucess: new Date(),
});

const forgotPasswordFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingForgotPassword: false,
});

const clearLastForgotPasswordSuccess = (state = INITIAL_STATE) => ({
  ...state,
  lastForgotPasswordSucess: null,
});
/* #endregion */

/* #region Validate Forgot password token */
const validateRedefinePasswordTokenRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingValidateRedefinePasswordToken: true,
});

const validateRedefinePasswordTokenSuccess = (
  state = INITIAL_STATE,
  action
) => ({
  ...state,
  loadingValidateRedefinePasswordToken: false,
  redefinePasswordTokenValidation: action.result,
});

const validateRedefinePasswordTokenFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingValidateRedefinePasswordToken: false,
});

const clearRedefinePasswordTokenValidation = (state = INITIAL_STATE) => ({
  ...state,
  redefinePasswordTokenValidation: null,
});
/* #endregion */

/* #region Redefine password region */
const redefinePasswordRequest = (state = INITIAL_STATE) => ({
  ...state,
  redefiningPassword: true,
});

const redefinePasswordSuccess = (state = INITIAL_STATE) => ({
  ...state,
  redefiningPassword: false,
  lastPasswordRedefinition: new Date(),
});

const redefinePasswordFailure = (state = INITIAL_STATE) => ({
  ...state,
  redefiningPassword: false,
});

/* #endregion */

const logout = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  isAuthenticated: false,
  authenticatedUser: null,
});

/* #endregion */

const onUserUploadPhoto = (state = INITIAL_STATE) => ({
  ...state,
  authenticatedUser: {
    ...state.authenticatedUser,
    hasPhoto: true,
  },
});

export default createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,

  [Types.AUTHENTICATED_REQUEST]: authenticatedRequest,
  [Types.AUTHENTICATED_SUCCESS]: authenticatedSuccess,
  [Types.AUTHENTICATED_FAILURE]: authenticatedFailure,

  [Types.FORGOT_PASSWORD_REQUEST]: forgotPasswordRequest,
  [Types.FORGOT_PASSWORD_SUCCESS]: forgotPasswordSuccess,
  [Types.FORGOT_PASSWORD_FAILURE]: forgotPasswordFailure,
  [Types.CLEAR_LAST_FORGOT_PASSWORD_SUCCESS]: clearLastForgotPasswordSuccess,

  [Types.VALIDATE_REDEFINE_PASSWORD_TOKEN_REQUEST]: validateRedefinePasswordTokenRequest,
  [Types.VALIDATE_REDEFINE_PASSWORD_TOKEN_SUCCESS]: validateRedefinePasswordTokenSuccess,
  [Types.VALIDATE_REDEFINE_PASSWORD_TOKEN_FAILURE]: validateRedefinePasswordTokenFailure,
  [Types.CLEAR_REDEFINE_PASSWORD_TOKEN_VALIDATION]: clearRedefinePasswordTokenValidation,

  [Types.REDEFINE_PASSWORD_REQUEST]: redefinePasswordRequest,
  [Types.REDEFINE_PASSWORD_SUCCESS]: redefinePasswordSuccess,
  [Types.REDEFINE_PASSWORD_FAILURE]: redefinePasswordFailure,

  [Types.LOGOUT]: logout,
  [Types.ON_USER_UPLOAD_PHOTO]: onUserUploadPhoto,
});
