import React, { useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { Grid, LinearProgress } from '@material-ui/core';
import moment from 'moment';
import TimeAdjustmentInput from './TimeAdjustmentInput';
import { ZeptoButton } from '../../../components';
import useForm from '../../../hooks/useForm';
import { dateFormat } from '../../../utils/Constants';
import TimeAdjustmentStore from '../../../stores/TimeAdjustmentStore';
import hoursToMs from '../../../utils/TimeUtils';

const toJson = value => ({
  ...value,
  time: hoursToMs(value.time),
  date: value.date.format(dateFormat),
});

const initialValue = {
  date: moment(),
  time: '',
  type: 'POSITIVE',
};

const rules = {
  reason: 'required|max:100',
  date: 'required',
  time: 'required|hours',
};

function TimeAdjustmentForm(props) {
  const { onSave, companyId, employerId } = props;
  const { value, onUpdate, isValid, getError } = useForm(initialValue, rules);

  const { create, creating, lastCreated, clearLastCreated } = useContext(
    TimeAdjustmentStore
  );

  function onSaveClick() {
    if (!isValid()) return;

    create(companyId, employerId, toJson(value));
  }

  useEffect(() => {
    if (lastCreated) onSave();
    return clearLastCreated;
  }, [lastCreated]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {creating && <LinearProgress />}
      </Grid>

      <Grid item xs={12}>
        <TimeAdjustmentInput
          value={value}
          onUpdate={onUpdate}
          getError={getError}
          disabled={creating}
        />
      </Grid>

      <Grid item xs={12}>
        {creating && <LinearProgress />}
      </Grid>

      <Grid item xs={12}>
        <Grid container justify="flex-end">
          <Grid item>
            <ZeptoButton
              onClick={onSaveClick}
              disabled={creating}
              label="Salvar"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

TimeAdjustmentForm.propTypes = {};

export default observer(TimeAdjustmentForm);
