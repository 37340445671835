import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { Grid, Link, Divider } from '@material-ui/core';
import { Link as RouterLink } from 'react-router';

import Typography from '@material-ui/core/Typography';
import Formsy from 'formsy-react';
import { loginUser } from '../../operators/authOperator';
import ZeptoTextField from '../../components/ZeptoFormTextField';
import ForgotPassword from './ForgotPassword';
import { ZeptoPaper, ZeptoButton } from '../../components';
import UnloggedTopBar from '../unlogged-top-bar/UnloggedTopBar';

const useStyles = makeStyles(theme => ({
  main: {
    width: 'auto',
    display: 'flex', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    alignItems: 'center',
    height: '100vh',
    overflow: 'unset',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  center: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const Login = props => {
  const handleSubmit = data => {
    const { loginUser } = props;
    loginUser(data);
  };
  const { loading } = props;
  const [enableSubmit, setEnableSubmit] = useState();
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  function onForgotPasswordClick() {
    setShowForgotPassword(true);
  }

  const classes = useStyles();
  return (
    <UnloggedTopBar>
      <main className={classes.main}>
        <ZeptoPaper className={classes.paper} variant="protected">
          {showForgotPassword && (
            <Grid container className={classes.form}>
              <Grid item xs={12}>
                <ForgotPassword onCancel={() => setShowForgotPassword(false)} />
              </Grid>
            </Grid>
          )}

          {!showForgotPassword && (
            <Formsy
              className={classes.form}
              onValidSubmit={handleSubmit}
              onValid={() => setEnableSubmit(true)}
              onInvalid={() => setEnableSubmit(false)}
            >
              <Grid container spacing={2} justify="center">
                <Grid item xs={12}>
                  <ZeptoTextField
                    name="email"
                    label="Email"
                    validations="isEmail"
                    validationError="Email inválido"
                    required
                    autoFocus
                  />
                </Grid>

                <Grid item xs={12}>
                  <ZeptoTextField
                    name="password"
                    type="password"
                    label="Senha"
                    validationError="Senha inválida"
                    required
                    margin="normal"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <ZeptoButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading || !enableSubmit}
                    label="Entrar"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography align="center">
                    <Link
                      href="#"
                      variant="body2"
                      onClick={onForgotPasswordClick}
                      align="center"
                    >
                      Esqueceu a senha?
                    </Link>
                  </Typography>
                  <Typography align="center">
                    <Link component={RouterLink} to="/signup" variant="body2">
                      Não é nosso cliente? Registre sua empresa!
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Formsy>
          )}
        </ZeptoPaper>
      </main>
    </UnloggedTopBar>
  );
};

const mapStateToProps = store => ({
  loading: store.auth.loading,
  errorMessage: store.auth.errorMessage,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loginUser,
    },
    dispatch
  );

Login.propTypes = {
  loading: PropTypes.bool.isRequired,
  loginUser: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
