import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  CircularProgress,
  Chip,
  makeStyles,
} from '@material-ui/core';
import { ZeptoPaper, ZeptoButton } from '../../../../components';
import SubscriptionStatus from '../../../../model/SubscriptionStatus';

const useStyles = makeStyles({
  canceled: {
    color: 'red',
  },
});

const Subscription = memo(props => {
  const classes = useStyles();

  const { subscription, onCancel, canceling } = props;
  return (
    <ZeptoPaper>
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="h6"> Plano: {subscription.plan.name}</Typography>
        </Grid>

        <Grid item>
          {canceling && <CircularProgress color="secondary" />}
          {!canceling && subscription && (
            <>
              {subscription.status !== SubscriptionStatus.canceled && (
                <ZeptoButton
                  label="Cancelar"
                  onClick={onCancel}
                  color="secondary"
                />
              )}

              {subscription.status === SubscriptionStatus.canceled && (
                <Chip
                  label="Cancelado"
                  className={classes.canceled}
                  variant="outlined"
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </ZeptoPaper>
  );
});

Subscription.propTypes = {
  subscription: PropTypes.shape({
    status: PropTypes.string.isRequired,
    plan: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  canceling: PropTypes.bool,
};

export default Subscription;
