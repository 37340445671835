import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';

import { Grid, TableCell, Typography, makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

import { ZeptoTable, ZeptoFab, ZeptoConfirmDialog } from '../../../components';
import HolidayStore from '../../../stores/HolidayStore';

import { toDate } from '../../../utils/DateUtils';
import CompanyHolidayActions from './CompanyHolidayActions';
import CompanyStore from '../../../stores/CompanyStore';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const CompanyHolidays = observer(() => {
  const classes = useStyles();
  const holidayStore = useContext(HolidayStore);
  const { company } = useContext(CompanyStore);

  const [confirmOptions, setConfirmOptions] = useState({
    open: false,
  });
  const [inEdition, setInEdition] = useState(undefined);

  const closeConfirmation = () => setConfirmOptions({ open: false });

  const {
    holidays,
    loadingHolidays,
    disablingHoliday,
    editingHoliday,
  } = holidayStore;

  function onDeleteConfirm(holiday) {
    closeConfirmation();
    holidayStore.disable(holiday);
  }

  function onDelete(holiday) {
    return () =>
      setConfirmOptions({
        open: true,
        onConfirm: () => onDeleteConfirm(holiday),
        content: `Deseja realmente apagar o feriado '${holiday.name}'?`,
      });
  }

  function onEdit(holiday) {
    return () => setInEdition(holiday);
  }

  const sorted = holidays.slice();
  sorted.sort((a, b) => {
    const momA = moment(a.date);
    const momB = moment(b.date);
    if (momA.isAfter(momB)) return 1;
    if (momA.isBefore(momB)) return -1;
    return 0;
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <ZeptoTable
          title="Feriados"
          rows={sorted}
          loading={loadingHolidays}
          renderSubTitle={() => <CompanyHolidayActions inEdition={inEdition} />}
          headRows={[
            { id: 'date', label: 'Data' },
            { id: 'name', label: 'Descrição' },
            { id: 'actions', label: '' },
          ]}
          renderCells={holiday => (
            <>
              <TableCell>
                <Typography noWrap>{toDate(holiday.date)}</Typography>
              </TableCell>
              <TableCell>
                <Typography noWrap>{holiday.name}</Typography>
              </TableCell>
              <TableCell>
                {holiday.companyId === company.id && (
                  <div className={classes.row}>
                    <ZeptoFab
                      onClick={onEdit(holiday)}
                      icon={<EditIcon />}
                      loading={editingHoliday}
                    />
                    <ZeptoFab
                      onClick={onDelete(holiday)}
                      icon={<CancelIcon />}
                      color="secondary"
                      loading={disablingHoliday}
                    />
                  </div>
                )}
              </TableCell>
            </>
          )}
        />
      </Grid>

      <ZeptoConfirmDialog
        open={confirmOptions.open}
        onConfirm={confirmOptions.onConfirm}
        onCancel={closeConfirmation}
        content={confirmOptions.content}
        loading={disablingHoliday}
      />
    </Grid>
  );
});

CompanyHolidays.propTypes = {};

export default CompanyHolidays;
