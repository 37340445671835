import React, { useContext } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles, Fab } from '@material-ui/core';
import { observer } from 'mobx-react';
import TableCell from '@material-ui/core/TableCell';
import DeleteIcon from '@material-ui/icons/Delete';
import AccountPlus from '@material-ui/icons/Add';
import UndoIcon from '@material-ui/icons/Undo';
import EditIcon from '@material-ui/icons/Edit';
import { push } from 'react-router-redux';
import { ZeptoConfirmDialog } from '../../../components';
import JourneyList from '../journeys/list/JourneyList';
import CompanyJourneyStore from '../../../stores/CompanyJourneyStore';

const useStyles = makeStyles(theme => ({
  disabled: {
    opacity: 0.5,
    color: 'red',
  },
  fab2: {
    margin: theme.spacing(2),
  },
  fab1: {
    margin: theme.spacing(1),
  },
}));

function CompanyJourneysList(props) {
  const [confirmOptions, setConfirmOptions] = React.useState({
    open: false,
  });

  const { company } = props;
  const {
    loadCompanyJourneys,
    companyJourneys,
    loadingCompanyJourneys,
    loadingCompanyJourney,
    disablingCompanyJourney,
    enablingCompanyJourney,
    disable,
    enable,
  } = useContext(CompanyJourneyStore);

  const loading =
    loadingCompanyJourneys ||
    loadingCompanyJourney ||
    disablingCompanyJourney ||
    enablingCompanyJourney;

  React.useEffect(() => {
    const { company } = props;
    loadCompanyJourneys(company.id);
  }, [company]);

  const closeConfirmation = () => setConfirmOptions({ open: false });

  const onEditJourney = companyJourney => {
    const { push, company } = props;
    push(`/companies/${company.id}/journeys/${companyJourney.id}/edit`);
  };

  const onDisableJourneyConfirm = journey => {
    disable(journey);
    closeConfirmation();
  };

  const onDisableJourney = companyJourney => {
    setConfirmOptions({
      open: true,
      onConfirm: () => onDisableJourneyConfirm(companyJourney),
      content: `Deseja realmente desabilitar a jornada '${companyJourney.journey.name}'?`,
    });
  };

  const onEnableJourneyConfirm = journey => {
    enable(journey);
    closeConfirmation();
  };

  const onEnableJourney = companyJourney => {
    setConfirmOptions({
      open: true,
      onConfirm: () => onEnableJourneyConfirm(companyJourney),
      content: `Deseja realmente habilitar a jornada '${companyJourney.journey.name}'?`,
    });
  };

  const onNewJourney = () => {
    const { push, company } = props;
    push(`/companies/${company.id}/journeys/0/edit`);
  };

  const classes = useStyles();

  return (
    <>
      <JourneyList
        loading={loading}
        journeys={companyJourneys}
        renderActions={() => (
          <Fab
            className={classes.fab2}
            disabled={loading}
            onClick={onNewJourney}
            color="primary"
            size="small"
          >
            <AccountPlus />
          </Fab>
        )}
        headRows={[
          {
            id: 'acoes',
            label: '',
          },
        ]}
        renderCells={journey => (
          <>
            <TableCell align="right">
              <Fab
                className={classes.fab1}
                onClick={() => onEditJourney(journey)}
                disabled={!journey.enabled || loading}
                size="small"
                color="primary"
              >
                <EditIcon />
              </Fab>
              {journey.enabled && (
                <Fab
                  className={classes.fab1}
                  onClick={() => onDisableJourney(journey)}
                  color="secondary"
                  disabled={loading}
                  size="small"
                >
                  <DeleteIcon />
                </Fab>
              )}
              {!journey.enabled && (
                <Fab
                  className={classes.fab1}
                  onClick={() => onEnableJourney(journey)}
                  disabled={loading}
                  color="primary"
                  size="small"
                >
                  <UndoIcon />
                </Fab>
              )}
            </TableCell>
          </>
        )}
      />
      <ZeptoConfirmDialog
        open={confirmOptions.open}
        onConfirm={confirmOptions.onConfirm}
        onCancel={closeConfirmation}
        content={confirmOptions.content}
      />
    </>
  );
}

CompanyJourneysList.propTypes = {
  company: PropTypes.shape({}).isRequired,
  push: PropTypes.func.isRequired,
};

const mapStateToProps = store => ({
  company: store.companies.selected,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      push,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(observer(CompanyJourneysList));
