import React, { memo } from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import ZeptoTimePicker from '../../../../components/ZeptoTimePicker';
import { timeFormat } from '../../../../utils/Constants';

const PeriodInput = memo(props => {
  const { value, onChange, disabled, errors, path } = props;
  const start = moment(value.start, timeFormat).toDate();
  const end = moment(value.end, timeFormat).toDate();

  function handleTimeChange(name) {
    return newTime => {
      onChange({ ...value, [name]: newTime });
    };
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <ZeptoTimePicker
          required
          label="Entrada"
          value={start}
          onChange={handleTimeChange('start')}
          disabled={disabled}
          error={errors ? errors(`${path}.start`) : null}
        />
      </Grid>
      <Grid item xs={6}>
        <ZeptoTimePicker
          required
          label="Saída"
          value={end}
          onChange={handleTimeChange('end')}
          disabled={disabled}
          error={errors ? errors(`${path}.end`) : null}
        />
      </Grid>
    </Grid>
  );
});

PeriodInput.propTypes = {};

export default PeriodInput;
