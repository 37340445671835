import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import moment from 'moment';
import ZeptoButton from '../../components/ZeptoButton';
import { createRequest } from '../../operators/requestsOperator';
import { showWarn } from '../../operators/appOperator';
import {
  ZeptoFormTextField,
  ZeptoFormDateTimePicker,
  ZeptoPaper,
} from '../../components';
import { RequestType } from '../../model/Request';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  buttonArea: {
    width: '100%',
    paddingTop: 30,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const RequestForm = props => {
  const { processingCreateRequest, workDay, showWarn, selected } = props;
  const sort = (a, b) => moment(a.timestamp).diff(moment(b.timestamp));

  const sortedPoints = [...workDay.points, ...workDay.offJourneyPoints].sort(
    sort
  );

  const [request] = React.useState({
    timestamp: '',
  });

  const [enableSubmit, setEnableSubmit] = React.useState(false);
  const classes = useStyles();

  const handleSubmit = data => {
    const { createRequest } = props;

    const req = {
      ...data,
      timestamp: moment(data.timestamp, 'DD/MM/YYYY HH:mm').format(
        'YYYY-MM-DD HH:mm'
      ),
      type: selected.type,
      pointId: selected.point ? selected.point.id : null,
    };

    const time = req.timestamp === 'Data inválida' ? selected.point.timestamp : req.timestamp

    const found = sortedPoints.filter(date => moment(date.timestamp).format("YYYY-MM-DD HH:mm") === time)

    if (found && found !== 'Data inválida' && found.length !== 0) {
      showWarn(`Já foi feito uma solicitação para este horário.`)
    } else {
      if (moment(time).isAfter(workDay.underJourney.firstDate)) {
        createRequest(req);
      } else {
        showWarn("Não é possível fazer solicitações para datas antes da jornada cadastrada");
      }
    }
  };

  return (
    <ZeptoPaper>
      <Formsy
        onValidSubmit={handleSubmit}
        onValid={() => setEnableSubmit(true)}
        onInvalid={() => setEnableSubmit(false)}
        className={classes.root}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {selected.type === RequestType.enable && (
              <ZeptoFormDateTimePicker
                name="timestamp"
                value={request.timestamp}
                label="Preencha com o dia e horário que ficou faltando o registro"
                required
                validations="isExisty,isValidDateTime,isValidDateTimeFuture"
                validationError="Horário inválido (Não é possível fazer solicitações para datas futuras)"
              />
            )}

            {selected.type === RequestType.disable && (
              <>
                <Typography>
                  Apagando o ponto de:{' '}
                  {moment(selected.point.timestamp).format('HH:mm DD/MM/YYYY')}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <ZeptoFormTextField
              name="requestText"
              value={request.requestText}
              label="Justificativa"
              validations="isExisty,minLength:1,maxLength:255"
              validationError="Justificativa inválida"
              required
              inputProps={{
                maxLength: 255,
              }}
            />
          </Grid>

          <div className={classes.buttonArea}>
            <ZeptoButton
              label="Salvar"
              type="submit"
              loading={processingCreateRequest || !enableSubmit}
            />
          </div>
        </Grid>
      </Formsy>
    </ZeptoPaper >
  );
};

const mapStateToProps = store => ({
  processingCreateRequest: store.users.processingCreateUser,
  workDay: store.workDays.workDay,
  selected: store.requests.selected,
  createRequestErrorMEssage: store.requests.createRequestErrorMEssage,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createRequest,
      showWarn
    },
    dispatch
  );

RequestForm.propTypes = {
  workDay: PropTypes.shape({}),
  showWarn: PropTypes.func.isRequired,
  processingCreateRequest: PropTypes.bool,
  createRequest: PropTypes.func.isRequired,
  selected: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestForm);
