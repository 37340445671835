import React from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';
import ZeptoSelect from './ZeptoSelect';

const ZeptoFormSelect = (props) => {
  const { setValue, getValue, getErrorMessage } = props;

  const changeValue = (event) => {
    setValue(event.target.value);
  };

  const errorMessage = getErrorMessage();

  return (
    <ZeptoSelect
      {...props}
      error={!!errorMessage}
      value={getValue() || ''}
      onChange={changeValue}
      
    />
  );
};

ZeptoFormSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  setValue: PropTypes.func,
  getValue: PropTypes.func,
  getErrorMessage: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
};

export default withFormsy(ZeptoFormSelect);
