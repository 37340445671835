import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Card,
  CardActionArea,
  CardContent,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { formatMoney } from '../../utils/StringUtils';

const useStyles = makeStyles(theme => ({
  root: {
    // maxWidth: 345,
  },
  media: {
    height: 140,
  },
  selected: {
    backgroundColor: theme.palette.type === 'light' ? grey[100] : grey[700],
  },
}));

const PlanItem = memo(props => {
  const classes = useStyles();
  const { plan, selected, onSelect } = props;
  const selectedClass = selected ? classes.selected : '';

  function onClick() {
    if (onSelect) onSelect(plan);
  }
  return (
    <Card className={clsx(classes.root, selectedClass)} selected>
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" align="center">
            {plan.name}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            align="center"
          >
            {plan.description}
          </Typography>

          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            align="center"
          >
            Até {plan.maxUsers} usuários.
          </Typography>

          <Typography gutterBottom variant="h5" component="h2" align="center">
            R$ {formatMoney(plan.price)} por mês
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
});

PlanItem.propTypes = {
  plan: PropTypes.instanceOf(Object),
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default PlanItem;
