import { createActions, createReducer } from 'reduxsauce';

/* #region Action types & creators */
export const { Types, Creators } = createActions({
  createAllowanceRequest: [null],
  createAllowanceSuccess: [null],
  createAllowanceFailure: [null],

  deleteAllowanceRequest: [null],
  deleteAllowanceSuccess: ['allowanceId'],
  deleteAllowanceFailure: [null],

  loadAllowancesRequest: [null],
  loadAllowancesSuccess: ['allowances'],
  loadAllowancesFailure: [null],
});
/* #endregion */

/* #region Initial State */
const INITIAL_STATE = {
  allowances: [],
};
/* #endregion */

/* #region Create allowance */

const createAllowanceRequest = (state = INITIAL_STATE) => ({
  ...state,
  processingCreateAllowance: true,
});

const createAllowanceSuccess = (state = INITIAL_STATE) => ({
  ...state,
  processingCreateAllowance: false,
});

const createAllowanceFailure = (state = INITIAL_STATE) => ({
  ...state,
  processingCreateAllowance: false,
});

// /* #endregion */

/* #region Create allowance */

const deleteAllowanceRequest = (state = INITIAL_STATE) => ({
  ...state,
  deletingAllowance: true,
});

const deleteAllowanceSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  allowances: state.allowances.filter(a => a.id !== Number(action.allowanceId)),
  lastDeleted: action.allowanceId,
  deletingAllowance: false,
});

const deleteAllowanceFailure = (state = INITIAL_STATE) => ({
  ...state,
  deletingAllowance: false,
});

// /* #endregion */

// /* #region Load allowances */

const loadAllowancesRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingAllowances: true,
});

const loadAllowancesSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingAllowances: false,
  allowances: action.allowances,
});

const loadAllowancesFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingAllowances: false,
});

// /* #endregion */

export default createReducer(INITIAL_STATE, {
  [Types.CREATE_ALLOWANCE_REQUEST]: createAllowanceRequest,
  [Types.CREATE_ALLOWANCE_SUCCESS]: createAllowanceSuccess,
  [Types.CREATE_ALLOWANCE_FAILURE]: createAllowanceFailure,

  [Types.DELETE_ALLOWANCE_REQUEST]: deleteAllowanceRequest,
  [Types.DELETE_ALLOWANCE_SUCCESS]: deleteAllowanceSuccess,
  [Types.DELETE_ALLOWANCE_FAILURE]: deleteAllowanceFailure,

  [Types.LOAD_ALLOWANCES_REQUEST]: loadAllowancesRequest,
  [Types.LOAD_ALLOWANCES_SUCCESS]: loadAllowancesSuccess,
  [Types.LOAD_ALLOWANCES_FAILURE]: loadAllowancesFailure,
});
