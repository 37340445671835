import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  ListItem,
  ListItemIcon,
  Grid,
  Typography,
  Hidden,
  List,
  ListSubheader,
} from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import {
  Home,
  People,
  AccountBalance,
  Schedule,
  AttachMoney,
  InsertInvitation,
  Print,
  Settings,
  TrackChanges,
  AssignmentTurnedIn,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserAvatar from './UserAvatar';
import userIs from '../../utils/permissionUtils';
import { goTo } from '../../operators/routesOperator';
import MenuStore from './MenuStore';
import { observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: 'hidden',
  },
  toolbar: theme.mixins.toolbar,
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  userGroup: {
    padding: 15,
  },
  userName: {
    fontWeight: 500,
  },
  list: {
    paddingLeft: 10,
  },
  spacer: {
    paddingBottom: 20,
  },
  title: {
    paddingBottom: 10,
  },
}));

function Sidebar(props) {
  const classes = useStyles();
  const { authenticatedUser, selectedCompany, goTo } = props;

  const { setMobileDrawerOpen } = useContext(MenuStore);

  function onLinkClick(url) {
    setMobileDrawerOpen(false);
    goTo(url);
  }
  return (
    <div className={classes.root}>
      <Hidden xsDown>
        <div className={classes.toolbar} />
      </Hidden>
      <Grid
        container
        spacing={2}
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.userGroup}
        wrap="nowrap"
      >
        <Grid item>
          <UserAvatar
            size="100"
            user={authenticatedUser}
            className={classes.avatar}
          />
        </Grid>

        <Grid item xs zeroMinWidth>
          <Typography className={classes.userName} noWrap>
            {authenticatedUser.name}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <div className={classes.spacer} />

      {(userIs('ADMIN') || userIs('EMPLOYER')) && selectedCompany && (
        <List
          subheader={
            <ListSubheader>
              <Typography noWrap className={classes.title}>
                {selectedCompany.businnesName}
              </Typography>
            </ListSubheader>
          }
        >
          <ListItem
            dense
            button
            onClick={() => onLinkClick(`/companies/${selectedCompany.id}/daily`)}
          >
            <ListItemIcon>
              <AccountBalance />
            </ListItemIcon>
            <ListItemText primary="Pontos do dia" />
          </ListItem>

          <ListItem
            dense
            button
            onClick={() => onLinkClick(`/companies/${selectedCompany.id}/dashboard`)}
          >
            <ListItemIcon>
              <TrackChanges />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>

          <ListItem
            dense
            button
            onClick={() =>
              onLinkClick(`/companies/${selectedCompany.id}/users`)
            }
          >
            <ListItemIcon>
              <People />
            </ListItemIcon>
            <ListItemText primary="Usuários" />
          </ListItem>

          <ListItem
            dense
            button
            onClick={() =>
              onLinkClick(`/companies/${selectedCompany.id}/journeys`)
            }
          >
            <ListItemIcon>
              <Schedule />
            </ListItemIcon>
            <ListItemText primary="Jornadas" />
          </ListItem>

          <ListItem
            dense
            button
            onClick={() =>
              onLinkClick(`/companies/${selectedCompany.id}/finance`)
            }
          >
            <ListItemIcon>
              <AttachMoney />
            </ListItemIcon>
            <ListItemText primary="Financeiro" />
          </ListItem>
          <ListItem
            dense
            button
            onClick={() =>
              onLinkClick(`/companies/${selectedCompany.id}/holidays`)
            }
          >
            <ListItemIcon>
              <InsertInvitation />
            </ListItemIcon>
            <ListItemText primary="Feriados" />
          </ListItem>
          {!window.ReactNativeWebView && (
            <ListItem
              dense
              button
              onClick={() =>
                onLinkClick(`/companies/${selectedCompany.id}/reports`)
              }
            >
              <ListItemIcon>
                <Print />
              </ListItemIcon>
              <ListItemText primary="Relatório" />
            </ListItem>
          )}

          <ListItem
            dense
            button
            onClick={() =>
              onLinkClick(`/companies/${selectedCompany.id}/closures`)
            }
          >
            <ListItemIcon>
              <AssignmentTurnedIn />
            </ListItemIcon>
            <ListItemText primary="Fechamento" />
          </ListItem>

          <ListItem
            dense
            button
            onClick={() =>
              onLinkClick(`/companies/${selectedCompany.id}/settings`)
            }
          >
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Ajustes" />
          </ListItem>
        </List>
      )}

      {userIs('ADMIN') && (
        <List
          subheader={
            <ListSubheader>
              <Typography noWrap className={classes.title}>
                Administrativo
              </Typography>
            </ListSubheader>
          }
        >
          <ListItem dense button onClick={() => onLinkClick('/companies')}>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary="Empresas" />
          </ListItem>
          <ListItem dense button onClick={() => onLinkClick('/employers')}>
            <ListItemIcon>
              <People />
            </ListItemIcon>
            <ListItemText primary="Empregadores" />
          </ListItem>
        </List>
      )}
      {userIs('USER') && (
        <List>
          <ListItem dense button onClick={() => onLinkClick('/me')}>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary="Início" />
          </ListItem>
        </List>
      )}

      {(userIs('AUDITOR') || userIs('ADMIN')) && (
        <List>
          <ListItem
            dense
            button
            onClick={() =>
              onLinkClick(`/companies/${authenticatedUser.companyId}/reports`)
            }
          >
            <ListItemIcon>
              <Print />
            </ListItemIcon>
            <ListItemText primary="Relatório" />
          </ListItem>
        </List>
      )}
    </div>
  );
}
Sidebar.propTypes = {
  authenticatedUser: PropTypes.shape({}).isRequired,
};

const mapStateToProps = store => ({
  authenticatedUser: store.auth.authenticatedUser,
  selectedCompany: store.companies.selected,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goTo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(observer(Sidebar));