import { createContext } from 'react';
import { decorate, observable } from 'mobx';
import { jsonFetch, jsonDelete, jsonPost } from '../utils/http';
import { appStore } from './AppStore';

const apiPath = (companyId, employerId) =>
  `${process.env.REACT_APP_API_PATH}/api/companies/${companyId}/users/${employerId}/time-adjustments`;

class TimeAdjustmentStore {
  timeAdjustments;

  lastCreated;

  lastDisabled;

  loadingTimeAdjustments;

  creating;

  disabling;

  clear = () => {
    this.timeAdjustments = null;

    this.lastDisabled = null;
  };

  clearLastCreated = () => {
    this.lastCreated = null;
  };

  list = async (companyId, employerId, params) => {
    this.loadingTimeAdjustments = true;
    try {
      this.timeAdjustments = await jsonFetch(
        `${apiPath(companyId, employerId)}/?${new URLSearchParams(
          params
        ).toString()}`
      );
    } catch (e) {
      appStore.showError(
        `Ocorreu um erro ao carregar os ajustes: ${e.message}`
      );
    }
    this.loadingTimeAdjustments = false;
  };

  disable = async (companyId, employerId, id) => {
    this.disabling = true;
    try {
      await jsonDelete(`${apiPath(companyId, employerId)}/${id}`);
      this.lastDisabled = new Date();
    } catch (e) {
      appStore.showError(
        `Ocorreu um erro ao cancelar os fechamentos: ${e.message}`
      );
    }
    this.disabling = false;
  };

  create = async (companyId, employerId, params) => {
    this.creating = true;
    try {
      await jsonPost(`${apiPath(companyId, employerId)}`, params);

      appStore.showSuccess('Ajuste criado com sucesso.');
      this.lastCreated = new Date();
    } catch (e) {
      appStore.showError(`Ocorreu um erro ao criar o ajuste: ${e.message}`);
    }
    this.creating = false;
  };
}

decorate(TimeAdjustmentStore, {
  timeAdjustments: observable,
  loadingTimeAdjustments: observable,
  creating: observable,
  disabling: observable,
  lastCreated: observable,
  lastDisabled: observable,
});

export const timeAdjustmentStore = new TimeAdjustmentStore();

export default createContext(timeAdjustmentStore);
