import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, LinearProgress } from '@material-ui/core';
import SubscriptionStore from '../../../stores/SubscriptionStore';
import CompanyInstallments from './installments/CompanyInstallments';
import Subscription from './subscription/Subscription';
import { ZeptoConfirmDialog } from '../../../components';
import BillingData from './billing-data/BillingData';

const CompanyFinance = props => {
  const { company } = props;

  const [confirmOptions, setConfirmOptions] = useState({
    open: false,
  });

  const closeConfirmation = () => setConfirmOptions({ open: false });

  const subscriptionStore = useContext(SubscriptionStore);

  const {
    subscription,
    loadingSubscription,
    loadSubscription,
    cancelSubscription,
    cancelingSubscription,
  } = subscriptionStore;

  function onCancelSubscriptionConfirm() {
    closeConfirmation();
    cancelSubscription(company.id);
  }

  function onCancelSubscription() {
    setConfirmOptions({
      open: true,
      onConfirm: () => onCancelSubscriptionConfirm(),
      content: 'Deseja realmente cancelar a assinatura?',
    });
  }

  useEffect(() => {
    loadSubscription(company.id);
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {loadingSubscription && <LinearProgress />}
      </Grid>

      {!loadingSubscription && subscription && (
        <Grid item xs={12}>
          <Subscription
            subscription={subscription}
            onCancel={onCancelSubscription}
            canceling={cancelingSubscription}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <BillingData companyId={company.id} />
      </Grid>

      <Grid item xs={12}>
        <CompanyInstallments companyId={company.id} />
      </Grid>

      <ZeptoConfirmDialog
        open={confirmOptions.open}
        onConfirm={confirmOptions.onConfirm}
        onCancel={closeConfirmation}
        content={confirmOptions.content}
        loading={cancelingSubscription}
      />
    </Grid>
  );
};

CompanyFinance.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

const mapStateToProps = store => ({
  company: store.companies.selected,
});

export default connect(mapStateToProps)(observer(CompanyFinance));
