import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Typography,
  makeStyles,
  Fab,
  List,
  ListItem,
  Grid,
  Divider,
} from '@material-ui/core';
import moment from 'moment';
import clsx from 'clsx';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { bindActionCreators } from 'redux';
import RequestConfirmDialog from '../../request/RequestConfirmDialog';
import { loadCompanyRequests } from '../../../operators/companiesOperator';
import { ZeptoPaper } from '../../../components';
import { RequestType } from '../../../model/Request';
import { green, red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  fab1: {
    margin: theme.spacing(1),
  },
  buttonsCell: {
    minWidth: 138,
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  open: { color: green[400] },
  disable: { color: red[400] },
  listItem: {
    minHeight: 100,
    paddingRight: 0,
    paddingLeft: 0,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  requestText: {
    opacity: 0.5,
  },
  userName: {
    paddingTop: 30,
  },
  noRecords: {
    paddingTop: 30,
  },
}));

const groupByUsers = requests => {
  const reqByUsers = [];
  requests.forEach(request => {
    const key = request.point.user;
    const grouper = reqByUsers.find(rbu => rbu.user.id === key.id);
    if (!grouper) {
      reqByUsers.push({
        user: key,
        requests: [request],
      });
    } else {
      grouper.requests.push(request);
    }
  });
  return reqByUsers;
};

const CompanyRequests = props => {
  const { requests, company, processingLoadRequests } = props;
  const classes = useStyles();

  const [selected, setSelected] = React.useState(null);
  const [showDialog, setShowDialog] = React.useState(false);
  const [toApprove, setToApprove] = React.useState(false);

  React.useEffect(() => {
    const { loadCompanyRequests, company } = props;
    loadCompanyRequests(company);
  }, [company]);

  const closeConfirmation = () => {
    setSelected(null);
    setShowDialog(false);
  };

  const onAcceptClick = request => {
    setToApprove(true);
    setShowDialog(true);
    setSelected(request);
  };

  const onRejectClick = request => {
    setToApprove(false);
    setShowDialog(true);
    setSelected(request);
  };

  const requestByUsers = groupByUsers(requests);
  const loading = () => processingLoadRequests;
  return (
    <ZeptoPaper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Solicitações</Typography>
        </Grid>

        {requestByUsers.map(rbu => (
          <Grid key={rbu.user.id} item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.userName}>
                  {rbu.user.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <List className={classes.root}>
                  {rbu.requests.map(request => (
                    <div key={request.id}>
                      <ListItem
                        key={request.id}
                        className={classes.listItem}
                        role={undefined}
                      >
                        <Grid container spacing={0} direction="column">
                          <Grid item xs={12}>
                            <Grid
                              container
                              spacing={0}
                              alignItems="center"
                              direction="row"
                            >
                              <Grid
                                item
                                xs={7}
                                className={
                                  request.type === RequestType.enable
                                    ? classes.open
                                    : classes.disable
                                }
                              >
                                {`${moment(request.point.timestamp).format(
                                  'DD/MM/YYYY'
                                )} às ${moment(request.point.timestamp).format(
                                  'HH:mm'
                                )}
                          `}
                              </Grid>

                              <Grid item xs={5} className={classes.buttons}>
                                <Fab
                                  className={clsx(
                                    classes.fab1,
                                    classes.approve
                                  )}
                                  onClick={() => onAcceptClick(request)}
                                  disabled={loading()}
                                  size="small"
                                  color="primary"
                                >
                                  <CheckIcon />
                                </Fab>
                                <Fab
                                  className={classes.fab1}
                                  onClick={() => onRejectClick(request)}
                                  disabled={loading()}
                                  size="small"
                                  color="secondary"
                                >
                                  <CloseIcon />
                                </Fab>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item>
                            <span className={classes.requestText}>
                              Justificativa:
                            </span>
                            {request.requestText}
                          </Grid>
                        </Grid>
                      </ListItem>
                      <Divider />
                    </div>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      {!!selected && (
        <RequestConfirmDialog
          open={showDialog}
          request={selected}
          onClose={() => closeConfirmation()}
          toApprove={toApprove}
        />
      )}

      {requestByUsers.length === 0 && (
        <Typography className={classes.noRecords}>
          Não existem registros cadastrados.
        </Typography>
      )}
    </ZeptoPaper>
  );
};

const mapStateToProps = store => ({
  processingLoadRequests: store.requests.processingLoadRequests,
  requests: store.requests.requests,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadCompanyRequests,
    },
    dispatch
  );

CompanyRequests.propTypes = {
  company: PropTypes.shape({}).isRequired,
  requests: PropTypes.arrayOf(PropTypes.shape({})),
  processingLoadRequests: PropTypes.bool,
};

CompanyRequests.defaultProps = {
  requests: [],
  processingLoadRequests: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(CompanyRequests));
