import React, { memo } from 'react';
import Table from '@material-ui/core/Table';
import { TableBody, Typography } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import moment from 'moment';

import PointItem from './PointItem';

const sort = (a, b) => moment(a.timestamp).diff(moment(b.timestamp));

const noRecords = <Typography>Nenhum registro encontrado.</Typography>;

const WorkDay = props => {
  const { workDay, onPointClick } = props;

  if (!workDay) {
    return noRecords;
  }

  const sortedPoints = [...workDay.points, ...workDay.offJourneyPoints].sort(
    sort
  );

  if (sortedPoints.length === 0) {
    return noRecords;
  }

  let counter = 0;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" />
          <TableCell component="th" scope="row">
            {'Horário'}
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedPoints.map(p => {
          if (p.enabled) counter++;
          return (
            <PointItem
              key={p.id}
              point={p}
              onPointClick={onPointClick}
              showMarks
              type={counter % 2 === 0 ? 'OUT' : 'IN'}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

WorkDay.propTypes = {
  workDay: PropTypes.shape({
    points: PropTypes.arrayOf(PropTypes.shape({})),
    offJourneyPoints: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onPointClick: PropTypes.func.isRequired,
};

export default memo(WorkDay);
