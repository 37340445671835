import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import InstallmentStore from '../../../../stores/InstallmentStore';
import { Grid, LinearProgress } from '@material-ui/core';
import InstallmentList from './InstallmentList';
import { observer } from 'mobx-react';

const CompanyInstallments = props => {
  const { companyId } = props;

  const { installments, loadInstallments, loadingInstallments } = useContext(
    InstallmentStore
  );

  useEffect(() => {
    loadInstallments(companyId);
  }, []);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {loadingInstallments && <LinearProgress />}
      </Grid>

      {!loadingInstallments && installments && (
        <Grid item xs={12}>
          <InstallmentList installments={installments} />
        </Grid>
      )}
    </Grid>
  );
};

CompanyInstallments.propTypes = {
  companyId: PropTypes.number.isRequired,
};

export default observer(CompanyInstallments);
