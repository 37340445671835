import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Grid, DialogActions, CircularProgress } from '@material-ui/core';
import ZeptoButton from './ZeptoButton';

import { showError } from '../operators/appOperator';

import { ZeptoDialog } from '.';

const ZeptoLocation = memo(props => {
  const [state, setState] = useState({
    loading: true,
  });
  const { loading } = state;
  const { onLoad, onCancel } = props;

  function onPositionLoad(data) {
    onLoad(data);
    setState({ loading: false });
  }

  function onPositionError() {
    const { showError } = props;
    showError('Ocorreu um erro ao carregar a localização.');
    onCancel();
  }

  function loadPosition() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        onPositionLoad,
        onPositionError,
        {
          enableHighAccuracy: true,
          maximumAge: 0,
        }
      );
    } else {
      const { showError } = props;
      showError('Geolocalização não é suportada por esse navegador.');
      onCancel();
    }
  }

  useEffect(() => {
    loadPosition();
  }, []);

  return (
    <>
      <ZeptoDialog
        open={loading}
        title="Carregando localização..."
        onClose={onCancel}
        renderAction={() => <CircularProgress size={30} thickness={5} />}
      >
        <DialogActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item>
              <ZeptoButton
                onClick={onCancel}
                color="secondary"
                label="Cancelar"
              />
            </Grid>
          </Grid>
        </DialogActions>
      </ZeptoDialog>
    </>
  );
});

ZeptoLocation.propTypes = {
  onLoad: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showError,
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(ZeptoLocation);
