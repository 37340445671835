import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Grid, Link, LinearProgress } from '@material-ui/core';
import { ZeptoTextField, ZeptoButton, ZeptoReCaptcha } from '../../components';
import useValidator from '../../hooks/useValidator';
import {
  forgotPassword,
  clearLastForgotPasswordSuccess,
} from '../../operators/authOperator';

const RULES = {
  email: 'required|min:1|max:255|email',
};

const inputProps = {
  max255: {
    maxLength: 255,
  },
};

const ForgotPassword = props => {
  const {
    onCancel,
    loadingForgotPassword,
    forgotPassword,
    lastForgotPasswordSucess,
    clearLastForgotPasswordSuccess,
  } = props;
  const [data, setData] = useState('');
  const [reCaptcha, setReCaptcha] = useState(false);

  const [isValid, errors] = useValidator(data, RULES);

  function handleChange(event) {
    setData({ email: event.target.value });
  }

  function onRestoreClick() {
    if (isValid()) {
      forgotPassword(data.email, reCaptcha);
      setReCaptcha(null);
    }
  }

  useEffect(() => {
    if (lastForgotPasswordSucess) onCancel();
    return clearLastForgotPasswordSuccess;
  }, [lastForgotPasswordSucess]);

  return (
    <Grid container justify="center" spacing={2} style={{ width: '100%' }}>
      <Grid item xs={12}>
        {loadingForgotPassword && <LinearProgress />}
      </Grid>
      <Grid item xs={12}>
        <ZeptoTextField
          name="email"
          value={data.email}
          label="Digite seu email..."
          type="email"
          required
          onChange={handleChange}
          errorMessage={errors('email')}
          inputProps={inputProps.max255}
        />
      </Grid>

      {!loadingForgotPassword && (
        <Grid item xs={12}>
          <ZeptoReCaptcha
            onValidate={value => setReCaptcha(value)}
            onExpired={() => setReCaptcha(null)}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <ZeptoButton
          label="Restaurar"
          onClick={onRestoreClick}
          disabled={!reCaptcha}
          loading={loadingForgotPassword}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container justify="center">
          <Grid item>
            <Link href="#" variant="body2" onClick={onCancel}>
              Voltar
            </Link>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {loadingForgotPassword && <LinearProgress />}
      </Grid>
    </Grid>
  );
};

ForgotPassword.propTypes = {
  onCancel: PropTypes.func.isRequired,
  loadingForgotPassword: PropTypes.bool,
  forgotPassword: PropTypes.func.isRequired,
  lastForgotPasswordSucess: PropTypes.shape({}),
};

const mapStateToProps = store => ({
  loadingForgotPassword: store.auth.loadingForgotPassword,
  lastForgotPasswordSucess: store.auth.lastForgotPasswordSucess,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      forgotPassword,
      clearLastForgotPasswordSuccess,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
