import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles, LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ZeptoPaper } from '../../components';
import { loadPointReceipt } from '../../operators/pointsOperator';

const useStyles = makeStyles({
  root: {
    minHeight: 200,
    margin: 20,
  },
});

const PointDetails = (props) => {
  const classes = useStyles();
  const {
    point, id, loadingReceipt, pointReceipt,
  } = props;

  useEffect(() => {
    const { loadPointReceipt } = props;
    loadPointReceipt(point);
  }, [point]);

  function rawMarkup() {
    const rawMarkup = pointReceipt.html;
    return { __html: rawMarkup };
  }

  return (
    <ZeptoPaper className={classes.root} id={id}>
      <Grid container>
        <Grid item xs={12}>
          {loadingReceipt && <LinearProgress />}

          {!loadingReceipt && pointReceipt && (
            <div
              id="point-receipt-details"
              dangerouslySetInnerHTML={rawMarkup()}
            />
          )}
        </Grid>
      </Grid>
    </ZeptoPaper>
  );
};

PointDetails.propTypes = {
  id: PropTypes.string,
  point: PropTypes.shape({}).isRequired,
  loadingReceipt: PropTypes.bool,
  pointReceipt: PropTypes.shape({}),
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    loadPointReceipt,
  },
  dispatch,
);

const mapStateToProps = state => ({
  loadingReceipt: state.points.processingLoadPointReceipt,
  pointReceipt: state.points.pointReceipt,
});

export default memo(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PointDetails),
);
