import React, { useState, useEffect, memo } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ReorderIcon from '@material-ui/icons/Reorder';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { connect } from 'react-redux';
import { Tab, Tabs, AppBar, LinearProgress } from '@material-ui/core';
import { ZeptoMap } from '../../components';
import { loadPointPhoto } from '../../operators/pointsOperator';
import PointDetails from './PointDetails';

const PointViewer = props => {
  const { point, processingLoadPointPhoto, pointPhoto, loadingPhoto } = props;

  const [state, setState] = useState({ selectedTab: 0 });
  const { selectedTab } = state;

  function changeTab(event, newValue) {
    setState({ ...state, selectedTab: newValue });
  }

  useEffect(() => {
    const { loadPointPhoto } = props;
    loadPointPhoto(point);
  }, [point]);

  const loading = loadingPhoto;

  return (
    <>
      <AppBar position="static" color="default">
        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={changeTab}
          variant="fullWidth"
        >
          <Tab icon={<ReorderIcon />} />
          <Tab icon={<LocationOnIcon />} disabled={!point.lat && !point.long} />
          <Tab icon={<AccountBoxIcon />} disabled={!point.imgPath} />
        </Tabs>
      </AppBar>

      {loading && <LinearProgress />}

      {!loading && (
        <>
          {selectedTab === 0 && <PointDetails point={point} />}
          {selectedTab === 1 && <ZeptoMap lat={point.lat} long={point.long} />}
          {selectedTab === 2 && (
            <>
              {processingLoadPointPhoto && <LinearProgress />}
              {!processingLoadPointPhoto && pointPhoto && (
                <img
                  style={{ width: '100%' }}
                  alt=""
                  src={URL.createObjectURL(pointPhoto)}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadPointPhoto,
    },
    dispatch
  );

const mapStateToProps = state => ({
  loadingPhoto: state.points.processingLoadPointPhoto,
  pointPhoto: state.points.pointPhoto,
  user: state.users.selected,
  company: state.companies.selected,
});

PointViewer.propTypes = {
  point: PropTypes.shape({}),
  processingLoadPointPhoto: PropTypes.bool,
  pointPhoto: PropTypes.shape({}),
  user: PropTypes.shape({}),
  company: PropTypes.shape({}),
  loadingPhoto: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(PointViewer));
