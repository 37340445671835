import React, { memo, useState } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import { DialogContent, DialogActions, Grid, Fab } from '@material-ui/core';
import { connect } from 'react-redux';
import domtoimage from 'dom-to-image';
import { ZeptoDialog, ZeptoButton } from '../../components';
import Formsy from 'formsy-react';
import userIs from '../../utils/permissionUtils';
import PointViewer from './PointViewer';
import { createRequest } from '../../operators/requestsOperator';
import moment from 'moment';
import { ZeptoFormTextField } from '../../components';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  buttonArea: {
    width: '100%',
    paddingTop: 30,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});


const PointDialog = (props) => {
  const [showDeleteJorney, setShowDeleteJorney] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [request] = useState({ requestText: 'Ajuste' });
  const classes = useStyles();
  const {
    point, onDisable, open, onClose, pointReceipt
  } = props;
console.log(point)
  const handleSubmit = data => {
    const { createRequest } = props;

    const req = {
      ...data,
      timestamp: moment(point.timestamp).format('YYYY-MM-DD HH:mm'),
      type: 'DISABLE',
      pointId: point.id,
      user: {
        id: point.userId
      }
    }

    createRequest(req);
  };

  function onDownload() {
    const node = document.getElementById('point-receipt-details');
    domtoimage.toPng(node).then((dataUrl) => {
      const link = document.createElement('a');
      link.download = 'comprovante';
      link.href = dataUrl;
      link.click();
    });
    // htmlToImage
    //   .toBlob(document.getElementById('point-receipt-details'))
    //   .then((blob) => {
    //     // window.saveAs(blob, 'my-node.png');
    //     // const a = document.createElement('a');
    //     // document.body.appendChild(a);
    //     // a.style = 'display: none';
    //     // return function (data, fileName) {
    //     //   const url = window.URL.createObjectURL(blob);
    //     //   a.href = url;
    //     //   a.download = fileName;
    //     //   a.click();
    //     //   window.URL.revokeObjectURL(url);
    //     // };
    //   });
    // htmlToImage
    //   .toJpeg(document.getElementById('point-receipt-details'), {
    //     quality: 0.95,
    //   })
    //   .then((dataUrl) => {
    //     console.log('rr', dataUrl);
    //     const link = document.createElement('a');
    //     link.download = 'comprovante.jpeg';
    //     link.href = dataUrl;
    //     link.click();
    //   });
  }

  return (
    <>
      {showDeleteJorney && <ZeptoDialog
        open
        title={`Apagando o ponto de: ${moment(point.timestamp).format('HH:mm DD/MM/YYYY')}`}
        onClose={() => setShowDeleteJorney(false)}
        renderAction={() => (
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item>
              <Fab
                onClick={() => setShowDeleteJorney(false)}
                size="small"
                color="secondary"
              >
                <CloseIcon />
              </Fab>
            </Grid>
          </Grid>
        )
        }
      >
        <DialogContent>
          <Formsy
            onValidSubmit={handleSubmit}
            onValid={() => setEnableSubmit(true)}
            onInvalid={() => setEnableSubmit(false)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ZeptoFormTextField
                  name="requestText"
                  value={request.requestText}
                  label="Justificativa"
                  validations="isExisty,minLength:1,maxLength:255"
                  validationError="Justificativa inválida"
                  required
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Grid>
              <div className={classes.buttonArea}>
                <ZeptoButton
                  label="Salvar"
                  type="submit"
                  loading={!enableSubmit}
                />
              </div>
            </Grid>
          </Formsy>
        </DialogContent>
      </ZeptoDialog >
      }
      <ZeptoDialog open={open} title="Extrato de Ponto" onClose={onClose}>
        <DialogContent dividers>
          <PointViewer point={point} />
        </DialogContent>
        <DialogActions>
          <ZeptoButton onClick={onClose} label="Fechar" />
          <ZeptoButton
            onClick={onDownload}
            label="Salvar"
            disabled={!pointReceipt}
          />
          {(userIs('USER') || userIs('ADMIN') || userIs('EMPLOYER')) && (
            <ZeptoButton
              onClick={() => (userIs('ADMIN') || userIs('EMPLOYER')) ? setShowDeleteJorney(true) : onDisable(point)}
              color="secondary"
              label="Apagar"
            />
          )}
        </DialogActions>
      </ZeptoDialog>
    </>
  );
};

PointDialog.propTypes = {
  point: PropTypes.shape({}).isRequired,
  onDisable: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  createRequest: PropTypes.func.isRequired,
  pointReceipt: PropTypes.PropTypes.shape({}),
};

const mapStateToProps = state => ({
  pointReceipt: state.points.pointReceipt,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createRequest,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
)(memo(PointDialog));
