import React, { useContext, useEffect, useReducer } from 'react';
import { Grid, LinearProgress, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import lodash from 'lodash';
import { ZeptoPaper, ZeptoButton } from '../../../../components';
import useValidator from '../../../../hooks/useValidator';

import BillingDataStore from '../../../../stores/BillingDataStore';
import BillingDataInput from './BillingDataInput';

const equals = (data1, data2) =>
  data1.name === data2.name &&
  data1.phone === data2.phone &&
  data1.cpf === data2.cpf &&
  data1.email === data2.email;

const rules = {
  billingData: {
    name: 'required|min:1|max:255',
    email: 'required|min:1|max:255|email',
    phone: 'phone',
    cpf: 'cpf',
  },
};

function BillingData(props) {
  const { companyId } = props;

  const {
    billingData,
    loadingBillingData,
    clear,
    load,
    update,
    updatingBillingData,
  } = useContext(BillingDataStore);

  useEffect(() => {
    load(companyId);
    return clear;
  }, [companyId]);

  function reducer(state, action) {
    const newState = lodash.cloneDeep(state);
    return lodash.set(newState, action.name, action.payload);
  }

  const [state, dispatch] = useReducer(reducer, {
    billingData: { name: '' },
  });

  const [isValid, getError] = useValidator(state, rules);

  useEffect(() => {
    if (billingData) dispatch({ name: 'billingData', payload: billingData });
  }, [billingData]);

  function onSaveClick() {
    if (!isValid() || equals(billingData, state.billingData)) {
      return;
    }

    update(companyId, state.billingData);
  }

  return (
    <ZeptoPaper>
      {loadingBillingData && <LinearProgress />}
      {state && (
        <Grid container spacing={2}>
          {updatingBillingData && (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          )}

          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="h6">Dados de cobrança</Typography>
              </Grid>

              <Grid item>
                <ZeptoButton
                  label="Salvar"
                  loading={updatingBillingData}
                  fullWidth={false}
                  onClick={onSaveClick}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <BillingDataInput
              value={state.billingData}
              onUpdate={dispatch}
              name="billingData"
              disabled={updatingBillingData}
              getError={getError}
            />
          </Grid>

          {updatingBillingData && (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          )}
        </Grid>
      )}
    </ZeptoPaper>
  );
}

BillingData.propTypes = {
  companyId: PropTypes.number.isRequired,
};

export default observer(BillingData);
