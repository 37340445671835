const InstallmentStatus = {
  pending: 'PENDING',
  paid: 'PAID',
  overdue: 'OVERDUE',
  refunded: 'REFUNDED',
  trial: 'TRIAL',
  canceled: 'CANCELED',
};

export const labelOf = status => {
  switch (status) {
    case InstallmentStatus.pending:
      return 'Pendente';

    case InstallmentStatus.paid:
      return 'Pago';
    case InstallmentStatus.overdue:
      return 'Vencido';
    case InstallmentStatus.refunded:
      return 'Reembolso';
    case InstallmentStatus.trial:
      return 'Trial';
    case InstallmentStatus.canceled:
      return 'Cancelado';

    default:
      return '';
  }
};

export default InstallmentStatus;
