import { createContext } from 'react';
import { decorate, observable } from 'mobx';
import { jsonFetch, jsonDelete, jsonPost } from '../utils/http';
import { appStore } from './AppStore';

const apiPath = companyId =>
  `${process.env.REACT_APP_API_PATH}/api/companies/${companyId}/closures`;

class UserClosureStore {
  closures;

  loadingClosures;

  lastDisable;

  list = async (companyId, params) => {
    this.loadingClosures = true;
    try {
      this.closures = await jsonFetch(
        `${apiPath(companyId)}/?${new URLSearchParams(params).toString()}`
      );
    } catch (e) {
      appStore.showError(
        `Ocorreu um erro ao carregar os fechamentos: ${e.message}`
      );
    }
    this.loadingClosures = false;
  };

  disable = async (companyId, ids) => {
    this.disablingClosures = true;
    try {
      await jsonDelete(`${apiPath(companyId)}/?ids=${ids}`);
      this.lastDisable = new Date();
    } catch (e) {
      appStore.showError(
        `Ocorreu um erro ao cancelar os fechamentos: ${e.message}`
      );
    }
    this.disablingClosures = false;
  };

  generate = async (companyId, params) => {
    this.generatingClosures = true;
    try {
      await jsonPost(`${apiPath(companyId)}`, params);

      appStore.showSuccess('Fechamento gerado com sucesso.');
    } catch (e) {
      appStore.showError(
        `Ocorreu um erro ao gerar os fechamentos: ${e.message}`
      );
    }
    this.generatingClosures = false;
  };
}

decorate(UserClosureStore, {
  closures: observable,
  loadingClosures: observable,
  disablingClosures: observable,
  generatingClosures: observable,
  lastDisable: observable,
});

export const userClosureStore = new UserClosureStore();

export default createContext(userClosureStore);
