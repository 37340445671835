import React, { memo, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import moment from 'moment';
import CancelIcon from '@material-ui/icons/Close';

import { ZeptoDatePicker, ZeptoTextField } from '../../../components';
import useValidator from '../../../hooks/useValidator';
import ZeptoFab from '../../../components/ZeptoFab';
import { dateFormat } from '../../../utils/Constants';

const useStyles = makeStyles({
  fab1: {
    paddingRight: 8,
  },
});

const rules = {
  name: 'required|min:1|max:50',
};

const inputProps = {
  max50: {
    maxLength: 50,
  },
};

const defaultHoliday = { date: new Date() };

const HolidayForm = memo(props => {
  const classes = useStyles();
  const { holiday, onSave, onCancel, loading, success } = props;

  const [data, setData] = useState({ ...defaultHoliday, ...holiday });

  const [isValid, errors] = useValidator(data, rules);

  const handleDateChange = name => newDate => {
    setData({ ...data, [name]: newDate.toDate() });
  };

  function handleChange(event) {
    setData({ ...data, [event.target.name]: event.target.value });
  }

  function onSaveClick() {
    if (isValid()) {
      onSave({ ...data, date: moment(data.date).format(dateFormat) });
    }
  }

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item md={3} xs={12}>
        <ZeptoDatePicker
          value={data.date}
          onChange={handleDateChange('date')}
          label="Data"
          disabled={loading}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <ZeptoTextField
          name="name"
          value={data.name}
          label="Nome"
          onChange={handleChange}
          errorMessage={errors('name')}
          required
          inputProps={inputProps.max50}
          disabled={loading}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <Grid container justify="flex-end" className={classes.fab1}>
          <Grid item>
            <ZeptoFab
              onClick={onSaveClick}
              loading={loading}
              success={success}
            />
          </Grid>
          <Grid item>
            <ZeptoFab
              onClick={onCancel}
              loading={loading}
              disabled={!!success}
              color="secondary"
              icon={<CancelIcon />}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

HolidayForm.propTypes = {};

export default HolidayForm;
