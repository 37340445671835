/**
 * Retorna a hora informada em milisegundos
 */
export default function hoursToMs(hoursTxt) {
  const separator = hoursTxt.indexOf(':');
  const minutes = hoursTxt.substring(separator + 1, hoursTxt.length);
  const hours = hoursTxt.substring(0, separator);

  const secondsInHours = Number(hours) * 60 * 60;
  const secondsInMinutes = Number(minutes) * 60;

  return (secondsInHours + secondsInMinutes) * 1000;
}
