import React, { useContext, useEffect, useState } from 'react';
import {
  Chip,
  DialogContent,
  Fab,
  Grid,
  TableCell,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import {
  ZeptoConfirmDialog,
  ZeptoDialog,
  ZeptoList,
  ZeptoPaper,
} from '../../../components';
import TimeAdjustmentStore from '../../../stores/TimeAdjustmentStore';
import { msToTime, toDate } from '../../../utils/DateUtils';
import TimeAdjustmentForm from './TimeAdjustmentForm';
import UserBalance from '../user-balance/UserBalance';

function TimeAdjustments(props) {
  const { employer } = props;
  const {
    list,
    timeAdjustments,
    loadingTimeAdjustments,
    clear,
    disabling,
    disable,
    lastDisabled,
  } = useContext(TimeAdjustmentStore);

  const [params, setParams] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [confirmOptions, setConfirmOptions] = React.useState({
    open: false,
  });

  function closeConfirmation() {
    setConfirmOptions({ open: false });
  }

  useEffect(() => {
    setParams({ page: 0, pageSize: 5 });

    return clear;
  }, [employer]);

  useEffect(() => {
    if (params) {
      list(employer.companyId, employer.id, params);
    }
  }, [params]);

  useEffect(() => {
    if (lastDisabled)
      setParams({
        ...params,
      });
  }, [lastDisabled]);

  function onChangePage(newParams) {
    setParams({
      ...params,
      ...newParams,
    });
  }

  function onDeleteConfirm(id) {
    closeConfirmation();
    disable(employer.companyId, employer.id, id);
  }

  function disableTimeAdjst(id) {
    return () => {
      setConfirmOptions({
        open: true,
        onConfirm: () => onDeleteConfirm(id),
        content: `Deseja realmente apagar o ajuste?`,
      });
    };
  }

  function onAddClick() {
    setShowForm(true);
  }

  function onSave() {
    setShowForm(false);

    setParams({
      ...params,
    });
  }

  return (
    <ZeptoPaper>
      <ZeptoList
        title={
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h6">Ajustes de tempo</Typography>
            </Grid>

            <Grid item>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <UserBalance employerId={employer.id} />
                </Grid>
                <Grid item>
                  <Fab
                    onClick={onAddClick}
                    size="small"
                    color="primary"
                    disabled={disabling}
                  >
                    <AddIcon />
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        onChangePage={onChangePage}
        headCells={
          <>
            <TableCell align="center">Data</TableCell>
            <TableCell align="center">Motivo</TableCell>
            <TableCell align="center">Horas</TableCell>
            <TableCell align="center">Lançado por</TableCell>
            <TableCell align="right"></TableCell>
          </>
        }
        renderBodyCells={row => (
          <>
            <TableCell align="center">{toDate(row.date)}</TableCell>
            <TableCell align="center">{row.reason}</TableCell>
            <TableCell align="center">
              <Chip
                label={msToTime(row.time)}
                color={row.type === 'NEGATIVE' ? 'secondary' : ''}
              />
            </TableCell>
            <TableCell align="center">{row.author.name}</TableCell>
            <TableCell align="right">
              <Fab
                onClick={disableTimeAdjst(row.id)}
                size="small"
                color="secondary"
                disabled={disabling}
              >
                <DeleteIcon />
              </Fab>
            </TableCell>
          </>
        )}
        results={timeAdjustments}
        loading={loadingTimeAdjustments}
      />

      {showForm && (
        <ZeptoDialog
          open
          title="Adicionar ajuste de tempo"
          onClose={() => setShowForm(false)}
          renderAction={() => (
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Grid item>
                <Fab
                  onClick={() => setShowForm(false)}
                  size="small"
                  color="secondary"
                >
                  <CloseIcon />
                </Fab>
              </Grid>
            </Grid>
          )}
        >
          <DialogContent>
            <TimeAdjustmentForm
              companyId={employer.companyId}
              employerId={employer.id}
              onSave={onSave}
            />
          </DialogContent>
        </ZeptoDialog>
      )}

      <ZeptoConfirmDialog
        open={confirmOptions.open}
        onConfirm={confirmOptions.onConfirm}
        onCancel={closeConfirmation}
        content={confirmOptions.content}
        loading={disabling}
      />
    </ZeptoPaper>
  );
}

TimeAdjustments.propTypes = {};

export default observer(TimeAdjustments);
