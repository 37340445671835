export default function downscaleImage(dataUri) {
  const newWidth = 200;
  const imageType = 'image/jpeg';

  const image = new Image();

  const result = new Promise(resolve => {
    image.addEventListener('load', () => {
      const oldWidth = image.width;
      const oldHeight = image.height;
      const newHeight = Math.floor((oldHeight / oldWidth) * newWidth);
      const canvas = document.createElement('canvas');

      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext('2d');

      // Draw the downscaled image on the canvas and return the new data URL.
      ctx.drawImage(image, 0, 0, newWidth, newHeight);
      const newDataUrl = canvas.toDataURL(imageType, 1);

      resolve(newDataUrl);
    });
  });

  image.setAttribute('src', dataUri);
  return result;
}
