import { createContext } from 'react';
import { decorate, observable } from 'mobx';
import { jsonFetch } from '../utils/http';
import { appStore } from './AppStore';

const apiPath = companyId =>
  `${process.env.REACT_APP_API_PATH}/api/companies/${companyId}/subscription/installments`;

class InstallmentStore {
  installments;

  loadingInstallments;

  loadInstallments = async companyId => {
    this.loadingInstallments = true;
    try {
      this.installments = await jsonFetch(apiPath(companyId));
    } catch (e) {
      appStore.showError(
        `Ocorreu um erro ao carregar os pagamentos: ${e.message}`
      );
    }
    this.loadingInstallments = false;
  };
}

decorate(InstallmentStore, {
  installments: observable,
  loadingInstallments: observable,
});

export const installmentStore = new InstallmentStore();

export default createContext(installmentStore);
