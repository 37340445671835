import React from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';
import ZeptoTimePicker from './ZeptoTimePicker';

const ZeptoFormTimePicker = props => {
  const { setValue, getValue, getErrorMessage } = props;
  const errorMessage = getErrorMessage();

  return (
    <ZeptoTimePicker
      {...props}
      value={getValue() || null}
      onChange={setValue}
      errorMessage={errorMessage}
      error={!!errorMessage}
    />
  );
};

ZeptoFormTimePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  setValue: PropTypes.func,
  getValue: PropTypes.func,
  getErrorMessage: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
};

export default withFormsy(ZeptoFormTimePicker);
