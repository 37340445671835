import React, { useContext, useEffect } from 'react';
import { Chip, CircularProgress, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import TimeAdjustmentStore from '../../../stores/TimeAdjustmentStore';
import WorkDayStore from '../../../stores/WorkDayStore';
import { msToTime } from '../../../utils/DateUtils';

function UserBalance(props) {
  const { employerId } = props;

  const { balance, loadingBalance, loadBalance, clear } = useContext(
    WorkDayStore
  );

  const { lastCreated, lastDisabled } = useContext(TimeAdjustmentStore);

  useEffect(() => {
    loadBalance(employerId);

    return clear;
  }, [employerId, lastCreated, lastDisabled]);

  const label = `Saldo ${
    !loadingBalance && balance !== undefined ? msToTime(balance) : ''
  }`;
  const icon = loadingBalance ? <CircularProgress size={20} /> : undefined;
  const color = balance < 0 ? 'secondary' : 'primary';

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <Chip color={color} label={label} icon={icon} />
      </Grid>
    </Grid>
  );
}

UserBalance.propTypes = {};

export default observer(UserBalance);
