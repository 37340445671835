import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { LinearProgress, Divider, Hidden, InputLabel } from '@material-ui/core';
import { loadCompanies } from '../../operators/companiesOperator';
import {
  editUser,
  createUser,
  updateUser,
} from '../../operators/usersOperator';
import { onUserUploadPhoto } from '../../operators/authOperator';
import userIs from '../../utils/permissionUtils';
import {
  ZeptoButton,
  ZeptoTextField,
  ZeptoMaskedTextField,
  ZeptoSelect,
  ZeptoDatePicker,
  ZeptoPaper,
} from '../../components';
import useValidator from '../../hooks/useValidator';
import ZeptoNumberInput from '../../components/ZeptoNumberInput';
import { dateFormat } from '../../utils/Constants';
import UserPhotoPicker from './UserPhotoPicker';

const AUDITOR_RULES = {
  name: 'required|min:1|max:255',
  email: 'required|min:1|max:255|email',

  password: 'digits_between:6,20|confirmed',
  companyId: 'required',
  role: 'required',
};

const RULES = {
  ...AUDITOR_RULES,
  rg: 'numeric|digits_between:0,20',

  pis: [{ required_if: ['role', 'USER'] }, 'numeric', 'digits_between:0,11'],
  admission: [{ required_if: ['role', 'USER'] }, 'validDate'],
  cpf: 'required|cpf',
};

const customNames = {
  password: 'senha',
};

const inputProps = {
  max255: {
    maxLength: 255,
  },
  max20: {
    maxLength: 20,
  },
  max11: {
    maxLength: 11,
  },
};

const initial = {
  role: 'USER',
  admission: new Date(),
  preMarkedBreak: false,
  companyId: '',
  cpf: '',
  rg: '',
};

const toData = entity => {
  if (!entity) return null;

  return {
    ...entity,
    admission: entity.admission
      ? moment(entity.admission, dateFormat).toDate()
      : new Date(),
  };
};

const UserForm = props => {
  const [user, setUser] = useState(initial);

  function handleChange(name) {
    return event => {
      setUser({ ...user, [name]: event.target.value });
    };
  }

  function handleDateChange(name) {
    return newDate => {
      setUser({ ...user, [name]: newDate.toDate() });
    };
  }

  // function handleCheckboxChange(event) {
  //   setUser({ ...user, [event.target.name]: event.target.checked });
  // }

  // function handleCheckboxChange(name) {
  //   return event => {
  //     setUser({ ...user, [name]: event.target.checked });
  //   };
  // }

  const rules = user && user.role === 'AUDITOR' ? AUDITOR_RULES : RULES;
  const [isValid, getError] = useValidator(user, rules, customNames);

  const {
    loadCompanies,
    companies,
    selected,
    params,
    editUser,
    processingLoadUser,
    processingCreateUser,
    processingUpdateUser,
    authenticatedUser,
    onUserUploadPhoto,
  } = props;

  useEffect(() => {
    setUser(toData(selected));
  }, [selected]);

  const loading = () =>
    processingLoadUser || processingCreateUser || processingUpdateUser;
  useEffect(() => {
    if (userIs('ADMIN')) {
      loadCompanies();
    }

    if (Number(params.id) > 0) {
      if (!selected) {
        editUser(params.id);
      } else {
        setUser(toData(selected));
      }
    } else {
      setUser({
        ...initial,
        companyId: authenticatedUser.companyId,
      });
    }
  }, []);

  function handleSubmit() {
    if (!isValid()) return;

    const { createUser, updateUser } = props;

    const usr = {
      ...user,
      id: user.id,
      admission: moment(user.admission).format(dateFormat),
    };
    if (usr.id) {
      updateUser(usr);
    } else {
      createUser(usr);
    }
  }

  function onUploadPhoto(lastUpload) {
    setUser({ ...user, lastPhotoUpload: lastUpload, hasPhoto: true });

    if (user.id === authenticatedUser.id) onUserUploadPhoto();
  }

  const layoutProps = { xs: 12 };
  if (user && user.id) layoutProps.md = 6;

  return (
    <ZeptoPaper>
      <Grid container spacing={1}>
        {/* Workaroud para impedir o chrome de autocompletar a senha */}
        <input type="password" style={{ opacity: 0, position: 'absolute' }} />
        <Grid item xs={12}>
          {loading() && <LinearProgress />}
        </Grid>

        {user && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {'Formulário de Usuário'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item {...layoutProps}>
                  <Grid container spacing={2}>
                    {user.id && user.role !== 'AUDITOR' && (
                      <Hidden mdUp>
                        <Grid item xs={12}>
                          <InputLabel required>Foto</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <UserPhotoPicker
                            user={user}
                            onUserUploadPhoto={onUploadPhoto}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </Hidden>
                    )}

                    <Grid item xs={12}>
                      <ZeptoTextField
                        name="name"
                        value={user.name}
                        label="Nome"
                        onChange={handleChange('name')}
                        errorMessage={getError('name')}
                        required
                        inputProps={inputProps.max255}
                        disabled={!userIs('ADMIN', 'EMPLOYER')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ZeptoTextField
                        name="email"
                        value={user.email}
                        label="Email"
                        type="email"
                        required
                        onChange={handleChange('email')}
                        errorMessage={getError('email')}
                        inputProps={inputProps.max255}
                        disabled={!userIs('ADMIN', 'EMPLOYER')}
                      />
                    </Grid>

                    {!user.id && userIs('ADMIN', 'EMPLOYER') && (
                      <>
                        {companies.length > 0 && userIs('ADMIN') && (
                          <Grid item xs={12}>
                            <ZeptoSelect
                              name="companyId"
                              value={user.companyId}
                              label="Empresa"
                              required
                              onChange={handleChange('companyId')}
                              errorMessage={getError('companyId')}
                            >
                              {companies.map(company => (
                                <MenuItem key={company.id} value={company.id}>
                                  {company.businnesName}
                                </MenuItem>
                              ))}
                            </ZeptoSelect>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <ZeptoSelect
                            name="role"
                            value={user.role}
                            label="Perfil"
                            onChange={handleChange('role')}
                            errorMessage={getError('role')}
                            required
                          >
                            <MenuItem value="USER">Usuário comum</MenuItem>
                            <MenuItem value="EMPLOYER">Gerente</MenuItem>
                            <MenuItem value="AUDITOR">Auditor</MenuItem>
                          </ZeptoSelect>
                        </Grid>
                      </>
                    )}

                    {user.role !== 'AUDITOR' && (
                      <>
                        <Grid item xs={12}>
                          <ZeptoNumberInput
                            name="rg"
                            value={user.rg}
                            label="RG"
                            inputProps={inputProps.max20}
                            onChange={handleChange('rg')}
                            errorMessage={getError('rg')}
                            disabled={!userIs('ADMIN', 'EMPLOYER')}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <ZeptoMaskedTextField
                            name="cpf"
                            value={user.cpf}
                            label="CPF"
                            required
                            onChange={handleChange('cpf')}
                            errorMessage={getError('cpf')}
                            disabled={!userIs('ADMIN', 'EMPLOYER')}
                            inputProps={{
                              mask: [
                                /\d/,
                                /\d/,
                                /\d/,
                                '.',
                                /\d/,
                                /\d/,
                                /\d/,
                                '.',
                                /\d/,
                                /\d/,
                                /\d/,
                                '-',
                                /\d/,
                                /\d/,
                              ],
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <ZeptoDatePicker
                            label="Admissão"
                            value={user.admission}
                            errorMessage={getError('admission')}
                            onChange={handleDateChange('admission')}
                            disabled={!userIs('ADMIN', 'EMPLOYER')}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <ZeptoNumberInput
                            name="pis"
                            value={user.pis}
                            label="PIS"
                            inputProps={inputProps.max11}
                            onChange={handleChange('pis')}
                            errorMessage={getError('pis')}
                            disabled={!userIs('ADMIN', 'EMPLOYER')}
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ZeptoTextField
                            name="motherName"
                            value={user.motherName}
                            label="Nome da Mãe"
                            onChange={handleChange('motherName')}
                            errorMessage={getError('motherName')}
                            inputProps={inputProps.max255}
                            disabled={!userIs('ADMIN', 'EMPLOYER')}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ZeptoTextField
                            name="fatherName"
                            value={user.fatherName}
                            label="Nome do Pai"
                            onChange={handleChange('fatherName')}
                            errorMessage={getError('fatherName')}
                            inputProps={inputProps.max255}
                            disabled={!userIs('ADMIN', 'EMPLOYER')}
                          />
                        </Grid>
                      </>
                    )}

                    {/* <Grid item xs={12}>
                      <ZeptoCheckbox
                        label="Intervalo pré-assinalado"
                        checked={user.preMarkedBreak}
                        onChange={handleCheckboxChange('preMarkedBreak')}
                        disabled={!userIs('ADMIN', 'EMPLOYER')}
                      />
                    </Grid> */}
                  </Grid>
                </Grid>
                {user.id && user.role !== 'AUDITOR' && (
                  <Hidden smDown>
                    <Grid item {...layoutProps}>
                      <UserPhotoPicker
                        user={user}
                        onUserUploadPhoto={onUploadPhoto}
                      />
                    </Grid>
                  </Hidden>
                )}
              </Grid>
            </Grid>

            {authenticatedUser.id === user.id && (
              <>
                <Grid item xs={12}>
                  <ZeptoTextField
                    name="password"
                    value={user.password}
                    label="Senha"
                    type="password"
                    onChange={handleChange('password')}
                    errorMessage={getError('password')}
                    inputProps={inputProps.max20}
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={12}>
                  <ZeptoTextField
                    name="password_confirmation"
                    value={user.password_confirmation}
                    label="Confirme a senha"
                    type="password"
                    onChange={handleChange('password_confirmation')}
                    errorMessage={getError('password_confirmation')}
                    inputProps={inputProps.max20}
                    autoComplete={false}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              {loading() && <LinearProgress />}
            </Grid>

            <Grid item xs={12}>
              <Grid container justify="flex-end">
                <Grid item>
                  <ZeptoButton
                    name="type"
                    label="Salvar"
                    loading={loading()}
                    onClick={handleSubmit}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </ZeptoPaper>
  );
};

UserForm.propTypes = {
  authenticatedUser: PropTypes.shape({}),
  companies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  params: PropTypes.shape({}).isRequired,
  processingLoadUser: PropTypes.bool,
  processingCreateUser: PropTypes.bool,
  processingUpdateUser: PropTypes.bool,
  loadCompanies: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  onUserUploadPhoto: PropTypes.func.isRequired,
  selected: PropTypes.shape({}),
  company: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  authenticatedUser: state.auth.authenticatedUser,
  processingLoadUser: state.users.processingLoadUser,
  processingCreateUser: state.users.processingCreateUser,
  processingUpdateUser: state.users.processingUpdateUser,
  company: state.companies.selected,
  companies: state.companies.companies,
  selected: state.users.selected,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      editUser,
      createUser,
      loadCompanies,
      updateUser,
      onUserUploadPhoto,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
