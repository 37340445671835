import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Select,
} from '@material-ui/core';

const ZeptoSelect = props => {
  const {
    name,
    label,
    required,
    children,
    value,
    onChange,
    onUpdate,
    errorMessage,
    disabled,
  } = props;

  function onValueChange(event) {
    if (!onUpdate) onChange(event);
    else onUpdate({ name, payload: event.target.value });
  }

  return (
    <FormControl
      error={!!errorMessage}
      required={required}
      disabled={disabled}
      margin="normal"
      fullWidth
    >
      <InputLabel htmlFor="select">{label}</InputLabel>
      <Select
        value={value || ''}
        onChange={onValueChange}
        name={name}
        input={<Input />}
        fullWidth
      >
        {children}
      </Select>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

ZeptoSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
};

export default ZeptoSelect;
