import React from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles, Fab } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import DeleteIcon from '@material-ui/icons/Delete';
import AccountPlus from '@material-ui/icons/Add';
import UndoIcon from '@material-ui/icons/Undo';
import EditIcon from '@material-ui/icons/Edit';
import { push } from 'react-router-redux';
import {
  disableUser,
  enableUser,
  editUser,
  loadEmployers,
} from '../../../operators/usersOperator';
import { ZeptoConfirmDialog } from '../../../components';
import UserList from '../list/UserList';

const useStyles = makeStyles(theme => ({
  disabled: {
    opacity: 0.5,
    color: 'red',
  },
  fab2: {
    margin: theme.spacing(2),
  },
  fab1: {
    margin: theme.spacing(1),
  },
}));

const EmployersList = (props) => {
  React.useEffect(() => {
    const { loadEmployers } = props;
    loadEmployers();
  }, []);

  const [confirmOptions, setConfirmOptions] = React.useState({
    open: false,
  });

  const closeConfirmation = () => setConfirmOptions({ open: false });

  const onEditUser = (user) => {
    const { editUser } = props;
    editUser(user.id);
  };

  const onDisableUserConfirm = (user) => {
    const { disableUser } = props;
    disableUser(user);
    closeConfirmation();
  };

  const onDisableUser = (user) => {
    setConfirmOptions({
      open: true,
      onConfirm: () => onDisableUserConfirm(user),
      content: `Deseja realmente desabilitar o usuário '${user.name}'?`,
    });
  };

  const onEnableUserConfirm = (user) => {
    const { enableUser } = props;
    enableUser(user);
    closeConfirmation();
  };

  const onEnableUser = (user) => {
    setConfirmOptions({
      open: true,
      onConfirm: () => onEnableUserConfirm(user),
      content: `Deseja realmente habilitar o usuário '${user.name}'?`,
    });
  };
  const onNewUser = () => {
    const { push } = props;
    push('/users/0/edit');
  };

  const classes = useStyles();

  const {
    users,
    processingLoadUser,
    processingLoadUsers,
    processingDisableUser,
    processingEnableUser,
  } = props;

  const loading = () => processingLoadUser
    || processingLoadUsers
    || processingDisableUser
    || processingEnableUser;

  return (
    <>
      <UserList
        loading={loading()}
        title="Empregadores"
        users={users}
        renderActions={() => (
          <Fab
            className={classes.fab2}
            disabled={loading()}
            onClick={onNewUser}
            color="primary"
            size="small"
          >
            <AccountPlus />
          </Fab>
        )}
        headRows={[
          {
            id: 'acoes',
            label: '',
          },
        ]}
        renderCells={user => (
          <>
            <TableCell align="right">
              <Fab
                className={classes.fab1}
                onClick={() => onEditUser(user)}
                disabled={!user.enabled || loading()}
                size="small"
                color="primary"
              >
                <EditIcon />
              </Fab>
              {user.enabled && (
                <Fab
                  className={classes.fab1}
                  onClick={() => onDisableUser(user)}
                  color="secondary"
                  disabled={loading()}
                  size="small"
                >
                  <DeleteIcon />
                </Fab>
              )}
              {!user.enabled && (
                <Fab
                  className={classes.fab1}
                  onClick={() => onEnableUser(user)}
                  disabled={loading()}
                  color="primary"
                  size="small"
                >
                  <UndoIcon />
                </Fab>
              )}
            </TableCell>
          </>
        )}
      />

      <ZeptoConfirmDialog
        open={confirmOptions.open}
        onConfirm={confirmOptions.onConfirm}
        onCancel={closeConfirmation}
        content={confirmOptions.content}
      />
    </>
  );
};

EmployersList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})),
  disableUser: PropTypes.func.isRequired,
  enableUser: PropTypes.func.isRequired,
  processingLoadUser: PropTypes.bool,
  processingLoadUsers: PropTypes.bool,
  processingDisableUser: PropTypes.bool,
  processingEnableUser: PropTypes.bool,
  editUser: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
};

EmployersList.defaultProps = {
  users: [],
};

const mapStateToProps = store => ({
  users: store.users.users,
  processingLoadUser: store.users.processingLoadUser,
  processingLoadUsers: store.users.processingLoadUsers,
  processingDisableUser: store.users.processingDisableUser,
  processingEnableUser: store.users.processingEnableUser,
});
const mapDispatchToProps = dispatch => bindActionCreators(
  {
    loadEmployers,
    disableUser,
    enableUser,
    editUser,
    push,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(EmployersList));
