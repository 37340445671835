import { useReducer } from 'react';
import lodash from 'lodash';
import useValidator from './useValidator';

function reducer(state, action) {
  const newState = lodash.cloneDeep(state);
  return lodash.set(newState, action.name, action.payload);
}

function useForm(initialValue, rules) {
  const [value, onUpdate] = useReducer(reducer, initialValue || {});

  const [isValid, getError] = useValidator(value, rules);

  return { value, onUpdate, isValid, getError };
}

export default useForm;
