import { push } from 'react-router-redux';
import { Creators as RequestActions } from '../ducks/requests';
import { Creators as WorkDaysActions } from '../ducks/workDays';
import { Creators as AppActions } from '../ducks/app';
import { jsonPost, jsonPut } from '../utils/http';
import { RequestType } from '../model/Request';

const apiPath = `${process.env.REACT_APP_API_PATH}/api/requests`;

export function newRequest(date) {
  return dispatch => {
    dispatch(WorkDaysActions.changeDate(date));
    dispatch(
      RequestActions.changeSelected({
        type: RequestType.enable,
      })
    );
    dispatch(push('/me/register/request'));
  };
}

export function createRequest(request) {
  return dispatch => {
    dispatch(RequestActions.createRequestRequest());

    return jsonPost(`${apiPath}`, request)
      .then(json => {
        dispatch(RequestActions.createRequestSuccess(json));
        dispatch(AppActions.showSuccess('Solicitação cadastrada com sucesso.'));
        dispatch(push('/me'));
      })
      .catch(error => {
        dispatch(RequestActions.createRequestFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao criar a solicitação: ${error.message}`
          )
        );
      });
  };
}

export function approveRequest(request, callback) {
  return dispatch => {
    dispatch(RequestActions.approveRequestRequest());

    return jsonPut(`${apiPath}/${request.id}/approve`, request)
      .then(json => {
        dispatch(RequestActions.approveRequestSuccess(json));
        dispatch(AppActions.showSuccess('Solicitação aprovada com sucesso.'));
        if (callback) {
          callback();
        }
      })
      .catch(error => {
        dispatch(RequestActions.approveRequestFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao aprovar a solicitação: ${error.message}`
          )
        );
      });
  };
}

export function rejectRequest(request, callback) {
  return dispatch => {
    dispatch(RequestActions.rejectRequestRequest());

    return jsonPut(`${apiPath}/${request.id}/reject`, request)
      .then(json => {
        dispatch(RequestActions.rejectRequestSuccess(json));
        dispatch(AppActions.showSuccess('Solicitação rejeitada com sucesso.'));
        if (callback) {
          callback();
        }
      })
      .catch(error => {
        dispatch(RequestActions.rejectRequestFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao rejeitar a solicitação: ${error.message}`
          )
        );
      });
  };
}

export function newRequest2() { }
