import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import { push } from 'react-router-redux';
import Moment from 'moment';
import { loadCompanies } from '../../operators/companiesOperator';
import { ZeptoTable } from '../../components';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  progress: {
    margin: theme.spacing(2),
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
  fab: {
    margin: theme.spacing(2),
  },
}));

const CompaniesList = props => {
  const classes = useStyles();
  useEffect(() => {
    const { loadCompanies } = props;
    loadCompanies();
  }, []);

  const onCompanyClick = id => {
    const { push } = props;
    push(`/companies/${id}`);
  };

  const { companies, processingLoadCompanies } = props;
  return (
    <>
      <ZeptoTable
        title="Empresas"
        loading={processingLoadCompanies}
        onRowClick={onCompanyClick}
        headRows={[
          {
            id: 'name',
            label: 'Razão social',
          },
          {
            id: 'expiration',
            label: 'Expiração',
          },
        ]}
        renderCells={company => (
          <>
            <TableCell className={!company.enabled ? classes.disabled : null}>
              {company.businnesName}
            </TableCell>
            <TableCell className={!company.enabled ? classes.disabled : null}>
              {company.expiration
                ? Moment(company.expiration).format('DD/MM/YYYY')
                : ''}
            </TableCell>
          </>
        )}
        rows={companies}
      />
      {/* <div>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Typography component="h1" variant="h5" className={classes.title}>
            {'Empresas'}
          </Typography>
          <Fab
            color="primary"
            aria-label="Add"
            disabled={processingLoadCompanies}
            onClick={this.onNewCompanyClick}
            className={classes.fab}
            size="small"
          >
            <AddIcon />
          </Fab>
        </Grid>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} align="left">
                  {'Nome'}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {'Expiração'}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.map(company => (
                <TableRow
                  hover
                  onClick={event => this.onCompanyClick(event, company)}
                  tabIndex={-1}
                  key={company.id}
                >
                  <TableCell
                    className={classes.tableCell}
                    component="th"
                    scope="row"
                  >
                    {company.businnesName}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {company.expiration
                      ? Moment(company.expiration).format('DD/MM/YYYY')
                      : ''}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </div>
      )}
    </> */}
    </>
  );
};

const mapStateToProps = store => ({
  errorMessage: store.companies.errorMessage,
  companies: store.companies.companies,
  processingLoadCompanies: store.companies.processingLoadCompanies,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadCompanies,
      push,
    },
    dispatch
  );

CompaniesList.propTypes = {
  processingLoadCompanies: PropTypes.bool,
  companies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  push: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(CompaniesList));
