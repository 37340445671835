import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';

const ZeptoSwitcher = memo(props => {
  const { checked, onChange, label, optionA, optionB } = props;
  return (
    <FormControlLabel
      control={
        <Grid component="label" container alignItems="center" spacing={0}>
          <Grid item>
            <Typography>{optionA}</Typography>
          </Grid>
          <Grid item>
            <Switch
              size="small"
              checked={checked}
              onChange={onChange}
              inputProps={{ 'aria-label': 'secondary dense' }}
            />
          </Grid>
          <Grid item>
            <Typography>{optionB}</Typography>
          </Grid>
        </Grid>
      }
      label={label}
      labelPlacement="start"
    />
  );
});

ZeptoSwitcher.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  optionA: PropTypes.string,
  optionB: PropTypes.string,
};

export default ZeptoSwitcher;
