import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles, Link } from '@material-ui/core';
import StepperButtonBar from '../../components/StepperButtonBar';
import AgreementTerms from '../legal/AgreementTerms';
import { ZeptoCheckbox } from '../../components';

const useStyles = makeStyles({
  agreement: {
    height: '55vh',
    overflowY: 'scroll',
  },
});

const AgreementStep = memo(props => {
  const classes = useStyles();
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [checkedPrivacyPolicy, setCheckedPrivacyPolicy] = useState(false);
  const { onNext, onBack } = props;

  function onCheckAgreementTerms(event) {
    setCheckedTerms(event.target.checked);
  }

  function onCheckPrivacyPolicy(event) {
    setCheckedPrivacyPolicy(event.target.checked);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AgreementTerms className={classes.agreement} />
      </Grid>

      <Grid item xs={12}>
        <ZeptoCheckbox
          label="Li e aceito os termos de uso."
          checked={checkedTerms}
          onChange={onCheckAgreementTerms}
        />
      </Grid>

      <Grid item xs={12}>
        <ZeptoCheckbox
          label={
            <Link
              href="http://caesser.com.br/politica-de-privacidade"
              variant="body2"
              align="center"
              target="_blank"
              rel="noopener noreferrer"
            >
              Li e aceito a Política de Privacidade
            </Link>
          }
          checked={checkedPrivacyPolicy}
          onChange={onCheckPrivacyPolicy}
        />
      </Grid>

      <Grid item xs={12}>
        <StepperButtonBar
          onBack={onBack}
          onNext={onNext}
          nextEnabled={!!checkedTerms && !!checkedPrivacyPolicy}
        />
      </Grid>
    </Grid>
  );
});

AgreementStep.propTypes = {
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default AgreementStep;
