import React, { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Grid, LinearProgress } from '@material-ui/core';
import UserInput from '../users/UserInput';
import useValidator from '../../hooks/useValidator';
import StepperButtonBar from '../../components/StepperButtonBar';
import { checkUser, clearUserCheck } from '../../operators/signupOperator';

const RULES = {
  name: 'required|min:1|max:255',
  email: 'required|min:1|max:255|email',
  rg: 'numeric|min:1|max:255',

  password: 'required|min:6|max:20|confirmed',
  password_confirmation: 'required',
  cpf: 'required|cpf',
  phone: 'required|phone:14',
};

const customNames = {
  password: 'senha',
};

const initial = { cpf: '' };

const ManagerStep = memo(props => {
  const {
    manager,
    onNext,
    onBack,
    userCheck,
    clearUserCheck,
    loadingCheckUser,
  } = props;

  const [managerDraft, setManagerDraft] = useState(initial);

  const [isValid, errors] = useValidator(managerDraft, RULES, customNames);

  function onNextClick() {
    if (isValid()) {
      const { checkUser } = props;
      checkUser({ email: managerDraft.email });
    }
  }

  useEffect(() => {
    if (userCheck && userCheck.exists === false) {
      onNext(managerDraft);
    }

    return clearUserCheck;
  }, [userCheck]);

  useEffect(() => {
    if (manager) setManagerDraft({ ...manager });
  }, [manager]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <UserInput
          errors={errors}
          onChange={setManagerDraft}
          user={managerDraft}
        />
      </Grid>

      {loadingCheckUser && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}

      <Grid item xs={12}>
        <StepperButtonBar
          nextEnable={isValid}
          onNext={onNextClick}
          onBack={onBack}
          loading={loadingCheckUser}
        />
      </Grid>
    </Grid>
  );
});

ManagerStep.propTypes = {
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  checkUser: PropTypes.func.isRequired,
  clearUserCheck: PropTypes.func.isRequired,
  loadingCheckUser: PropTypes.bool,
  userCheck: PropTypes.shape({
    exists: PropTypes.bool,
  }),
};

const mapStateToProps = state => ({
  loadingCheckUser: state.signup.loadingCheckUser,
  userCheck: state.signup.userCheck,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      checkUser,
      clearUserCheck,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagerStep);
