import { createContext } from 'react';
import { decorate, observable } from 'mobx';
import { appStore } from './AppStore';
import { jsonPut, jsonFetchBlob } from '../utils/http';

const apiPath = userId =>
  `${process.env.REACT_APP_API_PATH}/api/users/${userId}`;

class UserStore {
  uploadingPhoto;

  loadingPhoto = {};

  photos = {};

  lastUpload;

  uploadPhoto = async (user, photo) => {
    this.uploadingPhoto = true;

    try {
      await jsonPut(`${apiPath(user.id)}/photo`, { photo });
      this.lastUpload = new Date();
      this.photos = {};
      appStore.showSuccess('Upload realizado com sucesso.');
    } catch (e) {
      appStore.showError(`Ocorreu um erro ao realizar o upload: ${e.message}`);
    }

    this.uploadingPhoto = false;
  };

  loadPhoto = async (user, size = 'DEFAULT') => {
    if (!user.hasPhoto) {
      this.photos[size] = null;
      return;
    }

    this.loadingPhoto[size] = true;
    try {
      this.photos[size] = await jsonFetchBlob(
        `${apiPath(user.id)}/photo?size=${size || ''}`
      );
    } catch (e) {
      if (e.response.status === 404) {
        this.photos[size] = null;
      } else {
        appStore.showError(`Ocorreu um erro ao carregar a foto: ${e.message}`);
      }
    }

    this.loadingPhoto[size] = false;
  };

  getPhoto = (size = 'DEFAULT') => {
    return this.photos[size];
  };
}

decorate(UserStore, {
  uploadingPhoto: observable,
  loadingPhoto: observable,
  photos: observable,
  lastUpload: observable,
});

export const userStore = new UserStore();

export default createContext(userStore);
