import { createActions, createReducer } from 'reduxsauce';

/* #region Action types & creators */
export const { Types, Creators } = createActions({
  loadPlansRequest: null,
  loadPlansSuccess: ['plans'],
  loadPlansFailure: null,
});
/* #endregion */

/* #region Handlers */
const INITIAL_STATE = {};

/* #region Load plans */
const loadPlansRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingPlans: true,
});

const loadPlansSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingPlans: false,
  plans: action.plans,
});

const loadPlansFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingPlans: false,
});
/* #endregion */

export default createReducer(INITIAL_STATE, {
  [Types.LOAD_PLANS_REQUEST]: loadPlansRequest,
  [Types.LOAD_PLANS_SUCCESS]: loadPlansSuccess,
  [Types.LOAD_PLANS_FAILURE]: loadPlansFailure,
});
