import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

const ZeptoDateTimePicker = (props) => {
  const {
    label, onChange, value, errorMessage,
  } = props;

  return (
    <KeyboardDateTimePicker
      autoOk
      fullWidth
      ampm={false}
      format="DD/MM/YYYY HH:mm"
      label={label}
      value={value !== null ? moment(value, 'DD/MM/YYYY HH:mm') : null}
      onChange={onChange}
      error={!!errorMessage}
      helperText={errorMessage}
      disableFuture
    />
  );
};

ZeptoDateTimePicker.propTypes = {
  label: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default ZeptoDateTimePicker;
