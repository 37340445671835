import React, { memo } from 'react';
import {
  AppBar,
  Avatar,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { blue, yellow, green, red, grey } from '@material-ui/core/colors';
import classnames from 'classnames';
import PublicIcon from '@material-ui/icons/Public';
import FaceIcon from '@material-ui/icons/Face';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DescriptionIcon from '@material-ui/icons/Description';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import LockIcon from '@material-ui/icons/Lock';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import logo from '../../assets/img/logo-white-sm-3.png';
import logoSml from '../../assets/img/logosm.png';
import MainImg from '../../assets/img/main.png';
import { ZeptoButton, ZeptoPaper } from '../../components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    'overflow-x': 'hidden',
  },
  appBar: {
    // width:: theme.zIndex.drawer + 1,
  },

  title: {
    flexGrow: 1,
  },

  logo: {},
  mainImg: {
    maxWidth: '90%',
    display: 'block',
    margin: 'auto',
  },

  feature: {
    textAlign: 'center',
  },

  featureIcon: {
    fontSize: 40,
  },

  panel: {
    padding: theme.spacing(2),
    paddingTop: 50,
    paddingBottom: 50,
    [theme.breakpoints.up(959)]: {
      padding: theme.spacing(8),
      paddingTop: 150,
      paddingBottom: 150,
    },
  },

  panelSm: {
    padding: theme.spacing(2),
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.up(959)]: {
      padding: theme.spacing(8),
      paddingTop: 50,
      paddingBottom: 50,
    },
  },

  grayBg: {
    backgroundColor: '#f0f0f0',
  },

  whiteBg: {
    backgroundColor: '#ffffff',
  },

  blue: {
    color: blue[400],
  },

  yellow: {
    color: yellow[900],
  },

  green: {
    color: green[600],
  },

  red: {
    color: red[600],
  },

  softYellow: {
    color: yellow[700],
  },

  grey: {
    color: grey[700],
  },

  zeptoFeatureItem: { display: 'flex' },
  zeptoFeatureItemIcon: { paddingRight: 10 },
  smallLogo: {
    width: 30,
    height: 30,
    marginRight: 10,
  },

  blackColor: {
    color: '#000000',
  },
}));

const ZeptoFeature = memo(props => {
  const classes = useStyles();
  const { title, icon } = props;

  return (
    <div className={classes.feature}>
      {icon}
      <Typography color="primary">{title}</Typography>
    </div>
  );
});

const ZeptoFeatuleItem = memo(props => {
  const { title } = props;

  return (
    <ListItem button>
      <ListItemIcon>
        <CheckCircleOutlineIcon />
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );
});
const ZeptoCard = memo(() => {
  const classes = useStyles();

  const responsiveIcons = {
    xs: 6,
    sm: 4,
  };

  return (
    <div style={{ maxWidth: 400, margin: 'auto' }}>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.blackColor}>
            Zepto
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <br />
          <Typography align="justify" className={classes.blackColor}>
            Sistema de controle inteligente da jornada de trabalho de
            colaboradores.
            <br />
            <br />
            Com o ZEPTO os colaboradores são mais pontuais, sua empresa ganha
            produtividade e pontualidade.
            <br />
            <br />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            spacing={6}
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid item {...responsiveIcons}>
              <ZeptoFeature
                title="Online"
                icon={
                  <PublicIcon
                    className={classnames(classes.featureIcon, classes.blue)}
                  />
                }
              />
            </Grid>

            <Grid item {...responsiveIcons}>
              <ZeptoFeature
                title="Seguro"
                icon={
                  <LockIcon
                    className={classnames(classes.featureIcon, classes.yellow)}
                  />
                }
              />
            </Grid>

            <Grid item {...responsiveIcons}>
              <ZeptoFeature
                title="Reconhecimento facial"
                icon={
                  <FaceIcon
                    className={classnames(classes.featureIcon, classes.green)}
                  />
                }
              />
            </Grid>
            <Grid item {...responsiveIcons}>
              <ZeptoFeature
                title="Geolocalização"
                icon={
                  <LocationOnIcon
                    className={classnames(classes.featureIcon, classes.red)}
                  />
                }
              />
            </Grid>
            <Grid item {...responsiveIcons}>
              <ZeptoFeature
                title="Comprovantes"
                icon={
                  <ReceiptIcon
                    className={classnames(
                      classes.featureIcon,
                      classes.softYellow
                    )}
                  />
                }
              />
            </Grid>

            <Grid item {...responsiveIcons}>
              <ZeptoFeature
                title="Relatórios"
                icon={
                  <DescriptionIcon
                    className={classnames(classes.featureIcon, classes.grey)}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
});

const Panel1 = memo(() => {
  const classes = useStyles();

  return (
    <div className={classes.grayBg}>
      <div className={classes.panel}>
        <Grid container spacing={10} justify="center" direction="row">
          <Grid item xs={12} md={5}>
            <ZeptoCard />
          </Grid>

          <Grid item xs={12} md={7}>
            <img src={MainImg} alt="" className={classes.mainImg} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
});

const ZeptoPlan = memo(props => {
  const { title, price, maxUsers, id } = props;

  return (
    <ZeptoPaper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            Plano {title}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            R$ {price}
          </Typography>
          <Typography variant="subtitle1" align="center">
            por mês
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <List component="nav">
            <ZeptoFeatuleItem title={`Até ${maxUsers} usuários`} />
            <ZeptoFeatuleItem title="Acesso online" />
            <ZeptoFeatuleItem title="Reconhecimento Facial" />
            <ZeptoFeatuleItem title="Geolocalização" />
            <ZeptoFeatuleItem title="Comprovantes de ponto" />
            <ZeptoFeatuleItem title="Relatórios legais(Portaria 373 do Min. do Trabalho)" />
          </List>
        </Grid>

        <Grid item xs={12}>
          <a
            href={`/signup?selectedPlan=${id}`}
            style={{ textDecoration: 'none' }}
          >
            <ZeptoButton label="Registrar" />
          </a>
        </Grid>
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="button"
            display="block"
            gutterBottom
          >
            30 dias de teste grátis
          </Typography>
        </Grid>
      </Grid>
    </ZeptoPaper>
  );
});

const Panel2 = memo(() => {
  const classes = useStyles();

  const responsive = {
    sm: 7,
    md: 5,
    lg: 3,
  };

  return (
    <div className={classes.whiteBg}>
      <div className={classes.panel}>
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography align="center" variant="h4" gutterBottom>
              Conheça nossos planos:
            </Typography>
          </Grid>
          <Grid item xs={12} />

          <Grid item {...responsive}>
            <ZeptoPlan title="Básico" price="44,90" maxUsers={5} id="1" />
          </Grid>

          <Grid item {...responsive}>
            <ZeptoPlan
              title="Intermediário"
              price="64,90"
              maxUsers={10}
              id="2"
            />
          </Grid>

          <Grid item {...responsive}>
            <ZeptoPlan title="Enterprise" price="92,90" maxUsers={20} id="3" />
          </Grid>

          <Grid item {...responsive}>
            <ZeptoPlan title="Enterprise+" price="174,90" maxUsers={50} id="4" />
          </Grid>
        </Grid>
      </div>
    </div>
  );
});

const Panel3 = memo(
  withWidth()(props => {
    const classes = useStyles();

    const { width } = props;
    const responsive = {
      // xs: 5,
    };

    if (isWidthDown('sm', width)) responsive.xs = 12;

    return (
      <div className={classes.grayBg}>
        <div className={classes.panelSm}>
          <Grid
            container
            spacing={4}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={logoSml} alt="" className={classes.smallLogo} />
                <Typography component="span">Zepto</Typography>
              </div>
            </Grid>

            <Grid item xs={12} md={8}>
              <Grid
                container
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item {...responsive}>
                  <Link
                    href="http://caesser.com.br/"
                    target="_blank"
                    variant="body2"
                    align="center"
                  >
                    <Typography align="center">Sobre nós</Typography>
                  </Link>
                </Grid>
                <Grid item {...responsive}>
                  <Link
                    href="http://caesser.com.br/contate-nos"
                    target="_blank"
                    variant="body2"
                    align="center"
                  >
                    <Typography align="center">Entre em contato</Typography>
                  </Link>
                </Grid>
                <Grid item {...responsive}>
                  <Link
                    href="http://caesser.com.br/politica-de-privacidade"
                    target="_blank"
                    variant="body2"
                    align="center"
                  >
                    <Typography align="center">
                      Política de Privacidade
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Link
                href="https://www.instagram.com/zepto.app/"
                target="_blank"
                variant="body2"
                align="center"
                style={{ color: red[300] }}
              >
                <InstagramIcon />
              </Link>
              {'   '}
              <Link
                href="https://api.whatsapp.com/send?1=pt_BR&phone=5562984946101"
                target="_blank"
                variant="body2"
                align="center"
                style={{ color: green[500] }}
              >
                <WhatsAppIcon />
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  })
);

const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
});

const Website = memo(() => {
  const classes = useStyles();
  // const appBarColor = theme.palette.type === 'dark' ? 'default' : 'primary';
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <AppBar className={classes.appBar} color="default">
          <Toolbar>
            <a href="/">
              <Avatar alt="logo" src={logo} className={classes.logo} />
            </a>
            <div className={classes.title} />
            <a href="/login" style={{ textDecoration: 'none' }}>
              <ZeptoButton label="Login" color="default" />
            </a>
          </Toolbar>
        </AppBar>

        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Grid container spacing={0} style={{ overflowY: 'hidden' }}>
            <Grid item xs={12}>
              <Panel1 />
            </Grid>

            <Grid item xs={12}>
              <Panel2 />
            </Grid>

            <Grid item xs={12}>
              <Panel3 />
            </Grid>
          </Grid>
        </main>
      </div>
    </ThemeProvider>
  );
});

Website.propTypes = {};

ZeptoPlan.propTypes = {
  title: PropTypes.string,
  price: PropTypes.string,
  maxUsers: PropTypes.number,
  id: PropTypes.string,
};

ZeptoFeature.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
};

ZeptoFeatuleItem.propTypes = {
  title: PropTypes.string,
};

export default Website;
