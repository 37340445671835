import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem, Divider } from '@material-ui/core';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UserAvatar from './UserAvatar';
import { logout } from '../../operators/authOperator';
import { editUser } from '../../operators/usersOperator';

const UserAccountMenu = memo(props => {
  const { authenticatedUser, logout, editUser } = props;
  const [anchorEl, setAnchorEl] = useState(undefined);
  const open = Boolean(anchorEl);

  function onMenuClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function closeMenu() {
    setAnchorEl(undefined);
  }

  function onEditProfile() {
    closeMenu();

    editUser(authenticatedUser.id);
  }

  function onPrivacyPolicyClick() {
    closeMenu();

    window.open('https://caesser.com.br/politica-de-privacidade', '_blank');
  }

  function onLogoutClick() {
    closeMenu();
    logout();
  }

  return (
    <>
      <IconButton
        aria-owns={open ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        onClick={onMenuClick}
        color="inherit"
      >
        <UserAvatar user={authenticatedUser} size={100} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={closeMenu}
      >
        <MenuItem onClick={onEditProfile} dense>
          Editar Perfil
        </MenuItem>
        <Divider />

        <MenuItem onClick={onPrivacyPolicyClick} dense>
          Política de Privacidade
        </MenuItem>
        <MenuItem onClick={onLogoutClick} dense>
          Sair
        </MenuItem>
      </Menu>
    </>
  );
});

UserAccountMenu.propTypes = {
  logout: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  authenticatedUser: PropTypes.shape({
    id: PropTypes.number,
  }),
};

const mapStateToProps = store => ({
  authenticatedUser: store.auth.authenticatedUser,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout,
      editUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserAccountMenu);
