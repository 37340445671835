import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

const ZeptoTextField = props => {
  const {
    name,
    autoFocus,
    type,
    label,
    required,
    value,
    onChange,
    inputProps,
    errorMessage,
    disabled,
    inputComponent,
    onUpdate,
  } = props;

  function onValueChange(event) {
    if (!onUpdate) onChange(event);
    else onUpdate({ name, payload: event.target.value });
  }

  return (
    <TextField
      error={!!errorMessage}
      label={label}
      helperText={errorMessage}
      name={name}
      value={value || ''}
      onChange={onValueChange}
      required={required}
      autoFocus={autoFocus}
      type={type}
      inputProps={inputProps}
      disabled={disabled}
      inputComponent={inputComponent}
      fullWidth
      variant="outlined"
    />
  );
};

ZeptoTextField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  type: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  inputProps: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

export default memo(ZeptoTextField);
