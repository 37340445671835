import React, { useEffect, useState, useContext } from 'react';
import { Grid, LinearProgress, MenuItem } from '@material-ui/core';
import moment from 'moment';
import { observer } from 'mobx-react';
import { ZeptoSelect, ZeptoButton } from '../../../components';
// import { loadWorkSchedules } from '../../../operators/workSchedulesOperator';
import useValidator from '../../../hooks/useValidator';
import { dateFormat } from '../../../utils/Constants';
import { debounce } from 'lodash';
import { KeyboardDatePicker } from '@material-ui/pickers';

import CompanyJourneyStore from '../../../stores/CompanyJourneyStore';
import UserJourneyStore from '../../../stores/UserJourneyStore';

const WEEKLY_RULES = {
  companyJourneyId: 'required',
  startDate: 'required',
};

const CUSTOM_RULES = {
  ...WEEKLY_RULES,
  initialWorkDayIndex: 'required|numeric',
};

const dayTypeLabels = {
  WORK_DAY: 'Dia de trabalho',
  DAY_OFF: 'Folga',
};

const UserJourneyForm = props => {
  const { user } = props;

  const [data, setData] = useState({ startDate: new Date() });

  const {
    loadCompanyJourneys,
    clearCompanyJourneys,
    companyJourneys,
    loadingCompanyJourneys,
  } = useContext(CompanyJourneyStore);

  const { saveUserJourney, savingUserJourney } = useContext(UserJourneyStore);

  const rules =
    companyJourneys &&
      data.companyJourneyId &&
      isNotWeekly(data.companyJourneyId)
      ? CUSTOM_RULES
      : WEEKLY_RULES;
  const [isValid, getError] = useValidator(data, rules);

  function handleChange(event) {
    setData({ ...data, [event.target.name]: event.target.value });
  }

  const handleDateChange = name => newDate => {
    setData({ ...data, [name]: newDate.toDate() });
  };

  useEffect(() => {
    loadCompanyJourneys(user.companyId);

    return clearCompanyJourneys;
  }, [user]);

  function onSave() {
    if (!isValid()) return;

    saveUserJourney({
      ...data,
      userId: user.id,
      startDate: moment(data.startDate).format(dateFormat),
    });
  }

  function getCompJourneyById(companyJourneyId) {
    return companyJourneys.find(cj => cj.id === companyJourneyId);
  }

  function isNotWeekly(companyJourneyId) {
    return getCompJourneyById(companyJourneyId).journey.type !== 'WEEKLY';
  }

  return (
    <>
      {loadingCompanyJourneys && <LinearProgress />}
      {!loadingCompanyJourneys && companyJourneys && (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            {savingUserJourney && <LinearProgress />}
          </Grid>
          <Grid item xs={3}>
            <ZeptoSelect
              name="companyJourneyId"
              value={data.companyJourneyId}
              label="Jornada"
              required
              onChange={handleChange}
              errorMessage={getError('companyJourneyId')}
            >
              {companyJourneys.map(companyJourney => (
                <MenuItem key={companyJourney.id} value={companyJourney.id}>
                  {companyJourney.journey.name}
                </MenuItem>
              ))}
            </ZeptoSelect>
          </Grid>
          <Grid item xs={3} md={4} style={{marginTop: '8px'}}>
            <KeyboardDatePicker
              format="DD/MM/YYYY"
              label="Início"
              autoOk
              fullWidth
              value={moment(data.startDate, 'DD/MM/YYYY')}
              onChange={debounce(handleDateChange('startDate'), 2000)}
            />
          </Grid>

          {data.companyJourneyId && isNotWeekly(data.companyJourneyId) && (
            <Grid item xs={3} md={4}>
              <ZeptoSelect
                name="initialWorkDayIndex"
                value={data.initialWorkDayIndex}
                label="Dia de início"
                required
                onChange={handleChange}
                errorMessage={getError('initialWorkDayIndex')}
              >
                {getCompJourneyById(data.companyJourneyId).journey.days.map(
                  day => (
                    <MenuItem key={day.index} value={String(day.index)}>
                      Dia {day.index + 1} - {dayTypeLabels[day.type]}
                    </MenuItem>
                  )
                )}
              </ZeptoSelect>
            </Grid>
          )}

          <Grid item xs={12} md={3}>
            <ZeptoButton
              onClick={onSave}
              label="Adicionar"
              loading={savingUserJourney}
            />
          </Grid>
          <Grid item xs={12}>
            {savingUserJourney && <LinearProgress />}
          </Grid>
        </Grid>
      )}
    </>
  );
};

UserJourneyForm.propTypes = {
  // userId: PropTypes.number.isRequired,
  // companyId: PropTypes.number.isRequired,
  // loadWorkSchedules: PropTypes.func.isRequired,
  // createUserWorkSchedule: PropTypes.func.isRequired,
  // processingLoadWorkSchedules: PropTypes.bool,
  // workSchedules: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  // savingWorkSchedule: PropTypes.bool,
};

export default observer(UserJourneyForm);
