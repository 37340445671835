import React, { memo } from 'react';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator=":"
      decimalScale={2}
      fixedDecimalScale
      isNumericString
      allowNegative={false}
    />
  );
}

function ZeptoHoursPicker(props) {
  const { name, value, onUpdate, label, errorMessage, disabled } = props;

  function onValueChange(event) {
    onUpdate({ name, payload: event.target.value.replace('.', ':') });
  }

  const val = (value || '').replace(':', '.');

  return (
    <TextField
      fullWidth
      variant="outlined"
      label={label}
      value={val}
      disabled={disabled}
      onChange={onValueChange}
      error={!!errorMessage}
      helperText={errorMessage}
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
    />
  );
}

NumberFormatCustom.propTypes = {
  onChange: PropTypes.func,
  inputRef: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string
}

ZeptoHoursPicker.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onUpdate: PropTypes.func,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool
}

export default memo(ZeptoHoursPicker);