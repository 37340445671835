import moment from 'moment';
import { minutesToTime, timeToMinutes } from './DateUtils';
import { timeFormat } from './Constants';

export const journeyPeriodToForm = period => {
  if (!period) return null;

  return {
    ...period,
    start: moment(period.start || '00:00', timeFormat).toDate(),
    end: moment(period.end || '00:00', timeFormat).toDate(),
  };
};

export const journeyDayToForm = journeyDay => {
  if (!journeyDay) return null;

  return {
    ...journeyDay,
    periods: journeyDay.periods.map(p => journeyPeriodToForm(p)),
  };
};

export const journeyToForm = entity => {
  if (!entity) return {};

  return {
    ...entity,
    journey: {
      ...entity.journey,
      shiftTime: moment(
        minutesToTime(entity.journey.shiftTime),
        timeFormat
      ).toDate(),
      days: entity.journey.days.map(d => journeyDayToForm(d)),
    },
  };
};

export const journeyPeriodToJson = period => {
  if (!period) return null;

  return {
    ...period,
    start: moment(period.start).format(timeFormat),
    end: moment(period.end).format(timeFormat),
  };
};

export const journeyDayToJson = journeyDay => {
  if (!journeyDay) return null;

  return {
    ...journeyDay,
    periods: journeyDay.periods.map(p => journeyPeriodToJson(p)),
  };
};

export const journeyToJson = journey => {
  if (!journey) return {};

  return {
    ...journey,
    journey: {
      ...journey.journey,
      shiftTime: timeToMinutes(
        moment(journey.journey.shiftTime).format(timeFormat)
      ),
      days: journey.journey.days.map(d => journeyDayToJson(d)),
    },
  };
};

const dayNames = {
  0: 'Domingo',
  1: 'Segunda-feira',
  2: 'Terça-feira',
  3: 'Quarta-feira',
  4: 'Quinta-feira',
  5: 'Sexta-feira',
  6: 'Sábado',
};

export const getDayLabel = (dayIndex, journeyType) => {
  if (journeyType === 'WEEKLY') return dayNames[dayIndex];

  return `Dia  ${dayIndex + 1}`;
};