import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import {
  Menu,
  makeStyles,
  MenuItem,
  Fab,
  CircularProgress,
} from '@material-ui/core';

const ITEM_HEIGHT = 48;
const useStyles = makeStyles(theme => ({
  paper: {
    maxHeight: ITEM_HEIGHT * 4.5,
    width: 200,
  },
  fab: {
    margin: theme.spacing(0),
    minWidth: 40,
  },
}));
const UserMenu = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const {
    user,
    onEdit,
    onDisable,
    onEnable,
    onCreateAllowance,
    loading,
  } = props;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const close = () => {
    setAnchorEl(null);
  };

  const onEditClick = () => {
    onEdit();
    close();
  };

  const onDisableClick = () => {
    onDisable();
    close();
  };
  const onEnableClick = () => {
    onEnable();
    close();
  };
  const onAllowanceClick = () => {
    onCreateAllowance();
    close();
  };

  return (
    <>
      <Fab
        className={classes.fab}
        aria-haspopup="true"
        onClick={handleClick}
        color="primary"
        size="small"
        disabled={loading}
      >
        {!loading && <MoreVertIcon />}
        {loading && <CircularProgress />}
      </Fab>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={close}
        PaperProps={{
          className: classes.paper,
        }}
      >
        {user.enabled && user.role === 'USER' && (
          <MenuItem onClick={onAllowanceClick}>Abonar</MenuItem>
        )}
        {!user.enabled && (
          <MenuItem onClick={onEnableClick}>Habilitar</MenuItem>
        )}
        {user.enabled && <MenuItem onClick={onEditClick}>Editar</MenuItem>}
        {user.enabled && (
          <MenuItem onClick={onDisableClick}>Desabilitar</MenuItem>
        )}
      </Menu>
    </>
  );
};

UserMenu.propTypes = {
  user: PropTypes.shape({
    enabled: PropTypes.bool,
    role: PropTypes.string.isRequired,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
  onEnable: PropTypes.func.isRequired,
  onCreateAllowance: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default memo(UserMenu);
