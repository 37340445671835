import { createActions, createReducer } from 'reduxsauce';

/* #region Action types & creators */
export const { Types, Creators } = createActions({
  registerPointRequest: [null],
  registerPointSuccess: ['point'],
  registerPointFailure: [null],

  loadPointPhotoRequest: [null],
  loadPointPhotoSuccess: ['photo'],
  loadPointPhotoFailure: [null],

  loadPointReceiptRequest: [null],
  loadPointReceiptSuccess: ['receipt'],
  loadPointReceiptFailure: [null],

  clearLastPoint: [null],
  clearLastError: [null],
});
/* #endregion */

/* #region Initial State */
const INITIAL_STATE = {};
/* #endregion */

/* #region Register point */

const registerPointRequest = (state = INITIAL_STATE) => ({
  ...state,
  processingRegisterPoint: true,
  registerPointErrorMessage: '',
});

const registerPointSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  processingRegisterPoint: false,
  registerPointErrorMessage: '',
  lastPoint: action.point,
});

const registerPointFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  processingRegisterPoint: false,
  registerPointErrorMessage: action.error,
  lastError: new Date(),
});

/* #endregion */

/* #region Load point photo */

const loadPointPhotoRequest = (state = INITIAL_STATE) => ({
  ...state,
  processingLoadPointPhoto: true,
  pointPhoto: undefined,
});

const loadPointPhotoSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  processingLoadPointPhoto: false,
  pointPhoto: action.photo,
});

const loadPointPhotoFailure = (state = INITIAL_STATE) => ({
  ...state,
  processingLoadPointPhoto: false,
});

/* #endregion */

/* #region Load point receipt */

const loadPointReceiptRequest = (state = INITIAL_STATE) => ({
  ...state,
  processingLoadPointReceipt: true,
  pointReceipt: null,
});

const loadPointReceiptSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  processingLoadPointReceipt: false,
  pointReceipt: action.receipt,
});

const loadPointReceiptFailure = (state = INITIAL_STATE) => ({
  ...state,
  processingLoadPointReceipt: false,
});

/* #endregion */

const clearLastPoint = (state = INITIAL_STATE) => ({
  ...state,
  lastPoint: null,
});

const clearLastError = (state = INITIAL_STATE) => ({
  ...state,
  lastError: null,
});

/* #endregion */

export default createReducer(INITIAL_STATE, {
  [Types.REGISTER_POINT_REQUEST]: registerPointRequest,
  [Types.REGISTER_POINT_SUCCESS]: registerPointSuccess,
  [Types.REGISTER_POINT_FAILURE]: registerPointFailure,

  [Types.LOAD_POINT_PHOTO_REQUEST]: loadPointPhotoRequest,
  [Types.LOAD_POINT_PHOTO_SUCCESS]: loadPointPhotoSuccess,
  [Types.LOAD_POINT_PHOTO_FAILURE]: loadPointPhotoFailure,

  [Types.LOAD_POINT_RECEIPT_REQUEST]: loadPointReceiptRequest,
  [Types.LOAD_POINT_RECEIPT_SUCCESS]: loadPointReceiptSuccess,
  [Types.LOAD_POINT_RECEIPT_FAILURE]: loadPointReceiptFailure,

  [Types.CLEAR_LAST_POINT]: clearLastPoint,
  [Types.CLEAR_LAST_ERROR]: clearLastError,
});
