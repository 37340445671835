import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@material-ui/core';

function ZeptoCheckbox(props) {
  const { checked, label, onChange, disabled, name } = props;

  return (
    <FormControlLabel
      name={name}
      control={<Checkbox checked={checked || false} onChange={onChange} />}
      label={label}
      disabled={disabled}
    />
  );
}

ZeptoCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  checked: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default memo(ZeptoCheckbox);
