import { push } from 'react-router-redux';

import { Creators as CompanyActions } from '../ducks/companies';

import { jsonFetch, jsonPost, jsonPut, jsonDelete } from '../utils/http';
import { Creators as AppActions } from '../ducks/app';
import { Creators as RequestActions } from '../ducks/requests';
import { companyStore } from '../stores/CompanyStore';

const apiPath = `${process.env.REACT_APP_API_PATH}/api/companies`;

export function loadCompanies() {
  return dispatch => {
    dispatch(CompanyActions.loadCompaniesRequest());

    return jsonFetch(apiPath)
      .then(json => {
        dispatch(CompanyActions.loadCompaniesSuccess(json));
      })
      .catch(error => {
        dispatch(CompanyActions.loadCompaniesFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao carregar as empresas: ${error.message}`
          )
        );
      });
  };
}

export function loadCompany(id) {
  return dispatch => {
    dispatch(CompanyActions.loadCompanyRequest());

    return jsonFetch(`${apiPath}/${id}`)
      .then(json => {
        companyStore.setCompany(json);
        dispatch(CompanyActions.loadCompanySuccess(json));
      })
      .catch(error => {
        dispatch(CompanyActions.loadCompanyFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao carregar a empresa: ${error.message}`
          )
        );
      });
  };
}

export function createCompany(company) {
  return dispatch => {
    dispatch(CompanyActions.createCompanyRequest());

    return jsonPost(`${apiPath}`, company)
      .then(json => {
        dispatch(CompanyActions.createCompanySuccess(json));
        dispatch(push(`/companies/${json.id}`));
        dispatch(AppActions.showSuccess('Empresa criada com sucesso.'));
      })
      .catch(error => {
        dispatch(CompanyActions.createCompanyFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao criar a empresa: ${error.message}`
          )
        );
      });
  };
}

export function updateCompany(company) {
  return dispatch => {
    dispatch(CompanyActions.updateCompanyRequest());

    return jsonPut(`${apiPath}/${company.id}`, company)
      .then(json => {
        dispatch(CompanyActions.updateCompanySuccess(json));
        dispatch(push(`/companies/${json.id}`));
        dispatch(AppActions.showSuccess('Empresa atualizada com sucesso.'));
      })
      .catch(error => {
        dispatch(CompanyActions.updateCompanyFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao criar atualizar a empresa: ${error.message}`
          )
        );
      });
  };
}

export function disableCompany(id) {
  return dispatch => {
    dispatch(CompanyActions.disableCompanyRequest());

    return jsonDelete(`${apiPath}/${id}`)
      .then(json => {
        dispatch(CompanyActions.disableCompanySuccess(json));
        dispatch(push('/companies'));
        dispatch(AppActions.showSuccess('Empresa desabilitada com sucesso.'));
      })
      .catch(error => {
        dispatch(CompanyActions.disableCompanyFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao desabilitar a empresa: ${error.message}`
          )
        );
      });
  };
}

export function editCompany(id) {
  return dispatch => {
    dispatch(CompanyActions.loadCompanyRequest());
    return jsonFetch(`${apiPath}/${id}`)
      .then(json => {
        dispatch(CompanyActions.loadCompanySuccess(json));
        dispatch(push(`/companies/${id}/edit`));
      })
      .catch(error => {
        dispatch(CompanyActions.loadCompanyFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao carregar a empresa: ${error.message}`
          )
        );
      });
  };
}

export function loadCompanyRequests(company) {
  return dispatch => {
    dispatch(RequestActions.loadRequestsRequest());

    return jsonFetch(`${apiPath}/${company.id}/requests`)
      .then(json => {
        dispatch(RequestActions.loadRequestsSuccess(json));
      })
      .catch(error => {
        dispatch(RequestActions.loadRequestsFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao carregar as empresas: ${error.message}`
          )
        );
      });
  };
}
