import { createContext } from 'react';
import { decorate, observable } from 'mobx';
import { jsonFetch, jsonPost, jsonPut, jsonDelete } from '../utils/http';
import { appStore } from './AppStore';

const apiPath = companyId =>
  `${process.env.REACT_APP_API_PATH}/api/companies/${companyId}/holidays`;

class HolidayStore {
  holidays = [];

  lastSaved;

  loadingHolidays;

  creatingHoliday;

  updatingHoliday;

  disablingHoliday;

  loadHolidays = async (companyId, year) => {
    this.loadingHolidays = true;
    try {
      this.holidays = await jsonFetch(`${apiPath(companyId)}/?year=${year}`);
    } catch (e) {
      appStore.showError(
        `Ocorreu um erro ao carregar os feriados: ${e.message}`
      );
    }
    this.loadingHolidays = false;
  };

  create = async holiday => {
    this.creatingHoliday = true;

    try {
      this.lastSaved = await jsonPost(`${apiPath(holiday.companyId)}`, holiday);

      if (this.holidays) {
        this.holidays.push(this.lastSaved);
      }

      appStore.showSuccess('Feriado cadastrado com sucesso.');

      setTimeout(() => {
        this.lastSaved = null;
      }, 1000);
    } catch (e) {
      appStore.showError(`Ocorreu um erro ao criar o feriado: ${e.message}`);
    }

    this.creatingHoliday = false;
  };

  update = async holiday => {
    this.updatingHoliday = true;

    try {
      this.lastSaved = await jsonPut(
        `${apiPath(holiday.companyId)}/${holiday.id}`,
        holiday
      );

      appStore.showSuccess('Feriado atualizado com sucesso.');

      setTimeout(() => {
        this.lastSaved = null;
      }, 1000);

      if (this.holidays) {
        const index = this.holidays.findIndex(h => h.id === this.lastSaved.id);

        if (index !== -1) {
          this.holidays.splice(index, 1, this.lastSaved);
        }
      }
    } catch (e) {
      appStore.showError(
        `Ocorreu um erro ao atualizar o feriado: ${e.message}`
      );
    }

    this.updatingHoliday = false;
  };

  disable = async holiday => {
    this.disablingHoliday = true;

    try {
      await jsonDelete(`${apiPath(holiday.companyId)}/${holiday.id}`);

      if (this.holidays) {
        const index = this.holidays.findIndex(h => h.id === holiday.id);

        if (index !== -1) {
          this.holidays.splice(index, 1);
        }

        appStore.showSuccess('Feriado apagado com sucesso');
      }
    } catch (e) {
      appStore.showError(`Ocorreu um erro ao criar o apagar: ${e.message}`);
    }

    this.disablingHoliday = false;
  };
}

decorate(HolidayStore, {
  holidays: observable,
  lastSaved: observable,
  loadingHolidays: observable,
  creatingHoliday: observable,
  updatingHoliday: observable,
  disablingHoliday: observable,
});

export const holidayStore = new HolidayStore();

export default createContext(holidayStore);
