import React, { Fragment, useState } from 'react';
import { TableCell, Fab, Grid, DialogContent } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import moment from 'moment';
import { observer } from 'mobx-react';
import CloseIcon from '@material-ui/icons/Close';
import { ZeptoTable, ZeptoDialog } from '../../../components';

import JourneyInput from '../../companies/journeys/form/JourneyInput';
import { toDate } from '../../../utils/DateUtils';
import { dateFormat } from '../../../utils/Constants';
import { journeyToForm, getDayLabel } from '../../../utils/JourneyUtils';

const UserJourneysList = props => {
  const { journeys, onDelete, readOnly, renderSubTitle, loading } = props;

  const [selected, setSelected] = useState(null);

  function onDeleteJourney(journey) {
    return () => onDelete(journey);
  }

  function onViewJourney(ws) {
    return () => setSelected(journeyToForm(ws));
  }

  const sort = (a, b) =>
    moment(a.startDate, dateFormat).diff(moment(b.startDate, dateFormat));
  const sorted = journeys.sort(sort);

  function renderCells(userJourney) {
    return (
      <Fragment key={userJourney.id}>
        <TableCell>{userJourney.journey.name}</TableCell>
        <TableCell>{toDate(userJourney.startDate)}</TableCell>
        <TableCell>
          {getDayLabel(
            userJourney.initialWorkDayIndex,
            userJourney.journey.type
          )}
        </TableCell>
        <TableCell align="right">
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <Fab
                onClick={onViewJourney(userJourney)}
                size="small"
                color="primary"
              >
                <SearchIcon />
              </Fab>
            </Grid>
            {!readOnly && (
              <Grid item>
                <Fab
                  onClick={onDeleteJourney(userJourney)}
                  size="small"
                  color="secondary"
                >
                  <DeleteIcon />
                </Fab>
              </Grid>
            )}
          </Grid>
        </TableCell>

        <ZeptoDialog
          open={!!selected}
          title="Jornada"
          onClose={() => setSelected(null)}
          fullScreen
          renderAction={() => (
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Grid item>
                <Fab
                  onClick={() => setSelected(null)}
                  size="small"
                  color="secondary"
                >
                  <CloseIcon />
                </Fab>
              </Grid>
            </Grid>
          )}
        >
          <DialogContent>
            {selected && <JourneyInput value={selected.journey} readOnly />}
          </DialogContent>
        </ZeptoDialog>
      </Fragment>
    );
  }

  return (
    <ZeptoTable
      title="Escalas do funcionário"
      headRows={[
        {
          id: 'journey',
          label: 'Jornada',
        },
        {
          id: 'start',
          label: 'Início',
        },
        {
          id: 'startDay',
          label: 'Dia de início',
        },
        {
          id: 'actions',
          label: '',
        },
      ]}
      rows={sorted}
      renderCells={renderCells}
      renderSubTitle={renderSubTitle}
      loading={loading}
    />
  );
};

UserJourneysList.propTypes = {
  journeys: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  onDelete: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  renderSubTitle: PropTypes.func,
};

export default observer(UserJourneysList);
