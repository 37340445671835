import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { FormControl } from '@material-ui/core';

function ZeptoTimePicker(props) {
  const {
    label,
    name,
    onChange,
    onUpdate,
    value,
    errorMessage,
    disabled,
    error,
  } = props;

  function onValueChange(newTime) {
    if (!onUpdate) onChange(newTime);
    else onUpdate({ name, payload: newTime });
  }

  return (
    <FormControl margin="normal" fullWidth>
      <KeyboardTimePicker
        autoOk
        fullWidth
        ampm={false}
        label={label}
        value={value}
        onChange={onValueChange}
        helperText={errorMessage}
        error={!!errorMessage || error}
        disabled={disabled}
      />
    </FormControl>
  );
}

ZeptoTimePicker.propTypes = {
  label: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  value: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};

export default memo(ZeptoTimePicker);
