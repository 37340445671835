import React, { useEffect } from 'react';

import Validator from 'validatorjs';
import moment from 'moment';
import messages from '../utils/ValidatorMessages';
import { dateFormat } from '../utils/Constants';

const useValidator = (data, rules, customNames = {}) => {
  /* Essa propriedade serve para sinalizar que a validação só começa a ser feita
   * quando o usuário clicar em Salvar.
   */
  const [deveValidar, setDeveValidar] = React.useState(false);
  const [errors, setErrors] = React.useState(false);

  useEffect(() => {
    if (deveValidar) {
      const validator = new Validator(data, rules, messages);
      validator.setAttributeNames(customNames);
      validator.passes();
      setErrors(validator.errors);
    }
  }, [data, rules]);

  const isValid = () => {
    setDeveValidar(true);
    const validator = new Validator(data, rules, messages);
    validator.setAttributeNames(customNames);
    setErrors(validator.errors);

    return validator.passes();
  };

  const getError = prop => {
    if (!deveValidar || !errors) return null;
    return errors.get(prop).length > 0 ? errors.get(prop)[0] : null;
  };

  return [isValid, getError];
};

Validator.register(
  'validDate',
  value => {
    const date = moment(value, dateFormat);
    return date.isValid();
  },
  'Data inválida.'
);

Validator.register(
  'phone',
  value => {
    if (!value) return true;
    return value.split('_').join('').length === 14;
  },
  'Celular inválido.'
);

Validator.register(
  'time',
  value => {
    const thisValue = moment(value, 'HH:mm');
    if (!thisValue.isValid()) {
      return false;
    }

    return true;
  },
  'Valor inválido.'
);

Validator.register(
  'timeBiggerThanZero',
  value => {
    const thisValue = moment(value, 'kk:mm');
    if (!thisValue.isValid()) {
      return false;
    }

    if (thisValue.hours() === 0 && thisValue.minute() === 0) {
      return false;
    }

    return true;
  },
  'Valor inválido.'
);

Validator.register(
  'hours',
  value => {
    if (!value) return true;

    const numberValue = Number(value.replace(':', ''));

    if (Number.isNaN(numberValue)) return false;

    if (numberValue === 0) return false;

    return true;
  },
  'Valor inválido.'
);

Validator.register(
  'cpf',
  cpfText => {
    const cleanCPF = String(cpfText).replace(/\.|-|\s/g, '');
    const firstNineDigits = cleanCPF.substring(0, 9);
    const checker = cleanCPF.substring(9, 11);

    const calcFirstChecker = firstNineDigits => {
      let sum = null;

      for (let j = 0; j < 9; ++j) {
        sum += Number(firstNineDigits.toString().charAt(j)) * (10 - j);
      }

      const lastSumChecker1 = sum % 11;
      const checker1 = lastSumChecker1 < 2 ? 0 : 11 - lastSumChecker1;

      return checker1;
    };

    const calcSecondChecker = cpfWithChecker1 => {
      let sum = null;

      for (let k = 0; k < 10; ++k) {
        sum += Number(cpfWithChecker1.toString().charAt(k)) * (11 - k);
      }

      const lastSumChecker2 = sum % 11;
      const checker2 = lastSumChecker2 < 2 ? 0 : 11 - lastSumChecker2;

      return checker2;
    };

    if (cleanCPF.length !== 11) {
      return false;
    }

    // Checking if all digits are equal
    for (let i = 0; i < 10; i++) {
      if (`${firstNineDigits}${checker}` === Array(12).join(String(i))) {
        return false;
      }
    }

    const checker1 = calcFirstChecker(firstNineDigits);
    const checker2 = calcSecondChecker(`${firstNineDigits}${checker1}`);

    return checker.toString() === checker1.toString() + checker2.toString();
  },
  'CPF inválido.'
);

Validator.register(
  'cnpj',
  cnpjText => {
    if (!cnpjText) return true;
    const cnpj = cnpjText.replace(/[^\d]+/g, '');

    // Valida a quantidade de caracteres
    if (cnpj.length !== 14) return false;

    // Elimina inválidos com todos os caracteres iguais
    if (/^(\d)\1+$/.test(cnpj)) return false;

    // Cáculo de validação
    const t = cnpj.length - 2;
    const d = cnpj.substring(t);
    const d1 = parseInt(d.charAt(0), 10);
    const d2 = parseInt(d.charAt(1), 10);
    const calc = x => {
      const n = cnpj.substring(0, x);
      let y = x - 7;
      let s2 = 0;
      let r = 0;

      for (let i = x; i >= 1; i--) {
        s2 += n.charAt(x - i) * y--;
        if (y < 2) y = 9;
      }

      r = 11 - (s2 % 11);
      return r > 9 ? 0 : r;
    };

    return calc(t) === d1 && calc(t + 1) === d2;
  },
  'CNPJ inválido.'
);

export default useValidator;
