import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Formsy from 'formsy-react';
import { makeStyles, LinearProgress } from '@material-ui/core';
import {
  editCompany,
  createCompany,
  updateCompany,
} from '../../operators/companiesOperator';
import { ZeptoFormTextField, ZeptoFormMaskedTextField } from '../../components';

const useStyles = makeStyles({
  buttonArea: {
    paddingTop: 30,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const CompanyForm = props => {
  const [enableSubmit, setEnableSubmit] = useState();
  const [company, setCompany] = useState({});
  const classes = useStyles();

  const {
    editCompany,
    selected,
    params,
    processingLoadCompany,
    processingCreateCompany,
    processingUpdateCompany,
  } = props;

  const loading = () =>
    processingLoadCompany || processingCreateCompany || processingUpdateCompany;

  useEffect(() => {
    setCompany({ ...selected });
  }, [selected]);

  useEffect(() => {
    if (Number(params.id) > 0) {
      if (!selected) {
        editCompany(params.id);
      } else {
        setCompany({ ...selected });
      }
    } else {
      setCompany({});
    }
  }, []);

  const handleSubmit = data => {
    const { createCompany, updateCompany } = props;

    const comp = {
      ...data,
      id: company.id,
    };

    if (comp.id) {
      updateCompany(comp);
    } else {
      createCompany(comp);
    }
  };

  return (
    <Formsy
      onValidSubmit={handleSubmit}
      onValid={() => setEnableSubmit(true)}
      onInvalid={() => setEnableSubmit(false)}
    >
      <Typography variant="h6" gutterBottom>
        {'Formulário de Empresa'}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {loading() && <LinearProgress />}
        </Grid>
        <Grid item xs={12}>
          <ZeptoFormTextField
            name="businnesName"
            value={company.businnesName}
            label="Razão Social"
            required
            validations="minLength:1,maxLength:255"
            validationError="Razão Social inválido"
            inputProps={{
              maxLength: 255,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ZeptoFormTextField
            name="commercialName"
            value={company.commercialName}
            label="Nome Fantasia"
            required
            validations="minLength:1,maxLength:255"
            validationError="Nome Fantasia inválido"
            inputProps={{
              maxLength: 255,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ZeptoFormTextField
            name="street"
            value={company.street}
            label="Rua"
            validations="minLength:1,maxLength:255"
            validationError="Endereço inválido"
            inputProps={{
              maxLength: 255,
            }}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <ZeptoFormTextField
            name="address"
            value={company.address}
            label="Logradouro"
            validations="minLength:1,maxLength:255"
            validationError="Endereço inválido"
            inputProps={{
              maxLength: 255,
            }}
            required
          />
        </Grid>
        <Grid item xs={2}>
          <ZeptoFormTextField
            name="number"
            value={company.number}
            label="Número"
            ovalidations="minLength:1,maxLength:255"
            validationError="Endereço inválido"
            inputProps={{
              maxLength: 255,
            }}
            required
          />
        </Grid>
        <Grid item xs={2}>
          <ZeptoFormTextField
            name="city"
            value={company.city}
            label="Cidade"
            validations="minLength:1,maxLength:255"
            validationError="Endereço inválido"
            inputProps={{
              maxLength: 255,
            }}
            required
          />
        </Grid>
        <Grid item xs={2}>
          <ZeptoFormTextField
            name="state"
            value={company.state}
            label="Estado"
            validations="minLength:1,maxLength:255"
            validationError="Endereço inválido"
            inputProps={{
              maxLength: 255,
            }}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <ZeptoFormTextField
            name="complement"
            value={company.complement}
            label="Complemento"
            validations="minLength:1,maxLength:255"
            validationError="Endereço inválido"
            inputProps={{
              maxLength: 255,
            }}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <ZeptoFormMaskedTextField
            name="cep"
            value={company.cep}
            label="CEP"
            validations="minLength:1,maxLength:255"
            validationError="Endereço inválido"
            required
            inputProps={{
              mask: [
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
              ],
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <ZeptoFormMaskedTextField
            name="cnpj"
            value={company.cnpj}
            label="CNPJ"
            required
            validations="isExisty,isCnpj"
            validationError="CNPJ inválido"
            inputProps={{
              mask: [
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '/',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
              ],
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {loading() && <LinearProgress />}
        </Grid>
      </Grid>
      <div className={classes.buttonArea}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading() || !enableSubmit}
        >
          {'Salvar'}
        </Button>
      </div>
    </Formsy>
  );
};

CompanyForm.propTypes = {
  params: PropTypes.shape({}).isRequired,
  processingLoadCompany: PropTypes.bool,
  processingCreateCompany: PropTypes.bool,
  processingUpdateCompany: PropTypes.bool,
  editCompany: PropTypes.func.isRequired,
  createCompany: PropTypes.func.isRequired,
  updateCompany: PropTypes.func.isRequired,
  selected: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  processingLoadCompany: state.companies.processingLoadCompany,
  processingCreateCompany: state.companies.processingCreateCompany,
  processingUpdateCompany: state.companies.processingUpdateCompany,
  selected: state.companies.selected,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      editCompany,
      createCompany,
      updateCompany,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(CompanyForm));
