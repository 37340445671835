import { createContext } from 'react';
import { decorate, observable } from 'mobx';
import { jsonFetch, jsonPut } from '../utils/http';
import { appStore } from './AppStore';

const apiPath = companyId =>
  `${process.env.REACT_APP_API_PATH}/api/companies/${companyId}/settings`;

class CompanySettingsStore {
  settings;

  loadingSettings;

  updatingSettings;

  loadSettings = async companyId => {
    this.loadingSettings = true;
    try {
      this.settings = await jsonFetch(apiPath(companyId));
    } catch (e) {
      appStore.showError(
        `Ocorreu um erro ao carregar as configurações: ${e.message}`
      );
    }
    this.loadingSettings = false;
  };

  clearSettings = () => {
    this.settings = null;
  }

  updateSettings = async settings => {
    this.updatingSettings = true;

    try {
      const updated = await jsonPut(`${apiPath(settings.companyId)}`, settings);

      this.settings = updated;

      appStore.showSuccess(`Configurações salvas com sucesso.`);
    } catch (e) {
      appStore.showError(
        `Ocorreu um erro ao atualizar as configurações: ${e.message}`
      );
    }

    this.updatingSettings = false;
  };
}

decorate(CompanySettingsStore, {
  settings: observable,
  loadingSettings: observable,
  updatingSettings: observable,
});

export const companySettingsStore = new CompanySettingsStore();

export default createContext(companySettingsStore);
