import React, { useContext, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/styles';
import { AccountCircle } from '@material-ui/icons';
import { Avatar } from '@material-ui/core';
import UserStore from '../../stores/UserStore';

const useStyles = makeStyles({
  avatar: {
    fontSize: '30pt',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

const UserAvatar = observer(props => {
  const classes = useStyles();
  const { user, className, size, whenNoPhotoRender } = props;

  const { loadPhoto, loadingPhoto, getPhoto } = useContext(UserStore);

  const photo = getPhoto(size);

  useEffect(() => {
    loadPhoto(user, size);
  }, [user]);

  if (loadingPhoto[size]) return <AccountCircle className={classes.avatar} />;

  if (photo)
    return <Avatar src={URL.createObjectURL(photo)} className={className} />;

  return whenNoPhotoRender ? (
    whenNoPhotoRender
  ) : (
    <AccountCircle className={classes.avatar} />
  );
});

UserAvatar.propTypes = {
  user: PropTypes.shape({
    hasPhoto: PropTypes.bool,
  }).isRequired,
  whenNoPhotoRender: PropTypes.node,
};

UserAvatar.defaultProps = {
  size: 'DEFAULT',
};

function propsAreEqual(prevProps, nextProps) {
  return (
    prevProps.user === nextProps.user &&
      prevProps.user &&
      nextProps.user &&
      prevProps.user.id === nextProps.user.id,
    prevProps.user.hasPhoto === nextProps.user.hasPhoto,
    prevProps.user.lastPhotoUpload === nextProps.user.lastPhotoUpload
  );
}

export default memo(UserAvatar, propsAreEqual);
