import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import React from 'react';

const MapComponent = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={16}
      defaultCenter={{ lat: Number(props.lat), lng: Number(props.long) }}
      defaultOptions={{
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: false,
        fullscreenControl: false,
      }}
    >
      <Marker position={{ lat: Number(props.lat), lng: Number(props.long) }} />
    </GoogleMap>
  )),
);

const ZeptoMap = props => (
  <MapComponent
    {...props}
    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBHOri2pRdI5cwDTgC8n2tFWms90Ddxgyg&v=3.exp&libraries=geometry,drawing,places"
    loadingElement={<div style={{ height: '100%' }} />}
    containerElement={<div style={{ height: '300px' }} />}
    mapElement={<div style={{ height: '100%' }} />}
  />
);

export default ZeptoMap;
