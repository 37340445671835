import { push } from 'react-router-redux';
import { Creators as AuthActions } from '../ducks/auth';
import { jsonPut, jsonFetch } from '../utils/http';
import { Creators as AppActions } from '../ducks/app';

const apiPath = `${process.env.REACT_APP_API_PATH}/api/auth`;

export function loginUser(credentials) {
  return dispatch => {
    dispatch(AuthActions.loginRequest());

    return jsonPut(apiPath, credentials)
      .then(json => {
        localStorage.setItem('id_token', json.token);

        dispatch(AuthActions.loginSuccess(json.token));
        dispatch(push('/me'));
      })
      .catch(error => {
        dispatch(AuthActions.loginFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro durante a autenticação: ${error.message}`
          )
        );
      });
  };
}

export function logout() {
  return dispatch => {
    localStorage.removeItem('id_token');
    dispatch(AuthActions.logout());
    dispatch(push('/login'));
  };
}

export function loadAuthenticatedUser() {
  return dispatch => {
    dispatch(AuthActions.authenticatedRequest());

    return jsonFetch(apiPath)
      .then(json => {
        dispatch(AuthActions.authenticatedSuccess(json));
      })
      .catch(error => {
        localStorage.removeItem('id_token');
        dispatch(AuthActions.authenticatedFailure());
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao carregar o usuário: ${error.message}`
          )
        );
        dispatch(logout());
      });
  };
}

export function forgotPassword(email, reCaptcha) {
  return dispatch => {
    dispatch(AuthActions.forgotPasswordRequest());

    return jsonPut(`${apiPath}/forgot-password`, { email, reCaptcha })
      .then(() => {
        dispatch(AuthActions.forgotPasswordSuccess());
        dispatch(
          AppActions.showSuccess(
            `Foram enviadas instruções para redefinição da senha para o email informado.`
          )
        );
      })
      .catch(error => {
        dispatch(AuthActions.forgotPasswordFailure());
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao solicitar a restauração da senha: ${error.message}`
          )
        );
      });
  };
}

export function validateRedefinePasswordToken(token) {
  return dispatch => {
    dispatch(AuthActions.validateRedefinePasswordTokenRequest());

    return jsonFetch(`${apiPath}/redefine-password-tokens/${token}/isValid`)
      .then(json => {
        dispatch(AuthActions.validateRedefinePasswordTokenSuccess(json));
      })
      .catch(error => {
        dispatch(AuthActions.validateRedefinePasswordTokenFailure());
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao validar a restauração da senha: ${error.message}`
          )
        );
      });
  };
}

export function clearRedefinePasswordTokenValidation() {
  return dispatch => {
    dispatch(AuthActions.clearRedefinePasswordTokenValidation());
  };
}

export function clearLastForgotPasswordSuccess() {
  return dispatch => {
    dispatch(AuthActions.clearLastForgotPasswordSuccess());
  };
}

export function redefinePassword(token, reCaptcha, password) {
  return dispatch => {
    dispatch(AuthActions.redefinePasswordRequest());

    return jsonPut(`${apiPath}/redefine-password`, {
      token,
      reCaptcha,
      password,
    })
      .then(() => {
        dispatch(AuthActions.redefinePasswordSuccess());
        dispatch(AppActions.showSuccess(`Senha redefinida com sucesso.`));
        dispatch(push('/'));
      })
      .catch(error => {
        dispatch(AuthActions.redefinePasswordFailure());
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao redefinir a senha: ${error.message}`
          )
        );
      });
  };
}

export function onUserUploadPhoto() {
  return dispatch => {
    dispatch(AuthActions.onUserUploadPhoto());
  };
}
