import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ZeptoButton from './ZeptoButton';

const ZeptoConfirmDialog = props => {
  const {
    open,
    title,
    content,
    showCancel,
    cancelLabel,
    showConfirm,
    confirmLabel,
    onConfirm,
    onCancel,
    loading,
  } = props;
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    setIsOpen(!!open);
  }, [open]);

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
  };
  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {loading && <LinearProgress />}
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}

      <DialogContent>
        {content && (
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {showCancel && (
          <ZeptoButton
            onClick={handleCancel}
            color="secondary"
            label={cancelLabel}
            loading={loading}
          />
        )}
        {showConfirm && (
          <ZeptoButton
            onClick={handleConfirm}
            color="primary"
            label={confirmLabel}
            loading={loading}
            autoFocus
          />
        )}
      </DialogActions>
      {loading && <LinearProgress />}
    </Dialog>
  );
};

ZeptoConfirmDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  showCancel: PropTypes.bool,
  cancelLabel: PropTypes.string,
  showConfirm: PropTypes.bool,
  confirmLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
};

ZeptoConfirmDialog.defaultProps = {
  cancelLabel: 'Cancelar',
  confirmLabel: 'Confirmar',
  showCancel: true,
  showConfirm: true,
  title: 'Confirmação',
};

export default React.memo(ZeptoConfirmDialog);
