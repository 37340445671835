import React, { memo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const AgreementTerms = memo(props => {
  const { className } = props;
  return (
    <Grid container spacing={2} className={className}>
      <Grid item xs={12}>
        <Typography align="justify">
          <p>
            CONTRATADA: CAESSER TECNOLOGIA E INOVAÇÃO LTDA, estabelecida na Rua
            07, nº. 150, Quadra e, Lote 39, Sala 03, Centro, Ceres-GO, CEP:
            76.300-000 – CNPJ: 34.163.475/0001-10.
          </p>
          <p>
            CONTRATANTE: Aplica-se a todas as pessoas físicas e jurídicas que
            contratarem o software. Ao aceitar este contrato a CONTRATANTE está
            aderindo aos termos e condições aqui estipulados. Considera-se
            aceitação ao contrato: uso do software, uso dos serviços de
            suporte/atualização de versões oferecidos pela CONTRATADA e, ainda,
            pagamento de boleto bancário emitido pela CONTRATADA.
          </p>
          <p>
            Como o software ZEPTO é disponibilizado na modalidade SaaS (Software
            as a Service), e o mesmo não será instalado em servidores ou
            computadores da CONTRATANTE, não se faz necessário a presença de
            colaborador da CONTRATADA nas dependências da CONTRATANTE.
          </p>
          <p>
            <b>Cláusula Primeira: Do Objeto</b>
            <br />
            1.1 - Pelo presente contrato, compromete-se a CONTRATADA a fornecer
            à CONTRATANTE, nos termos deste instrumento, o licenciamento de uso
            (assinatura), de forma onerosa e não exclusiva, do software ZEPTO.
          </p>
          <p>
            1.2 – A CONTRATADA, que detém os direitos autorais do software,
            concede à CONTRATANTE, o direito de usar/acessar a versão do
            software contratado, por tempo indeterminado. Neste sentido, a
            CONTRATANTE possui somente o direito de uso/acesso ao software
            contratado e não de propriedade, não podendo esta transferir a
            outrem, comercializar, doar, arrendar, alienar, sublicenciar e
            tampouco dar o objeto em garantia. A interrupção do pagamento do
            valor mensal ajustado suspenderá imediatamente o direito de
            uso/acesso do software contratado.{' '}
          </p>
          <p>
            <b>Cláusula Segunda: Do Preço </b> <br />
            2.1 - A CONTRATANTE compromete-se a realizar o pagamento do valor
            previamente ajustado, de acordo com o plano contratado, em
            conformidade com as Notas Fiscais emitidas.{' '}
          </p>
          <p>
            2.2 - A adição de usuários solicitadas pela CONTRATANTE que seja
            preciso mudar de plano, em momento posterior à celebração do
            presente contrato, se dará mediante nova transação comercial
            celebrada junto a CONTRATADA, mantendo-se vigentes os termos e
            condições aqui previstos.{' '}
          </p>
          <p>
            <b>Cláusula Terceira: Da Forma de Pagamento</b> <br />
            3.1 - O pagamento será realizado por meio de boleto bancário que a
            CONTRATADA enviará à CONTRATANTE por via eletrônica (e-mail), ao
            endereço de e-mail de cobrança indicado no cadastro da CONTRATANTE.
          </p>
          <p>
            3.2 - No caso da CONTRATANTE não receber o boleto bancário até 03
            (três) dias antes do dia do vencimento, deverá emitir a 2ª via no
            site da CONTRATADA ou informar à CONTRATADA para a sua emissão, sob
            pena de, em não o fazendo, sujeitar-se aos efeitos do atraso como
            adiante detalhado.
          </p>
          <p>
            3.3 - As assinaturas de licenciamento de uso, serão reajustados a
            cada período de 12(doze) meses, contados da data do faturamento do
            pedido de vendas, de acordo com a variação do IGP-M ou por qualquer
            outro índice, a escolha da CONTRATADA, que venha a refletir a
            inflação vigente.
          </p>
          <p>
            3.3.1 - Com o fim de restabelecer a relação que as partes pactuaram
            inicialmente entre os encargos e a retribuição para a justa
            remuneração, objetivando a manutenção do equilíbrio
            econômicofinanceiro inicial do contrato, na hipótese de sobrevirem
            circunstâncias supervenientes não previstas no ato da assinatura do
            contrato, sobretudo em caso de aumento de alíquota dos tributos
            incidentes sobre o serviço ora contratado ou da imposição de novos
            tributos relativos a ele, o valor acrescido será repassado de
            imediato ao preço do serviço, com o que concorda o CONTRATANTE.
          </p>
          <p>
            3.4 - O atraso no pagamento, dá à CONTRATADA o direito de suspender
            temporariamente, independente de qualquer notificação, a prestação
            de todos serviços contratados, como suporte, atualização, acesso ao
            software, comunicação externa de dados, dentre outros, até que os
            débitos sejam regularizados.
          </p>
          <p>
            <b>Cláusula Quarta: Das Responsabilidades Gerais das Partes</b>
            <br />
            4.1 - Compete à CONTRATANTE:
          </p>
          <p>
            I - Indicar profissionais que receberão os treinamentos e
            acompanharão a implantação, bem como os demais processos;
          </p>
          <p>
            II - Manter em dia o pagamento dos serviços e produtos objeto do
            presente Contrato;
          </p>
          <p>
            III - Fornecer à CONTRATADA as informações e o apoio que estiverem
            ao seu alcance para auxiliá-la na prestação dos Serviços;
          </p>
          <p>
            IV - Notificar a CONTRATADA, no caso de verificação de alguma
            irregularidade na prestação dos Serviços;
          </p>
          <p>
            V - Disponibilizar smartphone, tablet, computadores adequados ao
            funcionamento do software. A CONTRATADA não recomenda o uso de
            smartphone, tablet, computadores com sistema operacional
            desatualizado que podem causar instabilidade na comunicação de
            dados, bem como perda de performance significativa no software
            Contratado.
          </p>
          <p>
            VI - Responsabilizar-se pelo cadastramento de dados, pelas
            informações imputadas no sistema, operação e conferência de dados
            extraídos dos sistemas, de acordo com sua atividade, bem como envio
            de informações aos órgãos fiscalizadores.
          </p>
          <p>
            VII – Responsabilizar-se por todos e quaisquer encargos cobrados
            pela operadora de telefonia móvel para conexão do software na
            Internet para o correspondente uso do Serviço.
          </p>
          <p>
            VIII - Conhecimento em informática por parte dos usuários
            colaboradores e gestores, o que inclui o uso do computador,
            smartphones e suas funções e principalmente o conhecimento dos
            processos de ponto e jornada de trabalho.
          </p>
          <p>4.2 - Compete à CONTRATADA</p>
          <p>
            I - Prestar os serviços dentro dos padrões e procedimentos acordados
            no presente Contrato;
          </p>
          <p>
            II - Prestar os serviços utilizando-se de mão de obra especializada,
            treinada e capacitada;
          </p>
          <p>
            III - Fornecer diretamente aos seus empregados as diretrizes dos
            trabalhos a serem executados, bem como fiscalizar a prestação dos
            SERVIÇOS;
          </p>
          <p>
            IV - Manter a CONTRATANTE informada sobre qualquer dificuldade e/ou
            irregularidade que possa prejudicar a execução dos serviços, de modo
            a permitir ações imediatas para regularização da situação, quando
            aplicável;
          </p>
          <p>
            V - Prestar à CONTRATANTE todos e quaisquer esclarecimentos e
            informações que julgar necessários para o acompanhamento da evolução
            do produto e serviço ora contratado.
          </p>
          <p>
            VI - A CONTRATADA reserva-se o direito de recusar o cadastro, ou
            cancelar contas que considere inapropriadas, assim como recusar-se a
            prestar quaisquer serviços que não correspondam às normas e valores
            éticos e morais estabelecidos pela CONTRATADA. A CONTRATADA
            reserva-se o direito de não prestar serviços profissionais quando a
            CONTRATANTE:
            <br />
            <ul>
              <li>
                Praticar ou for conivente com condutas anti-éticas, crimes ou
                contravenções penais;
              </li>
              <li>
                Praticar qualquer forma de agressão ou falta de respeito à
                integridade física, moral e/ou social de colaboradores e/ou
                prepostos da CONTRATADA. VII – A CONTRATADA não será obrigada a
                realizar contato telefônico com a CONTRATANTE, ficando esta
                responsabilizada por conseguir ler e compreender as instruções
                que lhe forem passadas pela via eletrônica escrita e virtual.
              </li>
            </ul>
          </p>
          <p>
            <b> Cláusula Quinta: Da Limitação da Responsabilidade</b> <br />
            5.1 - A CONTRATANTE é a única e exclusiva responsável pelo conteúdo
            e informações inseridas no sistema, devendo isentar e indenizar a
            CONTRATADA por todas e quaisquer perdas e danos sofridos ou
            imputados à CONTRATADA em decorrência das informações e/ou atuações
            indevidas cometidas pela CONTRATANTE.
          </p>
          <p>
            5.2 - Em conformidade com a legislação aplicável, a empresa
            CONTRATADA não será obrigada a reembolsar e/ou indenizar eventuais
            perdas e danos causados pela CONTRATANTE a terceiros, bem como
            perdas e danos específicos sofridos pela CONTRATANTE, em decorrência
            de atos de terceiros, não assumindo qualquer responsabilidade pelas
            informações e/ou obrigações assumidas pela CONTRATANTE por meio dos
            serviços da CONTRATADA.
          </p>
          <p>
            5.3 - Este instrumento não gera contrato de sociedade, mandato,
            franquia ou relação de trabalho entre a empresa CONTRATADA e
            qualquer CONTRATANTE.
          </p>
          <p>
            5.4 - Em nenhum caso a empresa CONTRATADA será responsável pelo
            lucro cessante ou por qualquer outro dano e/ou prejuízo decorrentes
            da conduta da CONTRATANTE.
          </p>
          <p>
            5.5 - A CONTRATADA não se responsabiliza por qualquer dano, prejuízo
            ou perda sofridos pela CONTRATANTE em razão de falhas na internet,
            vírus no sistema ou no servidor decorrentes de condutas de
            terceiros, caso fortuito ou força maior. A CONTRATADA não garante
            que a plataforma Web estará disponível ininterruptamente, que estará
            sempre livre de erros, não podendo, por conseguinte, ser
            responsabilizada por danos causados as CONTRATANTES em virtude de
            qualquer interrupção no funcionamento.
          </p>
          <p>
            5.6 - Em nenhuma circunstância, a CONTRATADA, seus administradores
            ou funcionários serão responsáveis por quaisquer danos diretos ou
            indiretos, especiais, incidentais ou de consequência, perdas ou
            despesas oriundas da falha de conexão de smartphones com operadoras
            de telefonia móvel e/ou com relação à qualquer falha de desempenho,
            erro, omissão, interrupção, defeito ou demora na operação ou
            transmissão, vírus ou falha da linha ou do sistema, isentando-se a
            CONTRATADA de eventuais danos, perdas ou despesas.
          </p>
          <p>
            5.7 - O provimento adequado de todos os recursos de Internet, sem
            exceção, são de inteira responsabilidade da CONTRATANTE.
          </p>
          <p>
            5.8 - O sistema não realiza o rastreamento do funcionário, tão
            somente identifica sua localização no momento da marcação do ponto
            no dispositivo móvel, desde que com o consentimento do Usuário e
            acesso disponível à Internet. Logo, a CONTRATADA não se
            responsabiliza pela impossibilidade de localização do Usuário seja
            pelos motivos acima ou por motivos de casos fortuitos e/ou força
            maior.
          </p>
          <p>
            <b>Cláusula Sexta: Da Implantação</b>
            <br />
            6.1 - A CONTRATADA não se responsabiliza por danos decorrentes de
            mau uso dos softwares, ficando desde já estabelecido que é
            responsabilidade da CONTRATANTE a plena observância aos preceitos
            legais, recolhimentos tributários, sobretudo a conferência de dados
            imputados e extraídos dos softwares, bem como o envio de informações
            aos órgãos fiscalizadores. Neste sentido, a CONTRATANTE assume a
            responsabilidade por eventuais multas, autuações e condenações, na
            esfera administrativa e/ou judicial, decorrentes de condutas que
            ocasionem o descumprimento de obrigações, principais ou acessórias,
            de natureza tributária, trabalhista, previdenciária, fundiária,
            cível, comercial, consumerista ou de qualquer outra natureza de
            sujeição passiva da CONTRATANTE, resultantes, inclusive, de mau uso
            ou uso indevido do software contratado e seus respectivos recursos.
          </p>
          <p>
            <b>Cláusula Sétima: Do Licenciamento de Uso </b> <br />
            7.1 - O licenciamento de uso inclui atualização de versões via
            internet, acesso ao software, consultas via telefone, WhatsApp,
            e-mail e acesso remoto quando autorizada pela CONTRATADA.
          </p>
          <p>
            7.2 - A comunicação externa de dados entre os sistemas, feita
            através da internet, é parte integrante do serviço de licenciamento
            de uso. Neste sentido, somente estará disponível para o CONTRATANTE
            que esteja em dia com o pagamento das mensalidades.
          </p>
          7.3 - Todo e qualquer serviço solicitado pela CONTRATANTE, que não
          estão expressamente previstos neste contrato, serão previamente
          analisados pela CONTRATADA e, sendo possível a execução, a CONTRATADA
          irá ajustar o preço e o prazo de execução a serem informados
          oportunamente.
          <p>
            7.4 - Os softwares contratados são produtos prontos, portanto não
            estão sujeitos a customizações solicitadas por
            usuários/contratantes. Todavia a CONTRATADA analisará a viabilidade
            das modificações solicitadas e, sendo possível executá-las, serão
            desenvolvidas, mediante aprovação de orçamento e cronograma
            apresentados pela CONTRATADA, ficando ressalvado o direito de
            propriedade da CONTRATADA sobre os sistemas, suas versões, releases
            e desenvolvimentos realizados.
          </p>
          <p>
            7.5- A CONTRATADA procederá às atualizações das versões dos
            sistemas, dentro da tecnologia utilizada e de acordo com os padrões
            necessários ao funcionamento dos mesmos, às quais a CONTRATANTE terá
            direito, mantendo em dia o pagamento do licenciamento de uso.
          </p>
          <p>
            7.6 - Todos os sistemas e suas versões são de propriedade da
            CONTRATADA, não podendo a CONTRATANTE dispor dos mesmos, sob
            quaisquer motivos.
          </p>
          <p>
            7.7 - A CONTRATANTE compromete-se a utilizar o software, observando
            o plano contratado na celebração do contrato, não estando autorizada
            a realizar qualquer tipo de cópia, modificação, engenharia reversa,
            reprodução, transmissão dos sistemas para outro usuário ou para
            outro endereço, sem autorização expressa da CONTRATADA, estando
            vedado, ainda, a sublocar ou arrendar os softwares contratados.{' '}
          </p>
          <p>
            7.8 - Sendo solicitada pela CONTRATANTE visita técnica, a CONTRATADA
            poderá autorizar a visita, bem como efetuar a cobrança adicional de
            deslocamento, estadia e alimentação do profissional, caso entenda
            necessário.
          </p>
          <p>
            7.9 - A CONTRATANTE compromete-se a utilizar o software contratado
            em, ao menos, uma máquina com acesso à internet, de acordo com a
            política de uso estabelecida pela CONTRATADA.
          </p>
          <p>
            <b>Cláusula Oitava: Da Política de Privacidade </b> <br />
            8.1 - A CONTRATADA irá coletar e armazenar as seguintes informações
            durante o uso do serviço pela contratante:
          </p>
          <p>
            8.1.1 - Informações de dados cadastrais do CONTRATANTE, como nome,
            razão social, CNPJ, número de telefone, e-mail, dentre outros.
          </p>
          <p>
            8.1.2 - Comprovante de Registro de Ponto, Geolocalização e Selfie
            dos usuários da Contratante.
          </p>
          <p>
            <b>Cláusula Nona: Das Garantias</b> <br />
            9.1 - A CONTRATADA garante ser a única e exclusiva proprietária dos
            direitos autorais relativo ao software contratado, além de possuir a
            competência profissional necessária para a execução dos serviços
            objeto do presente Contrato, respondendo pela qualidade técnica dos
            mesmos, desde que não sofram modificações, acidentes, abusos ou
            utilizações inadequadas ou que o mau funcionamento não seja
            decorrente de problemas de hardware ou rede da CONTRATANTE.
          </p>
          <p>
            9.2 - As versões seguintes do software terão prazo de validade
            técnica, delimitando o período em que a CONTRATANTE poderá proceder
            a qualquer reclamação sobre as mesmas. Este prazo referese às
            implementações realizadas em cada versão.
          </p>
          <p>
            9.3 - A CONTRATADA não se responsabiliza por danos decorrentes de
            mau uso, por comprovada culpa da CONTRATANTE, ficando desde já
            estabelecido que é de responsabilidade da CONTRATANTE a conferência
            de dados extraídos dos sistemas.
          </p>
          <p>
            <b>Cláusula Décima: Da Confidencialidade </b> <br />
            10.1 - Ambas as partes, através de seus administradores, prepostos,
            empregados e subcontratados (quando previamente autorizados),
            guardarão absoluto e completo sigilo sobre todas as informações
            fornecidas pela outra parte, independente da forma por meio do qual
            sejam divulgadas, seus termos e condições e a execução do objeto
            contratual, salvo por ordem judicial.
          </p>
          <p>
            <b>
              Cláusula Décima Primeira: Da Vigência e da Rescisão do Contrato
            </b>
            <br />
            11.1 - Este contrato terá vigência por tempo indeterminado e entrará
            em vigor na data do faturamento da transação comercial. Caso uma das
            partes queira rescindi-lo, deverá comunicar-se por escrito com a
            outra parte, dentro do prazo mínimo de 60 (sessenta dias), que
            corresponde ao aviso prévio de rescisão do contrato. Os débitos
            anteriores ao pedido de rescisão deverão ser quitados.
          </p>
          <p>
            11.1.2 - A rescisão do contrato celebrado desobrigará a CONTRATADA a
            continuidade da prestação dos serviços ora contratados. Nesta
            acepção, os CONTRATANTES do licenciamento de uso deverão promover a
            desinstalação do sistema outrora licenciado pela CONTRATADA,
            portanto, o eventual uso do sistema será INDEVIDO.
          </p>
          <p>
            11.2 - O presente contrato poderá ser rescindido sem aviso prévio,
            pelas partes, nos seguintes casos:
          </p>
          <p>
            I - Por motivo de caso fortuito e/ou força maior, devidamente
            comprovados;
          </p>
          <p>
            II - Decretação de falência, término das atividades, pedido de
            recuperação judicial ou extrajudicial ou declaração de insolvência
            de qualquer das Partes contratantes;
          </p>
          <p>
            III – Atraso no pagamento por parte da CONTRATANTE por período
            superior a 90 (noventa) dias, sem prejuízo de todas as cobranças
            pelos serviços prestados.
          </p>
          <p>
            11.3 - A alteração no quadro societário da CONTRATANTE, por qualquer
            motivo, não isentará os novos representes legais do cumprimento das
            determinações estabelecidas no presente no decorrer de sua vigência.
          </p>
          <p>
            11.4 – Caso a CONTRATANTE rescinda o presente contrato, a reativação
            dos serviços ora contratados estará condicionada a celebração de um
            novo contrato junto a CONTRATADA, situação pela qual deverá ser
            observada a política comercial adotada pela CONTRATADA a época da
            solicitação.
          </p>
          <p>
            <b>Cláusula Décima Segunda: Das Disposições Gerais </b> <br />
            12.1 - Fica ressalvado, entretanto, o direito da CONTRATADA de
            mencionar a CONTRATANTE e sua logomarca em propostas ou outros
            materiais de divulgação, como sendo um cliente da CONTRATADA,
            podendo ainda descrever, de maneira genérica, nesses documentos, os
            serviços objeto do presente.
          </p>
          <p>
            12.2 - A CONTRATANTE declara expressamente que tem pleno
            conhecimento de todas as características, abrangência e limitações
            do software e após ampla avaliação julgou por seus próprios
            critérios que o mesmo está de acordo com suas necessidades.
          </p>
          <p>
            12.3 - O não exercício de qualquer direito previsto no presente
            Contrato, não importará em renúncia, novação ou alteração
            contratual.
          </p>
          <p>
            12.4 - O presente contrato não transfere entre as partes quaisquer
            direitos de propriedade industrial e intelectual sobre suas
            criações, programas de computador, marcas, invenções, modelos de
            utilidade, e outros, que eventualmente as partes tomem conhecimento
            em razão deste instrumento.
          </p>
          <p>
            <b>Cláusula Décima Terceira: Do Foro</b> <br />
            13.1 - As partes contratantes elegem o foro da comarca de Ceres -
            GO, com renúncia a qualquer outro, para dirimir qualquer questão
            pertinente a este contrato.
          </p>
        </Typography>
      </Grid>
    </Grid>
  );
});

AgreementTerms.propTypes = {
  className: PropTypes.string,
};

export default AgreementTerms;
