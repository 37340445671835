import { createActions, createReducer } from 'reduxsauce';

/* #region Action types & creators */
export const { Types, Creators } = createActions({
  loadUserRequest: [null],
  loadUserSuccess: ['user'],
  loadUserFailure: ['error'],

  loadUsersRequest: [null],
  loadUsersSuccess: ['users'],
  loadUsersFailure: [null],

  loadUsersWPointsRequest: [null],
  loadUsersWPointsSuccess: ['users'],
  loadUsersWPointsFailure: [null],

  generateReportRequest: null,
  generateReportSuccess: null,
  generateReportFailure: null,

  createUserRequest: [null],
  createUserSuccess: ['user'],
  createUserFailure: ['error'],

  updateUserRequest: [null],
  updateUserSuccess: ['user'],
  updateUserFailure: ['error'],

  disableUserRequest: [null],
  disableUserSuccess: ['userId'],
  disableUserFailure: ['error'],

  enableUserRequest: [null],
  enableUserSuccess: ['userId'],
  enableUserFailure: ['error'],
});
/* #endregion */

/* #region Initial State */
const INITIAL_STATE = {
  users: [],
};
/* #endregion */

/* #region Load User */
const loadUserRequest = (state = INITIAL_STATE) => ({
  ...state,
  processingLoadUser: true,
  selected: null,
});

const loadUserSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  processingLoadUser: false,
  selected: action.user,
});

const loadUserFailure = (state = INITIAL_STATE) => ({
  ...state,
  processingLoadUser: false,
});

/* #endregion */

/* #region Load User */
const loadUsersWPointsRequest = (state = INITIAL_STATE) => ({
  ...state,
  processingLoadUsersWPoints: true,
  usersWPoints: null,
});

const loadUsersWPointsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  processingLoadUsersWPoints: false,
  usersWPoints: action.users,
});

const loadUsersWPointsFailure = (state = INITIAL_STATE) => ({
  ...state,
  processingLoadUsersWPoints: false,
});

/* #endregion */

/* #region Load Users */

const loadUsersRequest = (state = INITIAL_STATE) => ({
  ...state,
  processingLoadUsers: true,
  loadUsersErrorMessage: '',
  users: [],
});

const loadUsersSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  processingLoadUsers: false,
  loadUsersErrorMessage: '',
  users: action.users,
});

const loadUsersFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  processingLoadUsers: false,
  loadUsersErrorMessage: action.error,
});

/* #endregion */

/* #region Generate Report */

const generateReportRequest = (state = INITIAL_STATE) => ({
  ...state,
  processingGenerateReport: true,
  generateReportErrorMessage: '',
});

const generateReportSuccess = (state = INITIAL_STATE) => ({
  ...state,
  processingGenerateReport: false,
  generateReportErrorMessage: '',
});

const generateReportFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  processingGenerateReport: false,
  generateReportErrorMessage: action.error,
});

/* #endregion */

/* #region Create User */

const createUserRequest = (state = INITIAL_STATE) => ({
  ...state,
  processingCreateUser: true,
  createUserErrorMessage: '',
});

const createUserSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  processingCreateUser: false,
  createUserErrorMessage: '',
  selected: action.user,
});

const createUserFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  processingCreateUser: false,
  createUserErrorMessage: action.error,
});

/* #endregion */

/* #region Update User */

const updateUserRequest = (state = INITIAL_STATE) => ({
  ...state,
  processingUpdateUser: true,
  updateUserErrorMessage: '',
});

const updateUserSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  processingUpdateUser: false,
  updateUserErrorMessage: '',
  selected: action.user,
});

const updateUserFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  processingUpdateUser: false,
  updateUserErrorMessage: action.error,
});

/* #endregion */

/* #region Disable User */

const disableUserRequest = (state = INITIAL_STATE) => ({
  ...state,
  processingDisableUser: true,
  disableUserErrorMessage: '',
});

const disableUserSuccess = (state = INITIAL_STATE) => ({
  ...state,
  processingDisableUser: false,
  disableUserErrorMessage: '',
  selected: { ...state.selected, enabled: false },
});

const disableUserFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  processingDisableUser: false,
  disableUserErrorMessage: action.error,
});

/* #endregion */

/* #region Enable User */

const enableUserRequest = (state = INITIAL_STATE) => ({
  ...state,
  processingEnableUser: true,
  enableUserErrorMessage: '',
});

const enableUserSuccess = (state = INITIAL_STATE) => ({
  ...state,
  processingEnableUser: false,
  enableUserErrorMessage: '',
  selected: { ...state.selected, enabled: true },
});

const enableUserFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  processingEnableUser: false,
  enableUserErrorMessage: action.error,
});

/* #endregion */

export default createReducer(INITIAL_STATE, {
  [Types.LOAD_USER_REQUEST]: loadUserRequest,
  [Types.LOAD_USER_SUCCESS]: loadUserSuccess,
  [Types.LOAD_USER_FAILURE]: loadUserFailure,

  [Types.LOAD_USERS_REQUEST]: loadUsersRequest,
  [Types.LOAD_USERS_SUCCESS]: loadUsersSuccess,
  [Types.LOAD_USERS_FAILURE]: loadUsersFailure,

  [Types.LOAD_USERS_W_POINTS_REQUEST]: loadUsersWPointsRequest,
  [Types.LOAD_USERS_W_POINTS_SUCCESS]: loadUsersWPointsSuccess,
  [Types.LOAD_USERS_W_POINTS_FAILURE]: loadUsersWPointsFailure,

  [Types.GENERATE_REPORT_REQUEST]: generateReportRequest,
  [Types.GENERATE_REPORT_SUCCESS]: generateReportSuccess,
  [Types.GENERATE_REPORT_FAILURE]: generateReportFailure,

  [Types.CREATE_USER_REQUEST]: createUserRequest,
  [Types.CREATE_USER_SUCCESS]: createUserSuccess,
  [Types.CREATE_USER_FAILURE]: createUserFailure,

  [Types.UPDATE_USER_REQUEST]: updateUserRequest,
  [Types.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [Types.UPDATE_USER_FAILURE]: updateUserFailure,

  [Types.DISABLE_USER_REQUEST]: disableUserRequest,
  [Types.DISABLE_USER_SUCCESS]: disableUserSuccess,
  [Types.DISABLE_USER_FAILURE]: disableUserFailure,

  [Types.ENABLE_USER_REQUEST]: enableUserRequest,
  [Types.ENABLE_USER_SUCCESS]: enableUserSuccess,
  [Types.ENABLE_USER_FAILURE]: enableUserFailure,
});
