import { Creators as PlanActions } from '../ducks/plans';

import { jsonFetch } from '../utils/http';
import { Creators as AppActions } from '../ducks/app';

const apiPath = `${process.env.REACT_APP_API_PATH}/api/plans`;

export default function loadPlans() {
  return dispatch => {
    dispatch(PlanActions.loadPlansRequest());

    return jsonFetch(apiPath)
      .then(json => {
        dispatch(PlanActions.loadPlansSuccess(json));
      })
      .catch(error => {
        dispatch(PlanActions.loadPlansFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao carregar os planos: ${error.message}`
          )
        );
      });
  };
}
