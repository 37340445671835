import React, { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Grid, Typography, LinearProgress } from '@material-ui/core';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import UserIcon from '@material-ui/icons/Person';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import ReCAPTCHA from 'react-google-recaptcha';

import StepperButtonBar from '../../components/StepperButtonBar';
import { ZeptoActionCard } from '../../components';
import { formatMoney } from '../../utils/StringUtils';
import { clearLastError } from '../../operators/signupOperator';

import './review.css';

const ReviewStep = memo(props => {
  const {
    company,
    onEditCompany,
    manager,
    onEditManager,
    plan,
    onEditPlan,
    onBack,
    onConfirm,
    loading,
    lastError,
    clearLastError,
  } = props;

  const [reCaptcha, setReCaptcha] = useState(null);

  function onReCAPTCHAChange(value) {
    setReCaptcha(value);
  }

  function onConfirmClick() {
    onConfirm(reCaptcha);
  }

  useEffect(() => {
    if (lastError) {
      setReCaptcha(null);
      setTimeout(clearLastError);
    }
  }, [lastError]);
  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} md={4}>
        <ZeptoActionCard
          avatar={<AccountBalanceIcon />}
          title={company.businnessName}
          subheader={company.cnpj}
          onEdit={onEditCompany}
          enabled={!loading}
        >
          <Typography variant="body2" color="textSecondary" component="p">
            {company.commercialName}
          </Typography>

          <Typography variant="body2" color="textSecondary" component="p">
            {company.address}
          </Typography>
        </ZeptoActionCard>
      </Grid>

      <Grid item xs={12} md={4}>
        <ZeptoActionCard
          avatar={<UserIcon />}
          title={manager.name}
          subheader={manager.cpf}
          onEdit={onEditManager}
          enabled={!loading}
        >
          <Typography variant="body2" color="textSecondary" component="p">
            {manager.email}
          </Typography>
        </ZeptoActionCard>
      </Grid>

      <Grid item xs={12} md={4}>
        <ZeptoActionCard
          avatar={<MoneyIcon />}
          title={plan.name}
          subheader={plan.description}
          onEdit={onEditPlan}
          enabled={!loading}
        >
          <Typography variant="body2" color="textSecondary" component="p">
            De {plan.minUsers} até {plan.maxUsers} usuários.
          </Typography>

          <Typography variant="body2" color="textSecondary" component="p">
            R$ {formatMoney(plan.price)} por mês
          </Typography>
        </ZeptoActionCard>
      </Grid>

      {!lastError && (
        <Grid item xs={12}>
          <div className="g-recaptcha">
            <ReCAPTCHA
              sitekey="6LdCzOEUAAAAAMcUDLXRe3qbzgyM9AODa8j8oird"
              onChange={onReCAPTCHAChange}
            />
          </div>
        </Grid>
      )}

      {loading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}

      <Grid item xs={12}>
        <StepperButtonBar
          onBack={onBack}
          onNext={onConfirmClick}
          loading={loading}
          nextEnabled={!!reCaptcha}
          nextLabel="Confirmar"
        />
      </Grid>
    </Grid>
  );
});

ReviewStep.propTypes = {
  onBack: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onEditCompany: PropTypes.func.isRequired,
  manager: PropTypes.shape({}).isRequired,
  company: PropTypes.shape({}).isRequired,
  plan: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool,
  lastError: PropTypes.instanceOf(Object),
  clearLastError: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  lastError: state.signup.lastError,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearLastError,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewStep);
