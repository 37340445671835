import { goBack } from 'react-router-redux';
import { Creators as AllowanceActions } from '../ducks/allowances';
import { jsonPost, jsonFetch, jsonDelete } from '../utils/http';
import { Creators as AppActions } from '../ducks/app';

const apiPath = userId =>
  `${process.env.REACT_APP_API_PATH}/api/users/${userId}/allowances`;

export function createAllowance(userId, allowance) {
  return dispatch => {
    dispatch(AllowanceActions.createAllowanceRequest());

    return jsonPost(apiPath(userId), allowance)
      .then(json => {
        dispatch(AllowanceActions.createAllowanceSuccess(json));
        dispatch(AppActions.showSuccess('Abono criado com sucesso.'));
        dispatch(goBack());
      })
      .catch(error => {
        dispatch(AllowanceActions.createAllowanceFailure());
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao salvar o abono: ${error.message}`
          )
        );
      });
  };
}

export function deleteAllowance(userId, allowance) {
  return dispatch => {
    dispatch(AllowanceActions.deleteAllowanceRequest());

    return jsonDelete(`${apiPath(userId)}/${allowance.id}`)
      .then(json => {
        dispatch(AllowanceActions.deleteAllowanceSuccess(json));
        dispatch(AppActions.showSuccess('Abono apagado com sucesso.'));
      })
      .catch(error => {
        dispatch(AllowanceActions.deleteAllowanceFailure());
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao apagar o abono: ${error.message}`
          )
        );
      });
  };
}

export function loadAllowances(userId, start, end) {
  return dispatch => {
    dispatch(AllowanceActions.loadAllowancesRequest());

    return jsonFetch(`${apiPath(userId)}?start=${start}&end=${end}`)
      .then(json => {
        dispatch(AllowanceActions.loadAllowancesSuccess(json));
      })
      .catch(error => {
        dispatch(AllowanceActions.loadAllowancesFailure());
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao recuperar os abonos: ${error.message}`
          )
        );
      });
  };
}
