import { addValidationRule } from 'formsy-react';
import moment from 'moment';

const setUpRules = () => {
  addValidationRule('timeIsBeforeOrEqual', (values, value, other) => {
    const thisValue = moment(value, 'HH:mm');
    if (!thisValue.isValid()) {
      return true;
    }
    const time = moment(values[other], 'HH:mm');
    if (!time.isValid()) {
      return true;
    }
    if (thisValue.isAfter(time)) {
      return false;
    }
    return true;
  });

  addValidationRule('timeIsAfterOrEqual', (values, value, other) => {
    const thisValue = moment(value, 'HH:mm');
    if (!thisValue.isValid()) {
      return true;
    }
    const time = moment(values[other], 'HH:mm');
    if (!time.isValid()) {
      return true;
    }
    if (thisValue.isBefore(time)) {
      return false;
    }
    return true;
  });

  addValidationRule('isValidTime', (values, value) => {
    const thisValue = moment(value, 'HH:mm');
    if (!thisValue.isValid()) {
      return false;
    }
    return true;
  });

  addValidationRule('isValidDateTime', (values, value) => {
    const thisValue = moment(value, 'DD/MM/YYYY HH:mm');

    if (!thisValue.isValid()) {
      return false;
    }
    return true;
  });

  addValidationRule('isValidDateTimeFuture', (values, value) => {
    const thisValue = moment(value, 'DD/MM/YYYY HH:mm');
    let hours = moment().diff(thisValue, 'hours');

    if (hours < 0) {
      return false;
    }
    return true;
  });

  addValidationRule('isCpf', (values, cpfText) => {
    const cleanCPF = String(cpfText).replace(/\.|-|\s/g, '');
    const firstNineDigits = cleanCPF.substring(0, 9);
    const checker = cleanCPF.substring(9, 11);

    const calcFirstChecker = (firstNineDigits) => {
      let sum = null;

      for (let j = 0; j < 9; ++j) {
        sum += Number(firstNineDigits.toString().charAt(j)) * (10 - j);
      }

      const lastSumChecker1 = sum % 11;
      const checker1 = lastSumChecker1 < 2 ? 0 : 11 - lastSumChecker1;

      return checker1;
    };

    const calcSecondChecker = (cpfWithChecker1) => {
      let sum = null;

      for (let k = 0; k < 10; ++k) {
        sum += Number(cpfWithChecker1.toString().charAt(k)) * (11 - k);
      }

      const lastSumChecker2 = sum % 11;
      const checker2 = lastSumChecker2 < 2 ? 0 : 11 - lastSumChecker2;

      return checker2;
    };

    if (cleanCPF.length !== 11) {
      return false;
    }

    // Checking if all digits are equal
    for (let i = 0; i < 10; i++) {
      if (`${firstNineDigits}${checker}` === Array(12).join(String(i))) {
        return false;
      }
    }

    const checker1 = calcFirstChecker(firstNineDigits);
    const checker2 = calcSecondChecker(`${firstNineDigits}${checker1}`);

    return checker.toString() === checker1.toString() + checker2.toString();
  });

  addValidationRule('isCnpj', (values, s) => {
    if (!s) return false;
    const cnpj = s.replace(/[^\d]+/g, '');

    // Valida a quantidade de caracteres
    if (cnpj.length !== 14) return false;

    // Elimina inválidos com todos os caracteres iguais
    if (/^(\d)\1+$/.test(cnpj)) return false;

    // Cáculo de validação
    const t = cnpj.length - 2;
    const d = cnpj.substring(t);
    const d1 = parseInt(d.charAt(0), 10);
    const d2 = parseInt(d.charAt(1), 10);
    const calc = (x) => {
      const n = cnpj.substring(0, x);
      let y = x - 7;
      let s2 = 0;
      let r = 0;

      for (let i = x; i >= 1; i--) {
        s2 += n.charAt(x - i) * y--;
        if (y < 2) y = 9;
      }

      r = 11 - (s2 % 11);
      return r > 9 ? 0 : r;
    };

    return calc(t) === d1 && calc(t + 1) === d2;
  });
};

export default setUpRules;
