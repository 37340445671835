import React from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles, Fab, TableCell } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import AccountPlus from '@material-ui/icons/Add';
import { push } from 'react-router-redux';
import { loadUsers } from '../../../operators/usersOperator';
import { ZeptoConfirmDialog, ZeptoFab } from '../../../components';

import UserList from '../../users/list/UserList';

const useStyles = makeStyles(theme => ({
  fab2: {
    margin: theme.spacing(2),
  },
  fab1: {
    margin: theme.spacing(1),
  },
}));

const CompanyUsersList = props => {
  const { company } = props;
  const [confirmOptions, setConfirmOptions] = React.useState({
    open: false,
  });

  React.useEffect(() => {
    const { loadUsers, company } = props;
    loadUsers(company.id);
  }, [company]);

  const closeConfirmation = () => setConfirmOptions({ open: false });

  const onNewUser = () => {
    const { push } = props;
    push('/users/0/edit');
  };

  function onUserClick(id) {
    const { push } = props;
    push(`/users/${id}/view`);
  }

  const classes = useStyles();

  const { users, processingLoadUser, processingLoadUsers } = props;

  const loading = () => processingLoadUser || processingLoadUsers;

  return (
    <>
      <UserList
        loading={loading()}
        users={users}
        selectable={false}
        headRows={[{ id: 'actions', label: '' }]}
        renderActions={() => (
          <Fab
            className={classes.fab2}
            disabled={loading()}
            onClick={onNewUser}
            color="primary"
            size="small"
          >
            <AccountPlus />
          </Fab>
        )}
        renderCells={user => (
          <>
            <TableCell align="right">
              <ZeptoFab
                className={classes.fab1}
                onClick={() => onUserClick(user.id)}
                icon={<KeyboardArrowRightIcon />}
                loading={loading()}
              />
            </TableCell>
          </>
        )}
      />
      <ZeptoConfirmDialog
        open={confirmOptions.open}
        onConfirm={confirmOptions.onConfirm}
        onCancel={closeConfirmation}
        content={confirmOptions.content}
      />
    </>
  );
};

CompanyUsersList.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  processingLoadUser: PropTypes.bool,
  processingLoadUsers: PropTypes.bool,
  push: PropTypes.func.isRequired,
};

CompanyUsersList.defaultProps = {
  users: [],
};

const mapStateToProps = store => ({
  users: store.users.users,
  company: store.companies.selected,
  processingLoadUser: store.users.processingLoadUser,
  processingLoadUsers: store.users.processingLoadUsers,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadUsers,
      push,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(CompanyUsersList));
