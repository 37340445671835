import { createContext } from 'react';
import { decorate, observable } from 'mobx';

export const LIGHT_THEME = 'light';
export const DARK_THEME = 'dark';
class AppStore {
  theme = localStorage.getItem('theme');

  alertMessage;

  setTheme = theme => {
    this.theme = theme;
    localStorage.setItem('theme', theme);
  };

  hideMessage = () => {
    this.alertMessage = null;
  };

  showError = text => {
    this.alertMessage = {
      text,
      variant: 'error',
    };
  };

  showWarn = text => {
    this.alertMessage = {
      text,
      variant: 'warning',
    };
  };

  showSuccess = text => {
    this.alertMessage = {
      text,
      variant: 'success',
    };
  };
}

decorate(AppStore, {
  theme: observable,
  alertMessage: observable,
});

export const appStore = new AppStore();

export default createContext(appStore);
