import { push, goBack } from 'react-router-redux';
import moment from 'moment';
import { Creators as UserActions } from '../ducks/users';
import {
  jsonFetch,
  jsonPost,
  jsonPut,
  jsonFetchBlob,
  jsonDelete,
} from '../utils/http';
import { Creators as AppActions } from '../ducks/app';

const apiPath = `${process.env.REACT_APP_API_PATH}/api/users`;
const companyApiPath = `${process.env.REACT_APP_API_PATH}/api/companies`;

export function loadUser(id) {
  return dispatch => {
    dispatch(UserActions.loadUserRequest());

    return jsonFetch(`${apiPath}/${id}`)
      .then(json => {
        dispatch(UserActions.loadUserSuccess(json));
      })
      .catch(error => {
        dispatch(UserActions.loadUserFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao carregar os usuários: ${error.message}`
          )
        );
      });
  };
}

export function loadUsers(companyId, role = '', enabled = '') {
  return dispatch => {
    dispatch(UserActions.loadUsersRequest());

    return jsonFetch(
      `${companyApiPath}/${companyId}/users?role=${role}&enabled=${enabled}`
    )
      .then(json => {
        dispatch(UserActions.loadUsersSuccess(json));
      })
      .catch(error => {
        dispatch(UserActions.loadUsersFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao carregar o usuário: ${error.message}`
          )
        );
      });
  };
}

export function loadUsersWPoints(companyId, date) {
  const queryDate = moment(date).format('YYYY-MM-DD');
  return dispatch => {
    dispatch(UserActions.loadUsersWPointsRequest());

    return jsonFetch(
      `${companyApiPath}/${companyId}/usersWPoints?date=${queryDate}`
    )
      .then(json => {
        dispatch(UserActions.loadUsersWPointsSuccess(json));
      })
      .catch(error => {
        dispatch(UserActions.loadUsersWPointsFailure());
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao carregar os pontos dos usuários: ${error.message}.`
          )
        );
      });
  };
}

export function generateReport(companyId, ids, start, end, type) {
  return dispatch => {
    dispatch(UserActions.generateReportRequest());

    const st = start ? moment(start).format('YYYY-MM-DD') : '';
    const en = end ? moment(end).format('YYYY-MM-DD') : '';

    return jsonFetchBlob(
      `${companyApiPath}/${companyId}/report?ids=${ids}&start=${st}&end=${en}&type=${type}`
    )
      .then(pdf => {
        dispatch(UserActions.generateReportSuccess(pdf));

        const url = window.URL.createObjectURL(pdf);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'relatorio.pdf';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      })
      .catch(error => {
        dispatch(UserActions.generateReportFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao processar o rela†ório: ${error.message}`
          )
        );
      });
  };
}

export function createUser(user) {
  return dispatch => {
    dispatch(UserActions.createUserRequest());

    return jsonPost(`${apiPath}`, user)
      .then(json => {
        dispatch(UserActions.createUserSuccess(json));
        dispatch(AppActions.showSuccess('Usuário criado com sucesso.'));
        dispatch(push(`/companies/${json.companyId}`));
      })
      .catch(error => {
        dispatch(UserActions.createUserFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao criar o usuário: ${error.message}`
          )
        );
      });
  };
}

export function updateUser(user) {
  return dispatch => {
    dispatch(UserActions.updateUserRequest());

    return jsonPut(`${apiPath}/${user.id}`, user)
      .then(json => {
        dispatch(UserActions.updateUserSuccess(json));
        dispatch(AppActions.showSuccess('Usuário atualizado com sucesso.'));
        dispatch(goBack());
      })
      .catch(error => {
        dispatch(UserActions.updateUserFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao atualizar o usuário: ${error.message}`
          )
        );
      });
  };
}

export function disableUser(user) {
  return dispatch => {
    dispatch(UserActions.disableUserRequest());

    return jsonDelete(`${apiPath}/${user.id}`)
      .then(json => {
        dispatch(UserActions.disableUserSuccess(json));
        dispatch(AppActions.showSuccess('Usuário desabilitado com sucesso.'));
      })
      .catch(error => {
        dispatch(UserActions.disableUserFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao desabilitar o usuário: ${error.message}`
          )
        );
      });
  };
}

export function enableUser(user) {
  return dispatch => {
    dispatch(UserActions.enableUserRequest());

    return jsonPut(`${apiPath}/${user.id}/enable`)
      .then(json => {
        dispatch(UserActions.enableUserSuccess(json));
        dispatch(AppActions.showSuccess('Usuário habilitado com sucesso.'));
      })
      .catch(error => {
        dispatch(UserActions.enableUserFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao habilitar o usuário: ${error.message}`
          )
        );
      });
  };
}

export function loadEmployers() {
  return dispatch => {
    dispatch(UserActions.loadUsersRequest());

    return jsonFetch(`${apiPath}/employers`)
      .then(json => {
        dispatch(UserActions.loadUsersSuccess(json));
      })
      .catch(error => {
        dispatch(UserActions.loadUsersFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao carregar os empregadores: ${error.message}`
          )
        );
      });
  };
}

export function editUser(id) {
  return dispatch => {
    dispatch(UserActions.loadUserRequest());
    return jsonFetch(`${apiPath}/${id}`)
      .then(json => {
        dispatch(UserActions.loadUserSuccess(json));
        dispatch(push(`/users/${id}/edit`));
      })
      .catch(error => {
        dispatch(UserActions.loadUserFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao carregar o usuário: ${error.message}`
          )
        );
      });
  };
}
