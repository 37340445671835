import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ZeptoTextField, ZeptoMaskedTextField } from '../../components';

const inputProps = {
  max255: {
    maxLength: 255,
  },
};

const CompanyInput = memo(props => {
  const { company, onChange, errors } = props;

  function handleChange(event) {
    onChange({ ...company, [event.target.name]: event.target.value });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ZeptoTextField
          name="businnesName"
          value={company.businnesName}
          label="Razão Social"
          onChange={handleChange}
          errorMessage={errors('businnesName')}
          required
          inputProps={inputProps.max255}
        />
      </Grid>
      <Grid item xs={12}>
        <ZeptoTextField
          name="commercialName"
          value={company.commercialName}
          label="Nome Fantasia"
          onChange={handleChange}
          errorMessage={errors('commercialName')}
          required
          inputProps={inputProps.max255}
        />
      </Grid>
      <Grid item xs={12}>
        <ZeptoMaskedTextField
          name="cnpj"
          value={company.cnpj}
          label="CNPJ"
          onChange={handleChange}
          errorMessage={errors('cnpj')}
          required
          inputProps={{
            mask: [
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              '/',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
            ],
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ZeptoTextField
          name="street"
          value={company.street}
          label="Rua"
          onChange={handleChange}
          errorMessage={errors('address')}
          required
          inputProps={inputProps.max255}
        />
      </Grid>
      <Grid item xs={6}>
        <ZeptoTextField
          name="address"
          value={company.address}
          label="Logradouro"
          onChange={handleChange}
          errorMessage={errors('address')}
          required
          inputProps={inputProps.max255}
        />
      </Grid>
      <Grid item xs={2}>
        <ZeptoTextField
          name="number"
          value={company.number}
          label="Número"
          onChange={handleChange}
          errorMessage={errors('address')}
          required
          inputProps={inputProps.max255}
        />
      </Grid>
      <Grid item xs={2}>
        <ZeptoTextField
          name="city"
          value={company.city}
          label="Cidade"
          onChange={handleChange}
          errorMessage={errors('address')}
          required
          inputProps={inputProps.max255}
        />
      </Grid>
      <Grid item xs={2}>
        <ZeptoTextField
          name="state"
          value={company.state}
          label="Estado"
          onChange={handleChange}
          errorMessage={errors('address')}
          required
          inputProps={inputProps.max255}
        />
      </Grid>
      <Grid item xs={6}>
        <ZeptoTextField
          name="complement"
          value={company.complement}
          label="Complemento"
          onChange={handleChange}
          errorMessage={errors('address')}
          required
          inputProps={inputProps.max255}
        />
      </Grid>
      <Grid item xs={6}>
        <ZeptoMaskedTextField
          name="cep"
          value={company.cep}
          label="CEP"
          onChange={handleChange}
          errorMessage={errors('address')}
          required
          inputProps={{
            mask: [
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
            ],
          }}
        />
      </Grid>
    </Grid>
  );
});

CompanyInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Object),
  company: PropTypes.instanceOf(Object),
};

export default CompanyInput;
