import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import auth from './auth';
import app from './app';
import allowances from './allowances';
import companies from './companies';
import companyReports from './companyReports';
import points from './points';
import users from './users';
import requests from './requests';
import userWorkSchedules from './userWorkSchedules';
import workDays from './workDays';
import signup from './signup';
import plans from './plans';

export default combineReducers({
  app,
  allowances,
  auth,
  companies,
  companyReports,
  users,
  points,
  requests,
  userWorkSchedules,
  workDays,
  signup,
  plans,
  routing: routerReducer,
});
