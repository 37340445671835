import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Grid, Paper, Toolbar, MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { loadUsersWPoints } from '../../../operators/usersOperator';
import { ZeptoSelect } from '../../../components';
import ZeptoButton from '../../../components/ZeptoButton';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import ScrollArea from 'react-scrollbar'
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { loadWorkdayData } from '../../../operators/workDayOperator';

const CompanyDashboard = props => {
  const [date, setDate] = useState(new Date());
  const [dateChartStart, setDateChartStart] = useState(moment().startOf('month'));
  const [dateChartEnd, setDateChartEnd] = useState(moment());
  const [typeOfPoint, setTypeOfPoint] = useState(1)
  const [selectedUser, setSelectedUser] = useState('default')
  const [selectedUserRegisterPoints, setSelectedUserRegisterPoints] = useState(2)
  const [value, setValue] = useState([])

  const [chartBalanceData, setChartBalanceData] = useState({
    labels: [],
    datasets: []
  })

  const [chartExtraHoursData, setChartExtraHoursData] = useState({
    labels: [],
    datasets: []
  })

  const { company, usersWPoints } = props;
  let totalUsersPointsReduce
  let usersPointsRegister = []
  let users = [{ id: 'default', label: "Todos" }]

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const balance = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Horas faltantes',
        font: {
          size: 50
        }
      },
    },
  };

  const extraHours = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Horas extras',
        font: {
          size: 50
        }
      },
    },
  };

  React.useEffect(() => {
    let datasetsBalance = []
    let datasetsExtraHours = []
    let dataBalance = []
    let dataExtraHours = []
    let labels = []

    if (value && value.length > 0) {
      value.map(user => {
        user.workDays.map(workDay => {
          const found = labels.find(date => date === moment(workDay.date).format('DD/MM/YYYY'))
          if (!found) labels.push(moment(workDay.date).format('DD/MM/YYYY'))
          const extraHours2 = workDay.timeReport.extraHours2 ? workDay.timeReport.extraHours2.duration : 0

          dataExtraHours.push(extraHours2)

          if (workDay.timeReport.balance < 0) {
            dataBalance.push(workDay.timeReport.balance)
          }
        })

        datasetsBalance.push({
          label: user.user.name,
          data: dataBalance,
          backgroundColor: 'rgba(53, 162, 235, 0.5)'
        })

        datasetsExtraHours.push({
          label: user.user.name,
          data: dataExtraHours,
          backgroundColor: 'rgba(53, 162, 235, 0.5)'
        })

        dataBalance = []
        dataExtraHours = []
      })
    }

    setChartBalanceData({
      labels,
      datasets: datasetsBalance
    });

    setChartExtraHoursData({
      labels,
      datasets: datasetsExtraHours
    });
  }, [value])

  const filterChart = async () => {
    let userId = []

    if (usersWPoints) {
      for (const user of usersWPoints) {
        userId.push(user.user.id)
      }
    }

    const loadWorkDayData = await loadWorkdayData(userId, dateChartStart, dateChartEnd)
    setValue(loadWorkDayData)
  }

  const typeOfPointList = [
    { id: 1, label: "Número de solicitação de pontos" },
    { id: 2, label: "Pontos registrados" },
    { id: 3, label: "Pontos aceitos" },
    { id: 4, label: "Pontos rejeitados" }
  ]

  const userRegisterPoints = [
    { id: 1, label: "Colaboradores registraram pontos no dia" },
    { id: 2, label: "Colaboradores que não registrou nenhum ponto" },
  ]

  React.useEffect(() => {
    const { loadUsersWPoints, company } = props;
    loadUsersWPoints(company.id, date);
  }, [company, date]);

  const filters = () => {
    if (usersWPoints) {
      let usersPoints = []
      let teste = []
      const initialValue = 0;
      
      usersWPoints.map(user => {
        users.push({ id: user.user.id, label: user.user.name })
        //Usuarios
        if (user.workDay.points.length > 0 && selectedUserRegisterPoints === 1) {
          //registraram ponto.
          usersPointsRegister.push(user)

        } else if (user.workDay.points.length === 0 && selectedUserRegisterPoints === 2) {
          //não registraram ponto.
          usersPointsRegister.push(user)
        }
        if (selectedUser !== 'default') {
          if (user.user.id === selectedUser && typeOfPoint === 1) {
            totalUsersPointsReduce = user.workDay.points.find((point) => {
              let status = point.requests[point.requests.length - 1] ? point.requests[point.requests.length - 1].status : null

              if (point && status && status === 'OPEN') {
                usersPoints.push([point].length)
              }
            })
          } else if (user.user.id === selectedUser && typeOfPoint === 2) {
            usersPoints.push(user.workDay.points.length)

          } else if (user.user.id === selectedUser && typeOfPoint === 3) {
            totalUsersPointsReduce = user.workDay.points.find((point) => {
              let status = point.requests[point.requests.length - 1] ? point.requests[point.requests.length - 1].status : null

              if (point && status && status === 'APPROVED') {
                usersPoints.push([point].length)
              }
            })
          } else if (user.user.id === selectedUser && typeOfPoint === 4) {
            totalUsersPointsReduce = user.workDay.points.find((point) => {
              let status = point.requests[point.requests.length - 1] ? point.requests[point.requests.length - 1].status : null

              if (point && status && status === 'REJECTED') {
                usersPoints.push([point].length)
              }
            })
          }
        } else {
          //todos usuarios
          user.workDay.points.map(point => {
            teste.push({
              name: point.timestamp,
              userName: user.user.name,
              qtdPontos: [point].length
            })
          })
          if (typeOfPoint === 1) {
            totalUsersPointsReduce = user.workDay.points.find((point) => {
              let status = point.requests[point.requests.length - 1] ? point.requests[point.requests.length - 1].status : null

              if (point && status && status === 'OPEN') {
                usersPoints.push([point].length)
              }
            })
          } else if (typeOfPoint === 2) {
            usersPoints.push(user.workDay.points.length)

          } else if (typeOfPoint === 3) {
            totalUsersPointsReduce = user.workDay.points.find((point) => {
              let status = point.requests[point.requests.length - 1] ? point.requests[point.requests.length - 1].status : null

              if (point && status && status === 'APPROVED') {
                usersPoints.push([point].length)
              }
            })
          } else if (typeOfPoint === 4) {
            totalUsersPointsReduce = user.workDay.points.find((point) => {
              let status = point.requests[point.requests.length - 1] ? point.requests[point.requests.length - 1].status : null

              if (point && status && status === 'REJECTED') {
                usersPoints.push([point].length)
              }
            })
          }
        }

        totalUsersPointsReduce = usersPoints.reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          initialValue
        )
      })
    }
  }

  const firstDataChart = async () => {
    if (value.length === 0) {
      if (usersWPoints) {
        const firstDay = moment().startOf('month').format()
        const today = moment().format()
        let userId = []
        for (const user of usersWPoints) {
          userId.push(user.user.id)
        }

        const loadWorkDayData = await loadWorkdayData(userId, firstDay, today)

        if (loadWorkDayData) {
          setValue(loadWorkDayData)
        } else {
          setValue([])
        }
      }
    }
  }
  
  firstDataChart()
  filters()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <Toolbar>
            <Grid item xs={10}>
              <Typography
                component="h1"
                variant="h5"
              >
                Colaboradores
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <KeyboardDatePicker
                label="Data de Início"
                autoOk
                fullWidth
                ampm={false}
                format="DD/MM/YYYY"
                value={date !== null ? moment(date, 'DD/MM/YYYY') : null}
                onChange={date => setDate(date)}
              />
            </Grid>
          </Toolbar>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper >
          <Toolbar style={{ padding: '10px' }}>
            <Grid item xs={9}>
              <Grid>
                <Toolbar>
                  <ZeptoSelect
                    required
                    label="Usuários"
                    value={selectedUser}
                    onChange={e => setSelectedUser(e.target.value)}
                  >
                    {users.map((p) => (
                      <MenuItem key={p.id} value={p.id}>
                        {p.label}
                      </MenuItem>
                    ))}
                  </ZeptoSelect>
                </Toolbar>
              </Grid>
              <Grid>
                <Toolbar>
                  <ZeptoSelect
                    required
                    label=""
                    value={typeOfPoint}
                    onChange={e => setTypeOfPoint(e.target.value)}
                  >
                    {typeOfPointList.map((p) => (
                      <MenuItem key={p.id} value={p.id}>
                        {p.label}
                      </MenuItem>
                    ))}
                  </ZeptoSelect>
                </Toolbar>
              </Grid>
            </Grid>
            <Grid item xs={7}>
              <Typography
                color='primary'
                style={{ fontSize: 40, textAlign: 'center' }}
              >
                {totalUsersPointsReduce}
              </Typography>
            </Grid>
          </Toolbar>
        </Paper>
      </Grid>
      <Grid style={{ width: '49%' }}>
        <Paper style={{ marginTop: 10 }}>
          <Grid item xs={11}>
            <Toolbar style={{ justifyContent: 'center' }}>
              <ZeptoSelect
                required
                label=""
                value={selectedUserRegisterPoints}
                onChange={e => {
                  usersPointsRegister = []
                  setSelectedUserRegisterPoints(e.target.value)
                }}
              >
                {userRegisterPoints.map((p) => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.label}
                  </MenuItem>
                ))}
              </ZeptoSelect>
            </Toolbar>
          </Grid>
          <Grid style={{ padding: '10px' }}>
            <ScrollArea
              speed={0.8}
              className="area"
              contentClassName="content"
              horizontal={false}
              verticalContainerStyle={{ height: '10px' }}
              style={{ height: '70px' }}
            >
              {usersPointsRegister.map(userPointsRegister => (
                <Toolbar>
                  <Typography
                    component="h1"
                    color='primary'
                  >
                    {userPointsRegister.user.name}
                  </Typography>
                </Toolbar>
              ))}
            </ScrollArea>
          </Grid>
        </Paper>
      </Grid >
      <Grid item xs={12}>
        <Paper>
          <Grid item xs={12}>
            <Toolbar>
              <Grid item xs={6} style={{ marginRight: 20 }}>
                <KeyboardDatePicker
                  label="Data de Início"
                  autoOk
                  fullWidth
                  ampm={false}
                  format="DD/MM/YYYY"
                  value={dateChartStart !== null ? moment(dateChartStart, 'DD/MM/YYYY') : null}
                  onChange={date => setDateChartStart(date)}
                />
              </Grid>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  label="Data de fim"
                  autoOk
                  fullWidth
                  ampm={false}
                  format="DD/MM/YYYY"
                  value={dateChartEnd !== null ? moment(dateChartEnd, 'DD/MM/YYYY') : null}
                  onChange={date => setDateChartEnd(date)}
                />
              </Grid>
            </Toolbar>
            <Toolbar>
              <Grid item xs={11} style={{ marginLeft: 40 }}>
                <ZeptoButton
                  style={{ float: 'right' }}
                  label="Filtrar Horas Extra e Faltantes"
                  onClick={() => filterChart()}
                />
              </Grid>
            </Toolbar>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid item xs={12}>
            <Grid style={{ padding: '10px' }}>
              <Bar options={balance} data={chartBalanceData} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid style={{ padding: '10px' }}>
            <Bar options={extraHours} data={chartExtraHoursData} />
          </Grid>
        </Paper>
      </Grid>
    </Grid >
  );
};

CompanyDashboard.propTypes = {
  usersWPoints: PropTypes.arrayOf(PropTypes.shape({})),
  company: PropTypes.shape({}).isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadUsersWPoints
    },
    dispatch
  );


const mapStateToProps = store => ({
  usersWPoints: store.users.usersWPoints,
  company: store.companies.selected,
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(CompanyDashboard));