import React, { useState } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import {
  ZeptoDatePicker,
  ZeptoHoursPicker,
  ZeptoSelect,
  ZeptoTextField,
} from '../../../components';

const inputProps = {
  max100: {
    maxLength: 100,
  },
};

const negativeType = { id: 'NEGATIVE', label: 'Debitar' };
const positiveType = { id: 'POSITIVE', label: 'Creditar' };

const types = [positiveType, negativeType];
function TimeAdjustmentInput(props) {
  const { value, onUpdate, getError, disabled } = props;
  const [state, setState] = useState({
    date: value.date,
  });

  function onChangeDate(field) {
    return date => setState({ ...state, [field]: date })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ZeptoTextField
          label="Motivo"
          name="reason"
          value={value.reason}
          onUpdate={onUpdate}
          errorMessage={getError('reason')}
          inputProps={inputProps.max100}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12}>
        <ZeptoDatePicker
          name="date"
          value={value.date}
          onUpdate={onUpdate}
          label="Data"
          errorMessage={getError('date')}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <ZeptoHoursPicker
          label="Horas"
          name="time"
          value={value.time}
          onUpdate={onUpdate}
          errorMessage={getError('time')}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12}>
        <ZeptoSelect
          required
          label="Tipo"
          value={value.type}
          name="type"
          onUpdate={onUpdate}
          disabled={disabled}
        >
          {types.map(r => (
            <MenuItem key={r.id} value={r.id}>
              {r.label}
            </MenuItem>
          ))}
        </ZeptoSelect>
      </Grid>
    </Grid>
  );
}

TimeAdjustmentInput.propTypes = {};

export default TimeAdjustmentInput;
