import React, { memo } from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TablePagination,
  LinearProgress,
} from '@material-ui/core';
import { ZeptoCheckbox } from '..';

const getRows = results => {
  const res = results || [];

  return res.results || res;
};

const useListStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowY: 'auto',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
}));

const ZeptoListHead = memo(props => {
  const {
    selectable,
    rowCount,
    numSelected,
    onSelectAllClick,
    headCells,
    title,
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell colSpan={99}>{title}</TableCell>
      </TableRow>
      <TableRow>
        {selectable && (
          <TableCell padding="checkbox">
            <ZeptoCheckbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
        )}

        {headCells}
      </TableRow>
    </TableHead>
  );
});

const ZeptoListBody = memo(props => {
  const { selected, rows, onSelectionChange, renderBodyCells } = props;

  const selectable = !!selected;

  function isSelected(item) {
    return selected && selected.includes(item);
  }

  function onRowClick(item) {
    if (selectable) {
      if (isSelected(item)) onSelectionChange(selected.filter(i => i !== item));
      else onSelectionChange([...selected, item]);
    }
  }

  return (
    <TableBody>
      {rows.map((row, index) => {
        const isItemSelected = isSelected(row);
        return (
          <TableRow
            hover
            onClick={() => onRowClick(row)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
          >
            {selectable && (
              <TableCell padding="checkbox">
                <ZeptoCheckbox checked={isItemSelected} />
              </TableCell>
            )}
            {renderBodyCells(row)}
          </TableRow>
        );
      })}
    </TableBody>
  );
});

const ZeptoList = memo(props => {
  const {
    title,
    results,
    selected,
    dense,
    headCells,
    onSelectionChange,
    renderBodyCells,
    onChangePage,
    loading,
  } = props;

  const classes = useListStyles();
  const rows = getRows(results);
  const numSelected = (selected || []).length;

  function onSelectAll() {
    onSelectionChange(numSelected === rows.length ? [] : rows);
  }

  function handleChangeRowsPerPage(event) {
    const newPageSize = parseInt(event.target.value, 10);
    onChangePage({ page: 0, pageSize: newPageSize });
  }

  function handleChangePage(event, newPage) {
    onChangePage({ page: newPage });
  }

  return (
    <div className={classes.root}>
      <>
        {loading && <LinearProgress />}

        <Table size={dense ? 'small' : 'medium'}>
          <ZeptoListHead
            selectable={!!selected}
            numSelected={numSelected}
            onSelectAllClick={onSelectAll}
            rowCount={rows.length}
            headCells={headCells}
            title={title}
          />
          <ZeptoListBody
            selected={selected}
            rows={rows}
            renderBodyCells={renderBodyCells}
            onSelectionChange={onSelectionChange}
          />
        </Table>

        {!loading && rows.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={results.total}
            rowsPerPage={results.pageSize}
            page={results.page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            disabled
          />
        )}
      </>
      {loading && <LinearProgress />}
    </div>
  );
});

ZeptoListHead.propTypes = {
  selectable: PropTypes.bool,
  rowCount: PropTypes.number,
  numSelected: PropTypes.number,
  onSelectAllClick: PropTypes.func,
  headCells: PropTypes.string,
  title: PropTypes.string,
  selected: PropTypes.bool
}

ZeptoListBody.propTypes = {
  selected: PropTypes.bool,
  rows: PropTypes.array,
  onSelectionChange: PropTypes.func
}

ZeptoList.propTypes = {};

export default ZeptoList;
