import { push } from 'react-router-redux';
import { Creators as PointActions } from '../ducks/points';
import { Creators as RequestActions } from '../ducks/requests';
import { jsonPost, jsonFetch, jsonFetchBlob } from '../utils/http';
import { Creators as AppActions } from '../ducks/app';
import { RequestType } from '../model/Request';

const apiPath = `${process.env.REACT_APP_API_PATH}/api/points`;

export function registerPoint(point) {
  return dispatch => {
    dispatch(PointActions.registerPointRequest());

    return jsonPost(`${apiPath}`, point)
      .then(json => {
        dispatch(PointActions.registerPointSuccess(json));
      })
      .catch(error => {
        dispatch(PointActions.registerPointFailure(error.message));
        dispatch(
          AppActions.showError(`Ocorreu um registrar o ponto: ${error.message}`)
        );
      });
  };
}

export function loadPointPhoto(point) {
  return dispatch => {
    dispatch(PointActions.loadPointPhotoRequest());

    return jsonFetchBlob(`${apiPath}/${point.id}/photo`)
      .then(json => {
        dispatch(PointActions.loadPointPhotoSuccess(json));
      })
      .catch(error => {
        dispatch(PointActions.loadPointPhotoFailure());
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao carregar a foto: ${error.message}`
          )
        );
      });
  };
}

export function loadPointReceipt(point) {
  return dispatch => {
    dispatch(PointActions.loadPointReceiptRequest());

    return jsonFetch(`${apiPath}/${point.id}/receipt`)
      .then(receipt => {
        dispatch(PointActions.loadPointReceiptSuccess(receipt));
      })
      .catch(error => {
        dispatch(PointActions.loadPointReceiptFailure());
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao carregar o comprovante de ponto: ${error.message}`
          )
        );
      });
  };
}

export function disablePoint(point) {
  return dispatch => {
    dispatch(
      RequestActions.changeSelected({
        type: RequestType.disable,
        point,
      })
    );
    dispatch(push('/me/register/request'));
  };
}

export function clearLastPoint() {
  return dispatch => {
    dispatch(PointActions.clearLastPoint());
  };
}
export function clearLastError() {
  return dispatch => {
    dispatch(PointActions.clearLastError());
  };
}
