import React from 'react';
import { Dialog, DialogTitle, Slide, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ZeptoConfirmDialog = props => {
  const {
    open,
    fullScreen,
    title,
    children,
    onClose,
    renderAction,
    width,
  } = props;
  const [isOpen, setIsOpen] = React.useState(false);

  const isMobile = isWidthDown('xs', width);

  React.useEffect(() => {
    setIsOpen(!!open);
  }, [open]);

  return (
    <Dialog
      fullScreen={fullScreen || isMobile}
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        <Grid
          container
          justify="space-between"
          direction="row"
          alignItems="center"
        >
          <Grid item xs={9}>
            {title}
          </Grid>
          {renderAction && (
            <Grid item xs={3}>
              {renderAction()}
            </Grid>
          )}
        </Grid>
      </DialogTitle>

      {children}
    </Dialog>
  );
};

ZeptoConfirmDialog.propTypes = {
  open: PropTypes.bool,
  fullScreen: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  renderAction: PropTypes.func,
  width: PropTypes.string,
};

ZeptoConfirmDialog.defaultProps = {
  title: 'Confirmação',
};

export default React.memo(withWidth()(ZeptoConfirmDialog));
