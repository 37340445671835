import React, {  useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import UserJourneyStore from '../../../stores/UserJourneyStore';
import UserJourneysList from './UserJourneysList';
import { ZeptoConfirmDialog } from '../../../components';
import UserJourneyForm from './UserJourneyForm';

function UserJourneys(props) {
  const { user } = props;

  const {
    loadUserJourneys,
    clearUserJourneys,
    loadingUserJourneys,
    userJourneys,
    deletingUserJourney,
    deleteUserJourney,
  } = useContext(UserJourneyStore);

  const [confirmOptions, setConfirmOptions] = React.useState({
    open: false,
  });

  const closeConfirmation = () => setConfirmOptions({ open: false });

  useEffect(() => {
    loadUserJourneys(user.id);
    return clearUserJourneys;
  }, []);

  function onDeleteJourneyConfirm(journey) {
    deleteUserJourney(journey);
    closeConfirmation();
  }

  function onDeleteJourney(journey) {
    setConfirmOptions({
      open: true,
      onConfirm: () => onDeleteJourneyConfirm(journey),
      content: 'Deseja realmente excluir o registro selecionado?',
    });
  }

  return (
    <>
      <UserJourneysList
        journeys={userJourneys}
        onDelete={onDeleteJourney}
        readOnly={!user.enabled}
        loading={loadingUserJourneys}
        renderSubTitle={() => (
          <>{user.enabled && <UserJourneyForm user={user} />}</>
        )}
      ></UserJourneysList>
      <ZeptoConfirmDialog
        open={confirmOptions.open}
        onConfirm={confirmOptions.onConfirm}
        onCancel={closeConfirmation}
        content={confirmOptions.content}
        loading={deletingUserJourney}
      />
    </>
  );
}

UserJourneys.propTypes = {};

export default observer(UserJourneys);
