import React, { useContext, useEffect } from 'react';
import { Grid, LinearProgress } from '@material-ui/core';
import { observer } from 'mobx-react';
import CompanySettingsStore from '../../../stores/CompanySettingsStore';
import CompanyStore from '../../../stores/CompanyStore';

import SettingsForm from './SettingsForm';
import { ZeptoPaper } from '../../../components';

const CompanySettings = observer(() => {
const { company } = useContext(CompanyStore);
  const {
    loadSettings,
    loadingSettings,
    settings,
    updateSettings,
    updatingSettings,
  } = useContext(CompanySettingsStore);

  useEffect(() => {
    loadSettings(company.id);
  }, []);

  return (
    <ZeptoPaper>
      <Grid container spacing={2}>
        {loadingSettings && (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        )}
        {!loadingSettings && settings && (
          <Grid item xs={12}>
            <SettingsForm
              settings={settings}
              onSave={updateSettings}
              loading={updatingSettings}
            />
          </Grid>
        )}
      </Grid>
    </ZeptoPaper>
  );
});

CompanySettings.propTypes = {};

export default CompanySettings;
