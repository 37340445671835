import { createContext } from 'react';
import moment from 'moment';
import { decorate, observable } from 'mobx';
import { jsonFetch } from '../utils/http';
import { appStore } from './AppStore';
import { dateFormat } from '../utils/Constants';

const apiPath = employerId =>
  `${process.env.REACT_APP_API_PATH}/api/users/${employerId}/workDays`;

class WorkDayStore {
  balance;

  loadingBalance;

  clear = () => {
    this.balance = null;
  };

  loadBalance = async employerId => {
    this.loadingBalance = true;
    const currDate = moment().format(dateFormat);
    try {
      const result = await jsonFetch(
        `${apiPath(employerId)}/${currDate}/balance`
      );

      this.balance = result.balance;
    } catch (e) {
      appStore.showError(`Ocorreu um erro ao saldo: ${e.message}`);
    }
    this.loadingBalance = false;
  };
}

decorate(WorkDayStore, {
  balance: observable,
  loadingBalance: observable,
});

export const workDayStore = new WorkDayStore();

export default createContext(workDayStore);
