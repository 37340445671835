import React, { memo, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import AppStore from '../stores/AppStore';
import AppMenu from './menu/AppMenu';
import { loadAuthenticatedUser } from '../operators/authOperator';
import { loadCompany } from '../operators/companiesOperator';
import UserPhotoValidator from './UserPhotoValidator';

const Main = memo(props => {
  const {
    children,
    loadAuthenticatedUser,
    authenticatedUser,
    selectedCompany,
    loadCompany,
  } = props;
  const { showError } = useContext(AppStore);

  function onMobileError(error) {
    showError(error);
  }

  useEffect(() => {
    if (
      authenticatedUser &&
      ['EMPLOYER', 'AUDITOR'].includes(authenticatedUser.role) &&
      !selectedCompany
    )
      loadCompany(authenticatedUser.companyId);
  }, [authenticatedUser]);

  useEffect(() => {
    loadAuthenticatedUser();
    window.onMobileError = error => onMobileError(error);
  }, []);

  if (!authenticatedUser) return <LinearProgress />;

  return (
    <UserPhotoValidator user={authenticatedUser}>
      <AppMenu>{children}</AppMenu>
    </UserPhotoValidator>
  );
});

Main.propTypes = {
  children: PropTypes.node.isRequired,
  loadAuthenticatedUser: PropTypes.func.isRequired,
  authenticatedUser: PropTypes.shape({}),
};
const mapStateToProps = store => ({
  authenticatedUser: store.auth.authenticatedUser,
  selectedCompany: store.companies.selectedCompany,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadAuthenticatedUser,
      loadCompany,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Main);
