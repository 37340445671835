import moment from 'moment';
import { Creators as WorkDayActions } from '../ducks/workDays';
import { jsonFetch, jsonPost } from '../utils/http';

import { Creators as AppActions } from '../ducks/app';

const apiPath = user =>
  `${process.env.REACT_APP_API_PATH}/api/users/${user.id}/workDays`;

const apiPathWorkDay = () =>
  `${process.env.REACT_APP_API_PATH}/api/users/userWorkDay`;

export default function loadWorkDay(user, date = moment()) {
  return dispatch => {
    dispatch(WorkDayActions.changeDate(date.toDate()));
    dispatch(WorkDayActions.loadWorkDayRequest());

    return jsonFetch(`${apiPath(user)}/${date.format('YYYY-MM-DD')}`)
      .then(json => {
        dispatch(WorkDayActions.loadWorkDaySuccess(json));
      })
      .catch(error => {
        dispatch(WorkDayActions.loadWorkDayFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao carregar os pontos: ${error.message}`
          )
        );
      });
  };
}

export const loadWorkdayData = async (userIds, start, end) => {
  try {
    const response = await jsonPost(`${apiPathWorkDay()}`, { ids: userIds, start: moment(start).format('YYYY-MM-DD'), end: moment(end).format('YYYY-MM-DD') })
    return response.users
  } catch (error) {
    AppActions.showError(
      `Ocorreu um erro ao carregar os pontos: ${error.message}`
    )
  }
};