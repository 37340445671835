import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PointDialog from './PointDialog';
import WorkDay from './WorkDay';

const PointsViewer = props => {
  const [state, setState] = useState({});
  const { selected, showDialog } = state;

  function onPointClick(point) {
    if (point.enabled) {
      setState({ ...state, selected: point, showDialog: true });
    }
  }

  function hidePointDialog() {
    setState({ ...state, showDialog: false });
  }

  const { workDay, onDisablePoint } = props;
  return (
    <>
      <WorkDay workDay={workDay} onPointClick={onPointClick} />
      {!!selected && (
        <PointDialog
          open={showDialog}
          point={selected}
          onClose={hidePointDialog}
          onDisable={onDisablePoint}
        />
      )}
    </>
  );
};

PointsViewer.propTypes = {
  workDay: PropTypes.shape({}).isRequired,
  onDisablePoint: PropTypes.func,
};

export default PointsViewer;
