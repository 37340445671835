export const abreviate = (text, size = 50) => {
  if (!text) return text;
  return text.length > size ? `${text.substring(0, size)}...` : text;
};

export const charBySize = (char, size) => {
  let result = '';
  [...Array(size).keys()].forEach(() => {
    result += char;
  });
  return result;
};

export const padAtStart = (text, charToAdd, limit) => {
  if (limit.length >= limit) return text;

  const sizeToAdd = limit - String(text).length;
  return charBySize(charToAdd, sizeToAdd) + text;
};

export const stripCNPJ = cnpj => {
  return (cnpj || '').toString().replace(/[-/.]/g, '');
};

export const formatMoney = value => {
  return value.toFixed(2).replace('.', ',');
};
