import { createActions, createReducer } from 'reduxsauce';

/* #region Action types & creators */
export const { Types, Creators } = createActions({
  changeSelected: ['request'],

  createRequestRequest: [null],
  createRequestSuccess: [null],
  createRequestFailure: ['error'],

  approveRequestRequest: [null],
  approveRequestSuccess: ['request'],
  approveRequestFailure: [null],

  rejectRequestRequest: [null],
  rejectRequestSuccess: ['request'],
  rejectRequestFailure: [null],

  loadRequestsRequest: null,
  loadRequestsSuccess: ['requests'],
  loadRequestsFailure: null,
});
/* #endregion */

/* #region Initial State */
const INITIAL_STATE = {};
/* #endregion */

/* #region change date */

const changeSelected = (state = INITIAL_STATE, action) => ({
  ...state,
  selected: action.request,
  requests: [],
});

/* #endregion */

/* #region Create User */

const createRequestRequest = (state = INITIAL_STATE) => ({
  ...state,
  processingCreateRequest: true,
  createRequestErrorMessage: '',
});

const createRequestSuccess = (state = INITIAL_STATE) => ({
  ...state,
  processingCreateRequest: false,
  createRequestErrorMessage: '',
});

const createRequestFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  processingCreateRequest: false,
  createRequestErrorMessage: action.error,
});

/* #endregion */

/* #region Create User */

const approveRequestRequest = (state = INITIAL_STATE) => ({
  ...state,
  processingApproveRequest: true,
});

const approveRequestSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  processingApproveRequest: false,
  requests: state.requests.filter(r => r.id !== action.request.id),
});

const approveRequestFailure = (state = INITIAL_STATE) => ({
  ...state,
  processingApproveRequest: false,
});

/* #endregion */

/* #region Create User */

const rejectRequestRequest = (state = INITIAL_STATE) => ({
  ...state,
  processingRejectRequest: true,
});

const rejectRequestSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  processingRejectRequest: false,
  requests: state.requests.filter(r => r.id !== action.request.id),
});

const rejectRequestFailure = (state = INITIAL_STATE) => ({
  ...state,
  processingRejectRequest: false,
});

/* #endregion */

/* #region Create User */

const loadRequestsRequest = (state = INITIAL_STATE) => ({
  ...state,
  processingLoadRequests: true,
  loadRequestsErrorMessage: '',
});

const laodRequestsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  processingLoadRequests: false,
  loadRequestsErrorMessage: '',
  requests: action.requests,
});

const loadRequestsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  processingLoadRequests: false,
  loadRequestsErrorMessage: action.error,
});

/* #endregion */

export default createReducer(INITIAL_STATE, {
  [Types.CHANGE_SELECTED]: changeSelected,

  [Types.CREATE_REQUEST_REQUEST]: createRequestRequest,
  [Types.CREATE_REQUEST_SUCCESS]: createRequestSuccess,
  [Types.CREATE_REQUEST_FAILURE]: createRequestFailure,

  [Types.APPROVE_REQUEST_REQUEST]: approveRequestRequest,
  [Types.APPROVE_REQUEST_SUCCESS]: approveRequestSuccess,
  [Types.APPROVE_REQUEST_FAILURE]: approveRequestFailure,

  [Types.REJECT_REQUEST_REQUEST]: rejectRequestRequest,
  [Types.REJECT_REQUEST_SUCCESS]: rejectRequestSuccess,
  [Types.REJECT_REQUEST_FAILURE]: rejectRequestFailure,

  [Types.LOAD_REQUESTS_REQUEST]: loadRequestsRequest,
  [Types.LOAD_REQUESTS_SUCCESS]: laodRequestsSuccess,
  [Types.LOAD_REQUESTS_FAILURE]: loadRequestsFailure,
});
