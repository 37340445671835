import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, LinearProgress } from '@material-ui/core';
import {
  ZeptoPositionPicker,
  ZeptoButton,
  ZeptoCheckbox,
} from '../../../components';

const SettingsForm = memo(props => {
  const { settings: original, onSave, loading } = props;

  const [settings, setSettings] = useState(undefined);

  useEffect(() => {
    setSettings({ ...original });
  }, [original]);

  function onPositionChange(newPosition) {
    setSettings({
      ...settings,
      ...newPosition,
    });
  }

  function handleCheckboxChange(event) {
    setSettings({ ...settings, [event.target.name]: event.target.checked });
  }

  function onSaveClick() {
    onSave(settings);
  }
  return (
    <>
      {settings && (
        <Grid container spacing={2}>
          {loading && (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          )}

          <Grid item xs={12}>
            <ZeptoCheckbox
              name="validatePhoto"
              checked={settings.validatePhoto}
              onChange={handleCheckboxChange}
              label="Validar foto do funcionário ao bater ponto."
            />
          </Grid>

          <Grid item xs={12}>
            <ZeptoCheckbox
              name="validatePosition"
              checked={settings.validatePosition}
              onChange={handleCheckboxChange}
              label="Validar geolocalização do funcionáro ao bater ponto."
            />
          </Grid>
          {settings.validatePosition && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Área permitida para registro de pontos:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ZeptoPositionPicker
                  position={{
                    latitude: settings.latitude,
                    longitude: settings.longitude,
                    radius: settings.radius,
                  }}
                  onChange={onPositionChange}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Grid container justify="flex-end">
              <Grid item>
                <ZeptoButton
                  name="type"
                  label="Salvar"
                  loading={loading}
                  onClick={onSaveClick}
                />
              </Grid>
            </Grid>
          </Grid>
          {loading && (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
});

SettingsForm.propTypes = {
  settings: PropTypes.shape({}).isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default SettingsForm;
