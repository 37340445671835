import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, MenuItem, LinearProgress } from '@material-ui/core';
import moment from 'moment';
import {
  ZeptoSelect,
  ZeptoButton,
  ZeptoDatePicker,
  ZeptoTextField,
  ZeptoPaper,
} from '../../../components';
import useValidator from '../../../hooks/useValidator';
import {
  AllowanceType,
  AllowanceCategories,
  other,
  dayOff,
} from '../../../model/Allowance';
import { dateFormat, timeFormat } from '../../../utils/Constants';
import ZeptoTimePicker from '../../../components/ZeptoTimePicker';
import { createAllowance } from '../../../operators/allowancesOperator';
import { showWarn } from '../../../operators/appOperator';

const RULES = {
  startDate: 'required|validDate',
  endDate: 'required|validDate',
  category: 'required',
  type: ['required', { in: [AllowanceType.date, AllowanceType.time] }],
  time: [
    { required_if: ['type', AllowanceType.time] },
    'time',
    'timeBiggerThanZero',
  ],
  comment: [{ required_if: ['category', other.value] }],
};

const customNames = {
  type: 'Tipo',
  time: 'Horas a abonar',
  comment: 'Justificativa',
};

const AllowanceForm = props => {
  const [data, setData] = React.useState({
    startDate: new Date(),
    endDate: new Date(),
    type: AllowanceType.date,
    category: dayOff.value,
    time: null,
  });

  const [userId, setUserId] = useState(undefined);

  const { params, location, processingcreateAllowance, showWarn } = props;

  useEffect(() => {
    let date = new Date();
    if (location.query.date) {
      const aux = moment(location.query.date, dateFormat);

      if (aux.isValid()) {
        date = aux.toDate();
      }
    }
    if (params.id) {
      if (Number.isNaN(params.id)) {
        showWarn('Usuário inválido.');
      } else {
        setUserId(Number(params.id));
      }
    }

    setData({ ...data, startDate: date, endDate: date });
  }, [params, location]);

  const handleChange = name => event => {
    setData({ ...data, [name]: event.target.value });
  };

  const handleDateChange = name => newDate => {
    setData({ ...data, [name]: newDate.toDate() });
  };

  const handleTimeChange = newTime => {
    setData({ ...data, time: newTime });
  };

  const [isValid, getError] = useValidator(data, RULES, customNames);

  const onSaveClick = () => {
    const { createAllowance } = props;

    if (isValid()) {
      let time;

      if (data.type === AllowanceType.time) {
        time = moment(data.time).format(timeFormat);
      }

      const newAllowance = {
        ...data,
        time,
        startDate: moment(data.startDate).format(dateFormat),
        endDate: moment(data.endDate).format(dateFormat),
      };
      createAllowance(userId, newAllowance);
    }
  };

  return (
    <ZeptoPaper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {processingcreateAllowance && <LinearProgress />}
        </Grid>
        <Grid item xs={6}>
          <ZeptoDatePicker
            label="Início"
            value={data.startDate}
            onChange={handleDateChange('startDate')}
          />
        </Grid>

        <Grid item xs={6}>
          <ZeptoDatePicker
            label="Fim"
            value={data.endDate}
            onChange={handleDateChange('endDate')}
          />
        </Grid>

        <Grid item xs={12}>
          <ZeptoSelect
            label="Tipo"
            value={data.category}
            onChange={handleChange('category')}
            errorMessage={getError('category')}
          >
            {AllowanceCategories.map(cat => (
              <MenuItem key={cat.value} value={cat.value}>
                {cat.label}
              </MenuItem>
            ))}
          </ZeptoSelect>
        </Grid>

        <Grid item xs={12}>
          <ZeptoSelect
            label="Abonar"
            value={data.type}
            onChange={handleChange('type')}
          >
            <MenuItem value={AllowanceType.date}>Dia inteiro</MenuItem>
            <MenuItem value={AllowanceType.time}>Horas</MenuItem>
          </ZeptoSelect>
        </Grid>

        {data.type === AllowanceType.time && (
          <Grid item xs={12}>
            <ZeptoTimePicker
              label="Horas a abonar"
              value={data.time}
              onChange={handleTimeChange}
              errorMessage={getError('time')}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <ZeptoTextField
            label="Justificativa"
            value={data.comment}
            onChange={handleChange('comment')}
            errorMessage={getError('comment')}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item>
              <ZeptoButton
                name="type"
                label="Salvar"
                value={data.time}
                loading={processingcreateAllowance}
                onClick={onSaveClick}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {processingcreateAllowance && <LinearProgress />}
        </Grid>
      </Grid>
    </ZeptoPaper>
  );
};

AllowanceForm.propTypes = {
  params: PropTypes.shape({}),
  createAllowance: PropTypes.func.isRequired,
  showWarn: PropTypes.func.isRequired,
  processingcreateAllowance: PropTypes.bool,
};

const mapStateToProps = state => ({
  processingcreateAllowance: state.allowances.processingCreateAllowance,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createAllowance,
      showWarn,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AllowanceForm);
