import React, { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Grid, LinearProgress } from '@material-ui/core';
import StepperButtonBar from '../../components/StepperButtonBar';
import useValidator from '../../hooks/useValidator';
import CompanyInput from '../companies/CompanyInput';
import { checkCNPJ, clearCNPJCheck } from '../../operators/signupOperator';

const initialComp = { cnpj: '' };

const RULES = {
  businnesName: 'required|min:1|max:255',
  commercialName: 'required|min:1|max:255',
  cnpj: 'required|cnpj',
  address: 'required|min:1|max:255',
};

const CompanyStep = memo(props => {
  const { company, clearCNPJCheck } = props;
  const [companyDraft, setCompanyDraft] = useState(initialComp);
  const { onNext, cnpjCheck, loadingcheckCNPJ } = props;

  const [isValid, errors] = useValidator(companyDraft, RULES);

  function onNextClick() {
    if (isValid()) {
      const { checkCNPJ } = props;
      checkCNPJ(companyDraft.cnpj);
    }
  }

  useEffect(() => {
    if (cnpjCheck && cnpjCheck.exists === false) {
      onNext(companyDraft);
    }

    return clearCNPJCheck;
  }, [cnpjCheck]);

  useEffect(() => {
    if (company) setCompanyDraft({ ...company });
  }, [company]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CompanyInput
          company={companyDraft}
          errors={errors}
          onChange={setCompanyDraft}
        />
      </Grid>

      {loadingcheckCNPJ && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}

      <Grid item xs={12}>
        <StepperButtonBar
          backEnabled={false}
          nextEnable={isValid}
          onNext={onNextClick}
          loading={loadingcheckCNPJ}
        />
      </Grid>
    </Grid>
  );
});

CompanyStep.propTypes = {
  onNext: PropTypes.func.isRequired,
  checkCNPJ: PropTypes.func.isRequired,
  loadingcheckCNPJ: PropTypes.bool,
  cnpjCheck: PropTypes.shape({
    exists: PropTypes.bool,
  }),
};

const mapStateToProps = state => ({
  loadingcheckCNPJ: state.signup.loadingcheckCNPJ,
  cnpjCheck: state.signup.cnpjCheck,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      checkCNPJ,
      clearCNPJCheck,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyStep);
