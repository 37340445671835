import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Grid, TableCell, Typography, Fab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ClosureSearch from './ClosureSearch';
import { ZeptoPaper, ZeptoList, ZeptoConfirmDialog } from '../../../components';
import UserClosureStore from '../../../stores/UserClosureStore';
import CompanyStore from '../../../stores/CompanyStore';
import { toDate } from '../../../utils/DateUtils';

function CompanyClosures() {
  const {
    closures,
    loadingClosures,
    list,
    disable,
    generate,
    lastDisable,
    disablingClosures,
    generatingClosures,
  } = useContext(UserClosureStore);
  const { company } = useContext(CompanyStore);

  const [params, setParams] = useState({ page: 0, pageSize: 5 });
  const [selected, setSelected] = useState([]);
  const [confirmOptions, setConfirmOptions] = React.useState({
    open: false,
  });

  function onSearch(newParams) {
    setParams({
      ...params,
      ...newParams,
      page: 0,
    });
  }

  async function onGenerate(newParams) {
    await generate(company.id, newParams);
    setParams({
      ...params,
      ...newParams,
      page: 0,
    });
  }

  function onChangePage(newParams) {
    setParams({
      ...params,
      ...newParams,
    });
  }

  function onSelectionChange(selected) {
    setSelected(selected);
  }

  function closeConfirmation() {
    setConfirmOptions({ open: false });
  }

  useEffect(() => {
    if (params.employerIds && params.employerIds.length > 0)
      list(company.id, params);
  }, [params, lastDisable]);

  useEffect(() => {
    setSelected([]);
  }, [closures]);

  function onDeleteClosuresConfirm() {
    closeConfirmation();
    disable(
      company.id,
      selected.map(closure => closure.id)
    );
  }

  function deleteClosures() {
    setConfirmOptions({
      open: true,
      onConfirm: onDeleteClosuresConfirm,
      content: `Deseja realmente cancelar os fechamentos selecionados?`,
    });
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ZeptoPaper>
            <ClosureSearch
              onSearch={onSearch}
              onGenerate={onGenerate}
              loading={loadingClosures || generatingClosures}
            />
          </ZeptoPaper>
        </Grid>

        <Grid item xs={12}>
          <ZeptoPaper>
            <ZeptoList
              title={
                <Grid container>
                  <Grid item xs={11}>
                    <Typography>Fechamentos</Typography>
                  </Grid>

                  <Grid item xs={1}>
                    <Fab
                      onClick={deleteClosures}
                      size="small"
                      color="secondary"
                      disabled={selected.length === 0 || disablingClosures}
                    >
                      <DeleteIcon />
                    </Fab>
                  </Grid>
                </Grid>
              }
              onChangePage={onChangePage}
              headCells={
                <>
                  <TableCell align="left">Funcionário</TableCell>
                  <TableCell align="left">Data de início</TableCell>
                  <TableCell align="left">Data de Fim</TableCell>
                </>
              }
              renderBodyCells={row => (
                <>
                  <TableCell align="left">{row.employer.name}</TableCell>
                  <TableCell align="left">{toDate(row.startDate)}</TableCell>
                  <TableCell align="left">{toDate(row.endDate)}</TableCell>
                </>
              )}
              results={closures}
              loading={loadingClosures}
              selected={selected}
              onSelectionChange={onSelectionChange}
            />
          </ZeptoPaper>
        </Grid>
      </Grid>
      <ZeptoConfirmDialog
        open={confirmOptions.open}
        onConfirm={confirmOptions.onConfirm}
        onCancel={closeConfirmation}
        content={confirmOptions.content}
      />
    </>
  );
}

CompanyClosures.propTypes = {};

export default observer(CompanyClosures);
