import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Grid,
  Typography,
  Fab,
  makeStyles,
  MenuItem,
  Hidden,
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import PeriodInput from './PeriodInput';
import { ZeptoBorder, ZeptoSelect } from '../../../../components';
import { timeFormat } from '../../../../utils/Constants';
import { getDayLabel } from '../../../../utils/JourneyUtils';

const byIndex = (p1, p2) => p1.index - p2.index;

const workDayType = { id: 'WORK_DAY', label: 'Dia de trabalho' };
const dayOffType = { id: 'DAY_OFF', label: 'Folga' };
const dayTypes = [workDayType, dayOffType];

const getWorkDayPeriods = () => [
  {
    index: 0,
    start: moment('09:00', timeFormat),
    end: moment('12:00', timeFormat),
  },
  {
    index: 1,
    start: moment('13:00', timeFormat),
    end: moment('18:00', timeFormat),
  },
];

const useStyles = makeStyles({
  fab: {
    float: 'right',
  },
});

const JourneyDayInput = memo(props => {
  const {
    value,
    onChange,
    onRemove,
    disabled,
    readOnly,
    errors,
    path,
    journeyType,
    allowCustomDays,
  } = props;
  const classes = useStyles();

  function handlePeriodChange(newPeriod) {
    const index = value.periods.findIndex(d => d.index === newPeriod.index);

    const newPeriods = value.periods.slice();
    newPeriods.splice(index, 1, newPeriod);

    onChange({ ...value, periods: newPeriods });
  }

  function handleTypeChange(e) {
    const newType = e.target.value;
    let periodsToAssign = [];

    if (newType === workDayType.id) {
      periodsToAssign = getWorkDayPeriods();
    }
    onChange({ ...value, type: newType, periods: periodsToAssign });
  }

  const typeSelect = (
    <ZeptoSelect
      required
      label="Tipo"
      value={value.type}
      onChange={handleTypeChange}
      disabled={disabled || readOnly}
    >
      {dayTypes.map(r => (
        <MenuItem key={r.id} value={r.id}>
          {r.label}
        </MenuItem>
      ))}
    </ZeptoSelect>
  );

  return (
    <ZeptoBorder>
      <Grid
        container
        spacing={1}
        alignItems="center"
        direction="row"
        justify="space-between"
      >
        <Grid item xs={6}>
          <Typography>{getDayLabel(value.index, journeyType)}</Typography>
        </Grid>

        <Grid item xs={6}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            direction="row"
            justify="flex-end"
          >
            <Hidden xsDown>
              <Grid item xs={8}>
                {typeSelect}
              </Grid>
            </Hidden>
            {allowCustomDays && (
              <Grid item xs={4}>
                <Fab
                  size="small"
                  color="secondary"
                  className={classes.fab}
                  onClick={() => onRemove(value)}
                  disabled={disabled}
                >
                  <RemoveIcon />
                </Fab>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Hidden smUp>
          <Grid item xs={12}>
            {typeSelect}
          </Grid>
        </Hidden>

        <Grid item xs={12}>
          {value && (
            <Grid
              container
              spacing={1}
              alignItems="center"
              direction="row"
              justify="space-between"
            >
              {value.periods
                .slice()
                .sort(byIndex)
                .map(period => (
                  <Grid item xs={12} key={period.index}>
                    <PeriodInput
                      value={period}
                      onChange={handlePeriodChange}
                      disabled={disabled || readOnly}
                      errors={errors}
                      path={`${path}.periods.${period.index}`}
                    />
                  </Grid>
                ))}
            </Grid>
          )}
        </Grid>
      </Grid>
    </ZeptoBorder>
  );
});

JourneyDayInput.propTypes = {
  value: PropTypes.shape({}),
  onChange: PropTypes.func,
};

export default JourneyDayInput;
