import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import { TableCell, Fab } from '@material-ui/core';
import { dateFormat } from '../../../utils/Constants';
import { toDate } from '../../../utils/DateUtils';
import { ZeptoTable } from '../../../components';
import { getCategoryDescription } from '../../../model/Allowance';

const sort = (a, b) =>
  moment(a.startDate, dateFormat).diff(moment(b.startDate, dateFormat));

const AllowancesList = props => {
  const { allowances, onDelete, readOnly, renderSubTitle } = props;

  function onDeleteWS(ws) {
    return () => onDelete(ws);
  }

  function renderCells(allowance) {
    return (
      <Fragment key={allowance.id}>
        <TableCell>{getCategoryDescription(allowance.category)}</TableCell>
        <TableCell>{allowance.comment}</TableCell>
        <TableCell>{toDate(allowance.startDate)}</TableCell>
        <TableCell>{toDate(allowance.endDate)}</TableCell>
        <TableCell align="right">
          {!readOnly && (
            <Fab onClick={onDeleteWS(allowance)} size="small" color="secondary">
              <DeleteIcon />
            </Fab>
          )}
        </TableCell>
      </Fragment>
    );
  }

  const sorted = allowances.sort(sort);

  return (
    <ZeptoTable
      title="Abonos do funcionário"
      headRows={[
        {
          id: 'allowance',
          label: 'Abono',
        },
        {
          id: 'reason',
          label: 'Justificativa',
        },
        {
          id: 'start',
          label: 'Início',
        },
        {
          id: 'end',
          label: 'Fim',
        },
        {
          id: 'actions',
          label: '',
        },
      ]}
      rows={sorted}
      renderCells={renderCells}
      renderSubTitle={renderSubTitle}
    />
  );
};

AllowancesList.propTypes = {
  allowances: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDelete: PropTypes.func.isRequired,
  renderSubTitle: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default memo(AllowancesList);
