import { createContext } from 'react';
import { decorate, observable } from 'mobx';
import { jsonFetch, jsonPut } from '../utils/http';
import { appStore } from './AppStore';
import SubscriptionStatus from '../model/SubscriptionStatus';

const apiPath = companyId =>
  `${process.env.REACT_APP_API_PATH}/api/companies/${companyId}/subscription`;

class SubscriptionStore {
  subscription;

  loadingSubscription;

  loadSubscription = async companyId => {
    this.loadingSubscription = true;
    try {
      this.subscription = await jsonFetch(apiPath(companyId));
    } catch (e) {
      appStore.showError(
        `Ocorreu um erro ao carregar os dados de pagamento: ${e.message}`
      );
    }
    this.loadingSubscription = false;
  };

  cancelSubscription = async companyId => {
    this.cancelingSubscription = true;

    try {
      await jsonPut(`${apiPath(companyId)}/cancel`);

      if (this.subscription) {
        this.subscription.status = SubscriptionStatus.canceled;
      }
    } catch (e) {
      appStore.showError(
        `Ocorreu um erro ao cancelar a assinatura: ${e.message}`
      );
    }

    this.cancelingSubscription = false;
  };
}

decorate(SubscriptionStore, {
  subscription: observable,
  loadingSubscription: observable,
  cancelingSubscription: observable,
});

export const subscriptionStore = new SubscriptionStore();

export default createContext(subscriptionStore);
