import React, { memo } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    width: '100%',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ZeptoFab = memo(props => {
  const classes = useStyles();
  const { loading, onClick, color, success, icon, disabled, className } = props;

  const buttonClassname = clsx(
    {
      [classes.buttonSuccess]: success,
    },
    className
  );

  return (
    <div className={classes.wrapper}>
      <Fab
        size="small"
        color={color}
        className={buttonClassname}
        onClick={onClick}
        disabled={loading || disabled}
      >
        {icon}
        {!icon && success && <CheckIcon />}
        {!icon && !success && <SaveIcon />}
      </Fab>
      {loading && (
        <CircularProgress size={52} className={classes.fabProgress} />
      )}
    </div>
  );
});

ZeptoFab.defaultProps = {
  color: 'primary',
};
ZeptoFab.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.instanceOf(Object),
};

export default ZeptoFab;
