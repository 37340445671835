import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Snackbar, IconButton, SnackbarContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { amber, green } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import { Creators as AppActions } from '../ducks/app';
import AppStore from '../stores/AppStore';
import { observer } from 'mobx-react';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    color: 'white',
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
  },
}));

function SnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

SnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

const ZeptoSnackbar = props => {
  const appStore = useContext(AppStore);

  // #region Cola super feia que deve ser retirada na migracao redux -> mobx
  const { hideMessage, alertMessage: duckMessage } = props;
  const { alertMessage: storeMessage } = appStore;

  const [message, setMessage] = useState(undefined);

  useEffect(() => {
    setMessage(duckMessage);
  }, [duckMessage]);

  useEffect(() => {
    setMessage(storeMessage);
  }, [storeMessage]);

  function closeMessage() {
    hideMessage();
    appStore.hideMessage();
  }

  // #endregion Fim da cola

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={!!message}
      autoHideDuration={message && message.variant === 'error' ? null : 6000}
      onClose={closeMessage}
    >
      {message && (
        <SnackbarContentWrapper
          onClose={closeMessage}
          variant={message.variant}
          message={message.text}
        />
      )}
    </Snackbar>
  );
};

const mapStateToProps = store => ({
  alertMessage: store.app.alertMessage,
});

const mapDispatchToProps = dispatch => bindActionCreators(AppActions, dispatch);

ZeptoSnackbar.propTypes = {
  alertMessage: PropTypes.shape({}),
  hideMessage: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(observer(ZeptoSnackbar));
