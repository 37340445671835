import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import './review.css';

const ZeptoReCaptcha = memo(props => {
  const { onValidate, onExpired } = props;
  return (
    <div className="g-recaptcha">
      <ReCAPTCHA
        sitekey="6LdCzOEUAAAAAMcUDLXRe3qbzgyM9AODa8j8oird"
        onChange={onValidate}
        onExpired={onExpired}
      />
    </div>
  );
});

ZeptoReCaptcha.propTypes = {
  onValidate: PropTypes.func.isRequired,
  onExpired: PropTypes.func.isRequired,
};

export default ZeptoReCaptcha;
