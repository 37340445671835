import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { TableCell, Chip } from '@material-ui/core';
import { ZeptoTable } from '../../../../components';

import InstallmentStatus, {
  labelOf,
} from '../../../../model/InstallmentStatus';
import { toDate } from '../../../../utils/DateUtils';

const colorFromStatus = status => {
  switch (status) {
    case InstallmentStatus.overdue:
    case InstallmentStatus.canceled:
    case InstallmentStatus.refunded:
      return 'secondary';

    case InstallmentStatus.pending:
      return 'primary';
    case InstallmentStatus.trial:
    case InstallmentStatus.paid:
    default:
      return 'default';
  }
};

const InstallmentList = memo(props => {
  const { installments } = props;

  return (
    <ZeptoTable
      title="Pagamentos"
      headRows={[
        {
          id: 'to',
          label: 'Vencimento',
        },
        {
          id: 'status',
          label: 'Status',
        },
      ]}
      rows={installments}
      renderCells={installment => (
        <>
          <TableCell>{toDate(installment.endDate)}</TableCell>
          <TableCell>
            <Chip
              label={labelOf(installment.status)}
              color={colorFromStatus(installment.status)}
            />
          </TableCell>
        </>
      )}
    />
  );
});

InstallmentList.propTypes = {
  installments: PropTypes.arrayOf(PropTypes.shape({})),
};

export default InstallmentList;
