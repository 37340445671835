import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';

const MobileUserPhotoPicker = memo(props => {
  const { onUploadComplete, onCancel, token, userId } = props;

  function onMobileUploadUserPhoto() {
    onUploadComplete();
  }

  function cancel() {
    onCancel();
  }

  function startUpload() {
    window.onMobileUploadUserPhoto = onMobileUploadUserPhoto;
    window.onMobileUploadUserPhotoCancel = cancel;

    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'UPLOAD_USER_PHOTO', token, userId })
    );
  }

  function onUmount() {
    window.onMobileUploadUserPhoto = undefined;
    window.onMobileUploadUserPhotoCancel = undefined;
  }

  useEffect(() => {
    startUpload();
    return onUmount;
  }, []);

  return <div>Carregando...</div>;
});

const mapStateToProps = state => ({
  token: state.auth.token,
});

MobileUserPhotoPicker.propTypes = {
  onUploadComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};
export default connect(
  mapStateToProps,
  null
)(MobileUserPhotoPicker);
