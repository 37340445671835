import { createContext } from 'react';
import { decorate, observable } from 'mobx';
import { jsonFetch, jsonPost, jsonDelete } from '../utils/http';
import { appStore } from './AppStore';

const apiPath = userId =>
  `${process.env.REACT_APP_API_PATH}/api/users/${userId}/journeys`;

class UserJourneyStore {
  userJourneys = [];

  // companyJourney;

  loadingUserJourneys;

  // loadingCompanyJourney;

  savingUserJourney;

  // updatingCompanyJourney;

  deletingUserJourney;

  // enablingCompanyJourney;

  // lastSaved;

  loadUserJourneys = async userId => {
    this.loadingUserJourneys = true;
    try {
      this.userJourneys = await jsonFetch(`${apiPath(userId)}`);
    } catch (e) {
      appStore.showError(
        `Ocorreu um erro ao carregar as jornadas do usuário: ${e.message}`
      );
    }
    this.loadingUserJourneys = false;
  };

  clearUserJourneys = () => {
    this.userJourneys = [];
  };

  // loadCompanyJourney = async (companyId, id) => {
  //   this.loadingCompanyJourney = true;
  //   try {
  //     this.companyJourney = await jsonFetch(`${apiPath(companyId)}/${id}`);
  //   } catch (e) {
  //     appStore.showError(`Ocorreu um erro ao carregar a jornada: ${e.message}`);
  //   }
  //   this.loadingCompanyJourney = false;
  // };

  saveUserJourney = async userJourney => {
    this.savingUserJourney = true;

    try {
      const result = await jsonPost(
        `${apiPath(userJourney.userId)}`,
        userJourney
      );

      if (this.userJourneys) {
        this.userJourneys.push(result);
      }

      appStore.showSuccess('Jornada cadastrada com sucesso.');
    } catch (e) {
      appStore.showError(
        `Ocorreu um erro ao cadastrar a jornada: ${e.message}`
      );
    }

    this.savingUserJourney = false;
  };

  // update = async companyJourney => {
  //   this.updatingCompanyJourney = true;

  //   try {
  //     this.lastSaved = await jsonPut(
  //       `${apiPath(companyJourney.companyId)}/${companyJourney.id}`,
  //       companyJourney
  //     );

  //     appStore.showSuccess('Jornada atualizada com sucesso.');

  //     if (this.companyJourneys) {
  //       const index = this.companyJourneys.findIndex(
  //         h => h.id === this.lastSaved.id
  //       );

  //       if (index !== -1) {
  //         this.companyJourneys.splice(index, 1, this.lastSaved);
  //       }
  //     }
  //   } catch (e) {
  //     appStore.showError(
  //       `Ocorreu um erro ao atualizar a jornada: ${e.message}`
  //     );
  //   }

  //   this.updatingCompanyJourney = false;
  // };

  deleteUserJourney = async userJourney => {
    this.deletingUserJourney = true;

    try {
      await jsonDelete(`${apiPath(userJourney.userId)}/${userJourney.id}`);

      if (this.userJourneys) {
        this.userJourneys = this.userJourneys.filter(
          uj => uj.id !== userJourney.id
        );

        appStore.showSuccess('Jornada apagada com sucesso');
      }
    } catch (e) {
      appStore.showError(`Ocorreu um erro ao apagar a jornada: ${e.message}`);
    }

    this.deletingUserJourney = false;
  };

  // enable = async companyJourney => {
  //   this.enablingCompanyJourney = true;

  //   try {
  //     await jsonPut(
  //       `${apiPath(companyJourney.companyId)}/${companyJourney.id}/enable`
  //     );

  //     if (this.companyJourneys) {
  //       const enabled = this.companyJourneys.find(
  //         compJourney => compJourney.id === companyJourney.id
  //       );
  //       if (enabled) enabled.enabled = true;
  //       appStore.showSuccess('Jornada restaurada com sucesso');
  //     }
  //   } catch (e) {
  //     appStore.showError(
  //       `Ocorreu um erro ao restaurar a jornada: ${e.message}`
  //     );
  //   }

  //   this.enablingCompanyJourney = false;
  // };

  // clearCompanyJourney = () => {
  //   this.companyJourney = null;
  // };
}

decorate(UserJourneyStore, {
  userJourneys: observable,
  loadingUserJourneys: observable,
  deletingUserJourney: observable,
  savingUserJourney: observable,
});

export const userJourneyStore = new UserJourneyStore();

export default createContext(userJourneyStore);
