import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from '@material-ui/core';
import MaskedInput from 'react-text-mask';

function TextMaskCustom(props) {
  const { inputRef, mask, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  mask: PropTypes.arrayOf(PropTypes.any),
};

const ZeptoMaskedTextField = props => {
  const {
    name,
    autoFocus,
    type,
    label,
    required,
    value,
    onChange,
    onUpdate,
    inputProps,
    errorMessage,
    disabled,
  } = props;

  function onValueChange(event) {
    if (!onUpdate) onChange(event);
    else onUpdate({ name, payload: event.target.value });
  }

  return (
    <FormControl error={!!errorMessage} variant="outlined" fullWidth>
      <InputLabel htmlFor="input" required={required} variant="outlined">
        {label}
      </InputLabel>
      <OutlinedInput
        id="input"
        name={name}
        labelWidth={name.length * 10 + 5}
        value={value || ''}
        onChange={onValueChange}
        aria-describedby="input-text"
        type={type}
        autoFocus={autoFocus}
        inputProps={inputProps}
        inputComponent={TextMaskCustom}
        disabled={disabled}
      />
      {errorMessage && (
        <FormHelperText id="input-text">{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
};

ZeptoMaskedTextField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  inputProps: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

export default memo(ZeptoMaskedTextField);
