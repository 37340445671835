import { createActions, createReducer } from 'reduxsauce';

/* #region Action types & creators */
export const { Types, Creators } = createActions({
  generateAFDRequest: null,
  generateAFDSuccess: ['afd'],
  generateAFDFailure: null,

  generateAFDTRequest: null,
  generateAFDTSuccess: ['afdt'],
  generateAFDTFailure: null,

  generateACJEFRequest: null,
  generateACJEFSuccess: ['ACJEF'],
  generateACJEFFailure: null,

  generateAgreementTermRequest: null,
  generateAgreementTermSuccess: ['agreement'],
  generateAgreementTermFailure: null,
});
/* #endregion */

/* #region Initial State */
const INITIAL_STATE = {};
/* #endregion */

/* #region Generate Report */

const generateAFDRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingAFD: true,
});

const generateAFDSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingAFD: false,
  afd: action.afd,
});

const generateAFDFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingAFD: false,
});

/* #endregion */

/* #region Generate Report */

const generateAFDTRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingAFDT: true,
});

const generateAFDTSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingAFDT: false,
  afdt: action.afdt,
});

const generateAFDTFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingAFDT: false,
});

/* #endregion */

/* #region Generate Report */

const generateACJEFRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingACJEF: true,
});

const generateACJEFSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingACJEF: false,
  afdt: action.afdt,
});

const generateACJEFFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingACJEF: false,
});

/* #endregion */
/* #region Generate Report */

const generateAgreementTermRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingAgreementTerm: true,
});

const generateAgreementTermSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingAgreementTerm: false,
  afdt: action.afdt,
});

const generateAgreementTermFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingAgreementTerm: false,
});

/* #endregion */

export default createReducer(INITIAL_STATE, {
  [Types.GENERATE_AFD_REQUEST]: generateAFDRequest,
  [Types.GENERATE_AFD_SUCCESS]: generateAFDSuccess,
  [Types.GENERATE_AFD_FAILURE]: generateAFDFailure,

  [Types.GENERATE_AFDT_REQUEST]: generateAFDTRequest,
  [Types.GENERATE_AFDT_SUCCESS]: generateAFDTSuccess,
  [Types.GENERATE_AFDT_FAILURE]: generateAFDTFailure,

  [Types.GENERATE_ACJEF_REQUEST]: generateACJEFRequest,
  [Types.GENERATE_ACJEF_SUCCESS]: generateACJEFSuccess,
  [Types.GENERATE_ACJEF_FAILURE]: generateACJEFFailure,

  [Types.GENERATE_AGREEMENT_TERM_REQUEST]: generateAgreementTermRequest,
  [Types.GENERATE_AGREEMENT_TERM_SUCCESS]: generateAgreementTermSuccess,
  [Types.GENERATE_AGREEMENT_TERM_FAILURE]: generateAgreementTermFailure,
});
