import { createActions, createReducer } from 'reduxsauce';

/* #region Action types & creators */
export const { Types, Creators } = createActions({
  saveWorkScheduleRequest: [null],
  saveWorkScheduleSuccess: ['userWorkSchedule'],
  saveWorkScheduleFailure: [null],

  deleteWorkScheduleRequest: [null],
  deleteWorkScheduleSuccess: ['userWorkSchedule'],
  deleteWorkScheduleFailure: [null],
});
/* #endregion */

/* #region Initial State */
const INITIAL_STATE = {};
/* #endregion */

/* #region Create user work schedule */

const saveWorkScheduleRequest = (state = INITIAL_STATE) => ({
  ...state,
  savingWorkSchedule: true,
});

const saveWorkScheduleSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  savingWorkSchedule: false,
  lastSaved: action.userWorkSchedule,
});

const saveWorkScheduleFailure = (state = INITIAL_STATE) => ({
  ...state,
  savingWorkSchedule: false,
});

// /* #endregion */

/* #region Create user work schedule */

const deleteWorkScheduleRequest = (state = INITIAL_STATE) => ({
  ...state,
  deletingWorkSchedule: true,
});

const deleteWorkScheduleSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  deletingWorkSchedule: false,
  lastDeleted: action.userWorkSchedule,
});

const deleteWorkScheduleFailure = (state = INITIAL_STATE) => ({
  ...state,
  deletingWorkSchedule: false,
});

// /* #endregion */

/* #endregion */

export default createReducer(INITIAL_STATE, {
  [Types.SAVE_WORK_SCHEDULE_REQUEST]: saveWorkScheduleRequest,
  [Types.SAVE_WORK_SCHEDULE_SUCCESS]: saveWorkScheduleSuccess,
  [Types.SAVE_WORK_SCHEDULE_FAILURE]: saveWorkScheduleFailure,

  [Types.DELETE_WORK_SCHEDULE_REQUEST]: deleteWorkScheduleRequest,
  [Types.DELETE_WORK_SCHEDULE_SUCCESS]: deleteWorkScheduleSuccess,
  [Types.DELETE_WORK_SCHEDULE_FAILURE]: deleteWorkScheduleFailure,
});
