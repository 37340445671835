import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LinearProgress } from '@material-ui/core';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { ZeptoDialog } from '../components';
import UserPhotoPicker from './users/UserPhotoPicker';

import { onUserUploadPhoto } from '../operators/authOperator';
import CompanySettingsStore from '../stores/CompanySettingsStore';

const UserPhotoValidator = observer(props => {
  const { user, children, clearSettings, onUserUploadPhoto } = props;

  const { settings, loadSettings, loadingSettings } = useContext(
    CompanySettingsStore
  );

  function onUserUploadPhotoComplete() {
    onUserUploadPhoto();
  }

  if (user.role !== 'USER') return children;

  useEffect(() => {
    if (!settings) loadSettings(user.companyId);

    return clearSettings;
  }, [user]);

  if (loadingSettings) return <LinearProgress />;

  const shouldTakePhoto = !user.hasPhoto && settings && settings.validatePhoto;

  if (shouldTakePhoto) {
    return (
      <ZeptoDialog open title="Por favor, tire um auto-retrato.">
        <div style={{ padding: 10 }}>
          <UserPhotoPicker
            user={user}
            onUserUploadPhoto={onUserUploadPhotoComplete}
          />
        </div>
      </ZeptoDialog>
    );
  }

  return children;
});

UserPhotoValidator.propTypes = {
  user: PropTypes.shape({}).isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onUserUploadPhoto,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(UserPhotoValidator);
