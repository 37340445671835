export const AllowanceType = {
  time: 'TIME',
  date: 'DATE',
};

export const license = {
  label: 'Licença',
  value: 'LICENSE',
};

export const other = {
  label: 'Outro',
  value: 'OTHER',
};

export const dayOff = {
  label: 'Folga',
  value: 'DAY_OFF',
};

export const vacation = {
  label: 'Férias',
  value: 'VACATION',
};

export const getCategoryDescription = category => {
  switch (category) {
    case license.value:
      return license.label;

    case other.value:
      return other.label;

    case dayOff.value:
      return dayOff.label;

    case vacation.value:
      return vacation.label;

    default:
      return category;
  }
};

export const AllowanceCategories = [dayOff, license, vacation, other];
