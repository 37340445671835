import React from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Grid, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { editUser } from '../../operators/usersOperator';
import WorkDay from './WorkDay';
import { disablePoint } from '../../operators/pointsOperator';
import loadWorkDay from '../../operators/workDayOperator';
import { showWarn } from '../../operators/appOperator';
import { newRequest } from '../../operators/requestsOperator';
import PointDialog from './PointDialog';
import { RequestStatus } from '../../model/Request';
import { ZeptoPaper } from '../../components';
import { drawerWidth } from '../menu/ResponsiveDrawer';
import PointOptions from './PointOptions';
import { dateFormat } from '../../utils/Constants';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { debounce } from 'lodash';

const useStyles = makeStyles(theme => ({
  fab: {
    // margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  buttonArea: {
    paddingTop: 30,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  addButtonParent: {
    position: 'relative',
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  dateInput: {
    width: 115,
  },
  lastItem: {
    marginBottom: 70,
  },
}));

const DailyPoints = props => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState(moment());
  const [selected, setSelected] = React.useState();
  const [showPointDialog, setShowPointDialog] = React.useState(new Date());

  const {
    loadWorkDay,
    loadingWorkDay,
    workDay,
    date,
    authenticatedUser,
  } = props;

  React.useEffect(() => {
    if (selectedDate) loadWorkDay(authenticatedUser, selectedDate);
  }, [selectedDate]);

  function monitorDate() {
    const currDate = moment();

    if (
      selectedDate &&
      selectedDate.format(dateFormat) !== currDate.format(dateFormat)
    ) {
      setSelectedDate(currDate);
    }
  }

  React.useEffect(() => {
    if (date) setSelectedDate(moment(date));
    const interval = setInterval(monitorDate, 3000);
    return () => clearInterval(interval);
  }, []);

  const onRegisterPointClick = () => {
    const { push } = props;
    push('/me/register');
  };

  const onRequestClick = () => {
    const { newRequest } = props;
    newRequest(date);
  };

  const onchangeDate = newDate => {
    setSelectedDate(newDate);
  };

  const onPointClick = point => {
    if (point.enabled) {
      setSelected(point);
      setShowPointDialog(true);
    }
  };

  const onDisablePoint = point => {
    const { disablePoint, showWarn } = props;
    if (
      point.requests.filter(r => r.status === RequestStatus.open).length > 0
    ) {
      showWarn('Esse ponto possui uma solicitação pendente.');
    } else {
      disablePoint(point);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <KeyboardDatePicker
                format="DD/MM/YYYY"
                value={moment(selectedDate, 'DD/MM/YYYY')}
                onChange={debounce(onchangeDate, 2000)}
                autoOk
                fullWidth
                style={{width: '150px'}}
                disabled={loadingWorkDay}
                className={classes.dateInput}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {!loadingWorkDay && workDay && (
            <ZeptoPaper className={classes.lastItem}>
              <WorkDay workDay={workDay} onPointClick={onPointClick} />
            </ZeptoPaper>
          )}
        </Grid>

        <Grid item xs={12}>
          <PointOptions
            loading={loadingWorkDay}
            onRegisterPointClick={onRegisterPointClick}
            onRequestClick={onRequestClick}
          />
        </Grid>
      </Grid>

      {!!selected && (
        <PointDialog
          open={showPointDialog}
          point={selected}
          onClose={() => setShowPointDialog(false)}
          onDisable={onDisablePoint}
        />
      )}
    </>
  );
};

DailyPoints.propTypes = {
  date: PropTypes.shape({}).isRequired,
  authenticatedUser: PropTypes.shape({}).isRequired,
  workDay: PropTypes.shape({}),
  loadingWorkDay: PropTypes.bool,
  newRequest: PropTypes.func.isRequired,
  loadWorkDay: PropTypes.func.isRequired,
  disablePoint: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  showWarn: PropTypes.func.isRequired,
};

const mapStateToProps = store => ({
  loadingWorkDay: store.workDays.loadingWorkDay,
  workDay: store.workDays.workDay,
  date: store.workDays.date,
  authenticatedUser: store.auth.authenticatedUser,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      push,
      newRequest,
      editUser,
      loadWorkDay,
      disablePoint,
      showWarn,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DailyPoints);
