import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Grid, LinearProgress } from '@material-ui/core';
import {
  loadAllowances,
  deleteAllowance,
} from '../../../operators/allowancesOperator';
import { KeyboardDatePicker } from '@material-ui/pickers';
import AllowancesList from './AllowancesList';
import { ZeptoConfirmDialog } from '../../../components';
import { dateFormat } from '../../../utils/Constants';
import { debounce } from 'lodash';

const UserAllowances = props => {
  const {
    user,
    loadAllowances,
    allowances,
    loadingAllowances,
    deletingAllowance,
    deleteAllowance,
    lastDeleted,
  } = props;

  const [confirmOptions, setConfirmOptions] = React.useState({
    open: false,
  });

  function closeConfirmation() {
    setConfirmOptions({ open: false });
  }

  const [state, setState] = useState({
    start: moment()
      .startOf('month')
      .toDate(),
    end: moment()
      .endOf('month')
      .toDate(),
  });

  function handleDateChange(name) {
    return event => {
      setState({ ...state, [name]: event });
    };
  }

  function onDeleteWorkScheduleConfirm(allowance) {
    deleteAllowance(user.id, allowance);
  }

  function onDelete(allowance) {
    setConfirmOptions({
      open: true,
      onConfirm: () => onDeleteWorkScheduleConfirm(allowance),
      content: 'Deseja realmente excluir o registro selecionado?',
    });
  }

  useEffect(() => {
    closeConfirmation();
  }, [lastDeleted]);

  useEffect(() => {
    const st = moment(state.start).format(dateFormat);
    const nd = moment(state.end).format(dateFormat);
    loadAllowances(user.id, st, nd);
  }, [user, state]);

  return (
    <Grid container>
      {loadingAllowances && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}

      {!loadingAllowances && (
        <Grid item xs={12}>
          <AllowancesList
            renderSubTitle={() => (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <KeyboardDatePicker
                    label="Data de Início"
                    fullWidth
                    format="DD/MM/YYYY"
                    value={moment(state.start, 'DD/MM/YYYY')}
                    onChange={debounce(handleDateChange('start'), 2000)}
                    autoOk
                    style={{width: '150px'}}
                    disabled={loadingAllowances}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <KeyboardDatePicker
                    label="Data de Fim"
                    fullWidth
                    format="DD/MM/YYYY"
                    value={moment(state.end, 'DD/MM/YYYY')}
                    onChange={debounce(handleDateChange('end'), 2000)}
                    autoOk
                    style={{width: '150px', marginLeft: '50px'}}
                    disabled={loadingAllowances}
                  />
                </Grid>
              </Grid>
            )}
            allowances={allowances}
            onDelete={onDelete}
          />
        </Grid>
      )}

      <ZeptoConfirmDialog
        open={confirmOptions.open}
        onConfirm={confirmOptions.onConfirm}
        onCancel={closeConfirmation}
        content={confirmOptions.content}
        loading={deletingAllowance}
      />
    </Grid>
  );
};

UserAllowances.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  loadAllowances: PropTypes.func.isRequired,
  deleteAllowance: PropTypes.func.isRequired,
  loadingAllowances: PropTypes.bool,
  deletingAllowance: PropTypes.bool,
  allowances: PropTypes.arrayOf(PropTypes.shape({})),
  lastDeleted: PropTypes.number,
};

const mapStateToProps = state => ({
  allowances: state.allowances.allowances,
  loadingAllowances: state.allowances.loadingAllowances,
  deletingAllowance: state.allowances.deletingAllowance,
  lastDeleted: state.allowances.lastDeleted,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadAllowances,
      deleteAllowance,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(UserAllowances));
