import moment from 'moment';
import { dateFormat, dateFormatBr, timeFormat } from './Constants';

export const toDate = date => moment(date, dateFormat).format(dateFormatBr);
export const toTime = date => moment(date, dateFormat).format(dateFormatBr);

export const msToTime = s => {
  if (s === undefined) return undefined;
  if (s === 0) return '00:00';
  const ms = s % 1000;
  let newS = (s - ms) / 1000;
  const secs = newS % 60;
  newS = (newS - secs) / 60;
  const mins = newS % 60;
  const hrs = (newS - mins) / 60;

  const signal = s < 0 ? '-' : '';
  const mm = String(Math.abs(hrs)).padStart(2, '0');
  const ss = String(Math.abs(mins)).padStart(2, '0');

  return `${signal}${mm}:${ss}`;
};

export const minutesToTime = (time = 0) => {
  if (time === 0) return '00:00';

  if (!time) return undefined;

  const hours = String(Math.floor(time / 60));
  const minutes = String(time % 60);

  const result = `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;

  return result;
};

export const timeToMinutes = time => {
  if (!time) return null;

  const value = moment(time, timeFormat);

  return 60 * value.hours() + value.minute();
};
