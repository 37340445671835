import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { makeStyles, Paper, Grid, LinearProgress } from '@material-ui/core';

import { bindActionCreators } from 'redux';
import { showError } from '../../operators/appOperator';
import { goTo } from '../../operators/routesOperator';
import {
  validateRedefinePasswordToken,
  clearRedefinePasswordTokenValidation,
  redefinePassword,
} from '../../operators/authOperator';
import { ZeptoButton, ZeptoReCaptcha, ZeptoTextField } from '../../components';
import useValidator from '../../hooks/useValidator';

const useStyles = makeStyles(theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
}));

const RULES = {
  password: 'required|between:6,20|confirmed',
  password_confirmation: 'required',
};

const inputProps = {
  max20: {
    maxLength: 20,
  },
};

const RedefinePassword = memo(props => {
  const classes = useStyles();
  const {
    params,
    redefinePasswordTokenValidation,
    goTo,
    showError,
    validateRedefinePasswordToken,
    clearRedefinePasswordTokenValidation,
    loadingValidateRedefinePasswordToken,
    redefiningPassword,
    redefinePassword,
  } = props;
  const { token } = params;

  const [reCaptcha, setReCaptcha] = useState(false);
  const [data, setData] = useState('');
  const [isValid, errors] = useValidator(data, RULES);

  function handleChange(event) {
    setData({ ...data, [event.target.name]: event.target.value });
  }

  if (!token) {
    goTo('/');
    showError('Token de acesso inválido.');
  }

  useEffect(() => {
    validateRedefinePasswordToken(token);

    return clearRedefinePasswordTokenValidation;
  }, [token]);

  useEffect(() => {
    if (
      redefinePasswordTokenValidation &&
      !redefinePasswordTokenValidation.isValid
    ) {
      goTo('/');
      showError('Token de acesso inválido ou expirado.');
    }
  }, [redefinePasswordTokenValidation]);

  function onRedefineClick() {
    if (isValid()) {
      redefinePassword(token, reCaptcha, data.password);
      setReCaptcha(null);
    }
  }

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        {!redefinePasswordTokenValidation &&
          loadingValidateRedefinePasswordToken && (
            <Grid container>
              <Grid item xs>
                <LinearProgress />
              </Grid>
            </Grid>
          )}
        {redefinePasswordTokenValidation && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ZeptoTextField
                name="password"
                value={data.password}
                label="Senha"
                type="password"
                onChange={handleChange}
                errorMessage={errors('password')}
                inputProps={inputProps.max20}
                autoComplete="off"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <ZeptoTextField
                name="password_confirmation"
                value={data.password_confirmation}
                label="Confirme a senha"
                type="password"
                onChange={handleChange}
                errorMessage={errors('password_confirmation')}
                inputProps={inputProps.max20}
                autoComplete={false}
                required
              />
            </Grid>
            {!redefiningPassword && (
              <Grid item xs={12}>
                <ZeptoReCaptcha
                  onValidate={value => setReCaptcha(value)}
                  onExpired={() => setReCaptcha(null)}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <ZeptoButton
                label="Restaurar"
                onClick={onRedefineClick}
                disabled={!reCaptcha}
                loading={redefiningPassword}
              />
            </Grid>
          </Grid>
        )}
      </Paper>
    </main>
  );
});

RedefinePassword.propTypes = {
  params: PropTypes.shape({
    token: PropTypes.string.isRequired,
  }).isRequired,
  redefinePasswordTokenValidation: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  validateRedefinePasswordToken: PropTypes.func.isRequired,
  clearRedefinePasswordTokenValidation: PropTypes.func.isRequired,
  loadingValidateRedefinePasswordToken: PropTypes.bool,
  redefiningPassword: PropTypes.bool,
  redefinePassword: PropTypes.func.isRequired,
};

const mapStateToProps = store => ({
  loadingValidateRedefinePasswordToken:
    store.auth.loadingValidateRedefinePasswordToken,
  redefinePasswordTokenValidation: store.auth.redefinePasswordTokenValidation,
  redefiningPassword: store.auth.redefiningPassword,
  lastPasswordRedefinition: store.auth.lastPasswordRedefinition,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      validateRedefinePasswordToken,
      clearRedefinePasswordTokenValidation,
      goTo,
      showError,
      redefinePassword,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RedefinePassword);
