import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, makeStyles, Toolbar } from '@material-ui/core';
import PointsViewer from '../../point/PointsViewer';

const useStyles = makeStyles({
  root: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    padding: 0,
  },
});

const UserWorkDay = props => {
  const { userWorkDay } = props;
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h5" className={classes.title}>
            {userWorkDay.user.name}
          </Typography>
        </Toolbar>
      </Grid>

      <Grid item xs={12}>
        <PointsViewer workDay={userWorkDay.workDay} />
      </Grid>
    </Grid>
  );
};

UserWorkDay.propTypes = {
  userWorkDay: PropTypes.shape({
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
    workDay: PropTypes.shape({}),
  }).isRequired,
};

export default UserWorkDay;
