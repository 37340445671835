import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, IconButton, Toolbar, Avatar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import logo from '../../assets/img/logo-white-sm-2.png';
import UserAccountMenu from './UserAccountMenu';
import ThemeSelect from './ThemeSelect';

const useStyles = makeStyles(theme => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      zIndex: theme.zIndex.drawer + 1,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  title: {
    flexGrow: 1,
  },

  logo: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

function AppTopBar(props) {
  const { onMenuClick } = props;
  const theme = useTheme();
  const classes = useStyles();
  const appBarColor = theme.palette.type === 'dark' ? 'default' : 'primary';
  return (
    <AppBar position="fixed" className={classes.appBar} color={appBarColor}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onMenuClick}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>

        <a href="/me">
          <Avatar alt="logo" src={logo} className={classes.logo} />
        </a>
        <div className={classes.title} />

        <ThemeSelect />
        <UserAccountMenu />
      </Toolbar>
    </AppBar>
  );
}

AppTopBar.propTypes = {
  onMenuClick: PropTypes.func.isRequired,
};

export default AppTopBar;
