import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import UserList from '../../users/list/UserList';
import { loadUsers } from '../../../operators/usersOperator';
import { user } from '../../../model/User';
import { ZeptoButton } from '../../../components';
import { dateFormat } from '../../../utils/Constants';
import { KeyboardDatePicker } from '@material-ui/pickers';

const firstDayOfMonth = () => moment().startOf('month');
const lastDayOfMonth = () => moment().endOf('month');

function ClosureSearch(props) {
  const {
    onSearch,
    onGenerate,
    loadUsers,
    users,
    processingLoadUsers,
    company,
    loading,
  } = props;

  const [state, setState] = useState({
    startDate: firstDayOfMonth(),
    endDate: lastDayOfMonth(),
    employerIds: [],
  });

  useEffect(() => {
    if (company) loadUsers(company.id, user, true);
  }, [company]);

  function onChangeDate(field) {
    return date => setState({ ...state, [field]: date });
  }

  function assembleParams() {
    return {
      ...state,
      startDate: state.startDate.format(dateFormat),
      endDate: state.endDate.format(dateFormat),
    };
  }

  function onSearchClick() {
    onSearch(assembleParams());
  }

  function onGenerateClick() {
    onGenerate(assembleParams());
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={6}>
        <KeyboardDatePicker
          label="Data de Início"
          fullWidth
          format="DD/MM/YYYY"
          value={moment(state.startDate, 'DD/MM/YYYY')}
          onChange={onChangeDate('startDate')}
          autoOk
        />
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <KeyboardDatePicker
          label="Data de Fim"
          format="DD/MM/YYYY"
          fullWidth
          value={moment(state.endDate, 'DD/MM/YYYY')}
          onChange={onChangeDate('endDate')}
          autoOk
        />
      </Grid>

      <Grid item xs={12}>
        <UserList
          selectable
          loading={processingLoadUsers}
          onSelectionChange={ids => {
            setState({ ...state, employerIds: ids });
          }}
          users={users}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item xs={12} sm={3}>
            <ZeptoButton
              label="Gerar"
              loading={loading}
              onClick={onGenerateClick}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <ZeptoButton
              label="Pesquisar"
              loading={loading}
              onClick={onSearchClick}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

ClosureSearch.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})),
  company: PropTypes.shape({}),
  processingLoadUsers: PropTypes.bool,
  onSearch: PropTypes.func,
  loadUsers: PropTypes.func,
  loading: PropTypes.bool,
};

const mapStateToProps = state => ({
  users: state.users.users,
  company: state.companies.selected,
  processingLoadUsers: state.users.processingLoadUsers,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadUsers,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(ClosureSearch));
