import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';

function ZeptoDatePicker(props) {
  const {
    label,
    name,
    onChange,
    onUpdate,
    value,
    errorMessage,
    disabled,
    views,
    format,
    className,
  } = props;

  function onValueChange(newDate) {
    const date = moment(newDate, format)
    if (!onUpdate) onChange(date);
    else onUpdate({ name, payload: date });
  }

  return (
    <KeyboardDatePicker
      autoOk
      className={className}
      disabled={disabled}
      ampm={false}
      format={format}
      label={label}
      value={value !== null ? moment(value, format) : null}
      onChange={onValueChange}
      error={!!errorMessage}
      helperText={errorMessage}
      views={views}
      inputVariant="outlined"
      fullWidth
    />
  );
}

ZeptoDatePicker.defaultProps = {
  format: 'DD/MM/YYYY',
};

ZeptoDatePicker.propTypes = {
  format: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onUpdate: PropTypes.string,
  errorMessage: PropTypes.string,
  value: PropTypes.shape({}),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  views: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};

export default memo(ZeptoDatePicker);
