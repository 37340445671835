import React from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';
import ZeptoTextField from './ZeptoTextField';

const ZeptoFormTextField = (props) => {
  const {
    setValue, getValue, getErrorMessage, isPristine,
  } = props;
  const changeValue = (event) => {
    setValue(event.currentTarget.value);
  };
  const errorMessage = isPristine() ? '' : getErrorMessage();
  return (
    <ZeptoTextField
      {...props}
      value={getValue() || ''}
      onChange={changeValue}
      errorMessage={errorMessage}
      error={!!errorMessage}
    />
  );
};

ZeptoFormTextField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  setValue: PropTypes.func,
  isPristine: PropTypes.func,
  getValue: PropTypes.func,
  getErrorMessage: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
};

export default withFormsy(ZeptoFormTextField);
