import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import DailyPoints from '../point/DailyPoints';
import userIs from '../../utils/permissionUtils';
import { goTo } from '../../operators/routesOperator';

const Me = props => {
  const { authenticatedUser, goTo } = props;
  useEffect(() => {
    if (userIs('EMPLOYER')) {
      goTo(`/companies/${authenticatedUser.companyId}/daily`);
    } else if (userIs('ADMIN')) {
      goTo(`/companies`);
    } else if (userIs('AUDITOR')) {
      goTo(`/companies/${authenticatedUser.companyId}/reports`);
    }
  }, []);

  switch (authenticatedUser.role) {
    case 'USER':
      return <DailyPoints />;
    default:
      return <div />;
  }
};

Me.propTypes = {
  authenticatedUser: PropTypes.shape({ role: PropTypes.string.isRequired })
    .isRequired,
};

const mapStateToProps = store => ({
  authenticatedUser: store.auth.authenticatedUser,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goTo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Me);
