import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ZeptoButton } from '.';

const StepperButtonBar = memo(props => {
  const {
    backEnabled,
    nextEnabled,
    loading,
    onNext,
    onBack,
    nextLabel,
  } = props;
  return (
    <Grid container spacing={2} justify="flex-end">
      {onBack && (
        <Grid item>
          <ZeptoButton
            onClick={onBack}
            disabled={!backEnabled || loading}
            label="Voltar"
          />
        </Grid>
      )}

      <Grid item>
        <ZeptoButton
          disabled={!nextEnabled || loading}
          onClick={onNext}
          label={nextLabel}
        />
      </Grid>
    </Grid>
  );
});

StepperButtonBar.propTypes = {
  backEnabled: PropTypes.bool,
  loading: PropTypes.bool,
  nextEnabled: PropTypes.bool,
  nextLabel: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
};

StepperButtonBar.defaultProps = {
  backEnabled: true,
  nextEnabled: true,
  nextLabel: 'Próximo',
};

export default StepperButtonBar;
