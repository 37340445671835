import { createActions, createReducer } from 'reduxsauce';

/* #region Action types & creators */
export const { Types, Creators } = createActions({
  changeDate: ['date'],

  loadWorkDayRequest: [null],
  loadWorkDaySuccess: ['workDay'],
  loadWorkDayFailure: [null],
});
/* #endregion */

/* #region Initial State */
const INITIAL_STATE = {
  date: new Date(),
};
/* #endregion */

/* #region change date */

const changeDate = (state = INITIAL_STATE, action) => ({
  ...state,
  date: action.date,
});

/* #endregion */

/* #region Register point */

const loadWorkDayRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingWorkDay: true,
});

const loadWorkDaySuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingWorkDay: false,
  workDay: action.workDay,
});

const loadWorkDayFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingWorkDay: false,
});

/* #endregion */

export default createReducer(INITIAL_STATE, {
  [Types.CHANGE_DATE]: changeDate,

  [Types.LOAD_WORK_DAY_REQUEST]: loadWorkDayRequest,
  [Types.LOAD_WORK_DAY_SUCCESS]: loadWorkDaySuccess,
  [Types.LOAD_WORK_DAY_FAILURE]: loadWorkDayFailure,
});
