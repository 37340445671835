import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Grid, LinearProgress, Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { makeStyles } from '@material-ui/styles';
import { ZeptoButton, ZeptoCamera } from '../../components';
import UserStore from '../../stores/UserStore';
import MobileUserPhotoPicker from './MobileUserPhotoPicker';
import UserAvatar from '../menu/UserAvatar';

const useStyles = makeStyles(theme => ({
  avatar: {
    width: theme.spacing(25),
    height: theme.spacing(25),
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  noPhoto: {
    fontSize: 100,
    width: '100%',
  },
  photo: {
    maxWidth: 400,
    width: '100%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  description: { wordBreak: 'normal', textAlign: 'center' },
  center: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

function UserPhotoPicker(props) {
  const classes = useStyles();
  const { user, onUserUploadPhoto } = props;
  const { uploadPhoto, uploadingPhoto, lastUpload } = useContext(UserStore);
  const [isTakingPhoto, setIsTakingPhoto] = useState(false);
  const [mobile] = useState(!!window.ReactNativeWebView);

  function onTakePhoto(photo) {
    setIsTakingPhoto(false);
    uploadPhoto(user, photo);
  }

  function onMobileTakePhoto() {
    setIsTakingPhoto(false);
    onUserUploadPhoto(new Date());
  }

  useEffect(() => {
    console.log({ lastUpload });
    if (lastUpload) onUserUploadPhoto(lastUpload);
  }, [lastUpload]);

  return (
    <>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12}>
          <UserAvatar
            user={user}
            className={classes.avatar}
            whenNoPhotoRender={
              <PersonIcon className={classes.noPhoto} color="disabled" />
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.description}>
            {
              'Essa foto será utilizada como base para a verificação da identidade do usuário no momento do registro de ponto.'
            }
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {uploadingPhoto && <LinearProgress />}
          {!uploadingPhoto && (
            <ZeptoButton
              label="Tirar foto"
              onClick={() => setIsTakingPhoto(true)}
            />
          )}
        </Grid>
      </Grid>

      {isTakingPhoto && !mobile && (
        <ZeptoCamera
          onTakePhoto={onTakePhoto}
          onCancel={() => setIsTakingPhoto(false)}
        />
      )}

      {isTakingPhoto && mobile && (
        <MobileUserPhotoPicker
          onUploadComplete={onMobileTakePhoto}
          onCancel={() => setIsTakingPhoto(false)}
          userId={user.id}
        />
      )}
    </>
  );
}

UserPhotoPicker.propTypes = {
  user: PropTypes.shape({}).isRequired,
  onUserUploadPhoto: PropTypes.func.isRequired,
};

export default observer(UserPhotoPicker);
