import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const ZeptoButton = props => {
  const {
    label,
    loading,
    type,
    onClick,
    style,
    color,
    disabled,
    startIcon,
    variant,
  } = props;
  return (
    <Button
      color={color}
      type={type}
      onClick={onClick}
      disabled={loading || disabled}
      style={style}
      startIcon={startIcon}
      variant={variant}
      fullWidth
    >
      {label}
    </Button>
  );
};

ZeptoButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  startIcon: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.shape({}),
};

ZeptoButton.defaultProps = {
  color: 'primary',
  variant: 'contained',
};

export default ZeptoButton;
