export const RequestType = {
  enable: 'ENABLE',
  disable: 'DISABLE',
};

export const RequestStatus = {
  open: 'OPEN',
  approved: 'APPROVED',
  rejected: 'REJECTED',
};
