import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Toolbar, AppBar, Fab, CircularProgress } from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { makeStyles } from '@material-ui/styles';
import HistoryIcon from '@material-ui/icons/History';
import { drawerWidth } from '../menu/ResponsiveDrawer';

const useStyles = makeStyles(theme => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  addButtonParent: {
    position: 'relative',
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  grow: {
    flexGrow: 1,
  },
}));

const PointOptions = memo(props => {
  const classes = useStyles();
  const { loading, onRegisterPointClick, onRequestClick } = props;
  return (
    <AppBar position="fixed" className={classes.appBar} color="default">
      <Toolbar>
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fabButton}
          disabled={loading}
          onClick={onRegisterPointClick}
        >
          {loading && <CircularProgress size={30} color="default" />}
          {!loading && <CameraAltIcon />}
        </Fab>

        <div className={classes.grow} />
        <Fab
          size="small"
          color="secondary"
          aria-label="add"
          disabled={loading}
          onClick={onRequestClick}
        >
          <HistoryIcon />
        </Fab>
      </Toolbar>
    </AppBar>
  );
});

PointOptions.propTypes = {
  loading: PropTypes.bool,
  onRegisterPointClick: PropTypes.func,
  onRequestClick: PropTypes.func,
};

export default PointOptions;
