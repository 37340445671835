/*
 * O método fetch() retorna erros do servidor da mesma forma que as respostas
 * de sucesso. A diferença é apenas a flag "ok" da resposta.
 *
 * Esse é um método utilitário para ser utilizado nas chamadas Rest para tratar
 * isso e lançar um erro nesses casos, que irá cair no callback "catch" do fetch().
 */
export async function handleError(response) {
  if (!response.ok) {
    let messageText;

    try {
      const respBody = await response.json();
      messageText = respBody.message;
    } catch (e) {
      messageText = response.statusText;
    }
    const error = Error(`Error: ${response.status} ${messageText}`);
    error.message = messageText;
    error.response = response;

    throw error;
  }
  return response;
}
export function get(url) {
  return fetch(url, {
    method: 'GET',
  });
}

/*
 * Faz uma chamada GET para retornar um JSON como resposta.
 *
 * Já faz o tratamento de erro na resposta.
 */
export function jsonFetch(url) {
  const token = localStorage.getItem('id_token');

  return fetch(url, {
    method: 'GET',
    headers: {
      'x-access-token': token,
      'X-Appengine-Cron': true,
      'x-appengine-cron': true
    },
  }).then(async resp => (await handleError(resp)).json());
}

/*
 * Faz uma chamada GET para retornar um JSON como resposta.
 *
 * Já faz o tratamento de erro na resposta.
 */
export function jsonFetchBlob(url) {
  const token = localStorage.getItem('id_token');

  return fetch(url, {
    method: 'GET',
    headers: {
      'x-access-token': token,
    },
  }).then(async resp => (await handleError(resp)).blob());
}
/*
 * Faz uma chamada PATCH para retornar um JSON como resposta.
 *
 * Já faz o tratamento de erro na resposta.
 */
export function jsonPatch(url, body) {
  const token = localStorage.getItem('id_token');

  return fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  }).then(async resp => (await handleError(resp)).json());
}

/*
 * Faz uma chamada POST para retornar um JSON como resposta.
 *
 * Já faz o tratamento de erro na resposta.
 */
export function jsonPost(url, body) {
  const token = localStorage.getItem('id_token');

  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  }).then(async resp => (await handleError(resp)).json());
}

/*
 * Faz uma chamada PUT para retornar um JSON como resposta.
 *
 * Já faz o tratamento de erro na resposta.
 */
export function jsonPut(url, body) {
  const token = localStorage.getItem('id_token');

  return fetch(url, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token,
    },
  }).then(async resp => (await handleError(resp)).json());
}

export function jsonDelete(url) {
  const token = localStorage.getItem('id_token');

  return fetch(url, {
    method: 'DELETE',
    headers: {
      'x-access-token': token,
    },
  }).then(async resp => (await handleError(resp)).json());
}

export function jsonPath(url) {
  const token = localStorage.getItem('id_token');

  return fetch(url, {
    method: 'PATCH',
    headers: {
      'x-access-token': token,
    },
  }).then(async resp => (await handleError(resp)).json());
}
