import React, { useContext, useEffect } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { observer } from 'mobx-react';

import { CssBaseline } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import AppStore, { DARK_THEME, LIGHT_THEME } from '../stores/AppStore';
import { blue } from '@material-ui/core/colors';

const createTheme = ({ themeType }) =>
  createMuiTheme({
    palette: {
      primary: blue,
      type: themeType,
    },
    // overrides: {
    //   MuiTableCell: {
    //     root: {
    //       padding: '14px 10px 14px 10px;',
    //     },
    //   },
    // },
  });
function ZeptoTheme(props) {
  const { children } = props;
  const { theme, setTheme } = useContext(AppStore);

  useEffect(() => {
    if (!theme) {
      const prefersDarkMode =
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches;
      setTheme(prefersDarkMode ? DARK_THEME : LIGHT_THEME);
    }
  }, []);

  return (
    <ThemeProvider theme={createTheme({ themeType: theme || LIGHT_THEME })}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

ZeptoTheme.propTypes = {
  children: PropTypes.node.isRequired,
};

export default observer(ZeptoTheme);
