import React, { useState } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import AddIcon from '@material-ui/icons/Add';
import { HiQuestionMarkCircle } from "react-icons/hi";
import {
  ZeptoTextField,
  ZeptoCheckbox,
  ZeptoBorder,
  ZeptoSelect,
  ZeptoButton,
} from '../../../../components';
import JourneyDayInput from './JourneyDayInput';
import ZeptoTimePicker from '../../../../components/ZeptoTimePicker';
import { timeFormat } from '../../../../utils/Constants';

const byIndex = (d1, d2) => d1.index - d2.index;
const weeklyType = { id: 'WEEKLY', label: 'Semanal' };
const customType = { id: 'CUSTOM', label: 'Customizada' };
const journeyTypes = [weeklyType, customType];

const workDay = {
  type: 'WORK_DAY',
  periods: [
    {
      index: 0,
      start: moment('09:00', timeFormat),
      end: moment('12:00', timeFormat),
    },
    {
      index: 1,
      start: moment('13:00', timeFormat),
      end: moment('18:00', timeFormat),
    },
  ],
};

const dayOff = {
  type: 'DAY_OFF',
  periods: [],
};

const getWeeklyDays = () => [
  // domingo
  {
    index: 0,
    ...dayOff,
  },
  // dias da semana
  ...[...Array(5).keys()].map(i => ({
    index: i + 1,
    ...workDay,
  })),
  // Sábado
  {
    index: 6,
    ...dayOff,
  },
];
const inputProps = {
  max255: {
    maxLength: 255,
  },
  max20: {
    maxLength: 20,
  },
  max11: {
    maxLength: 11,
  },
};

const JourneyInput = observer(props => {
  const { value, onChange, errors, disabled, readOnly } = props;
  let [showExtraHours, setShowExtraHours] = useState(false)
  let [showBankedHours, setShowBankedHours] = useState(false)
  let [showIntervalBankedHours, setShowIntervalBankedHours] = useState(false)
  let [showIgnoresHolidays, setIgnoresHolidays] = useState(false)
  function handleChange(name) {
    return event => {
      onChange({ ...value, [name]: event.target.value });
    };
  }

  function handleJourneyDayChange(newJourneyDay) {
    const index = value.days.findIndex(d => d.index === newJourneyDay.index);

    const newDays = value.days.slice();
    newDays.splice(index, 1, newJourneyDay);

    onChange({ ...value, days: newDays });
  }

  function onRemove(journeyDay) {
    const index = value.days.findIndex(d => d.index === journeyDay.index);

    const newDays = value.days.slice().sort(byIndex);
    newDays.splice(index, 1);
    newDays.forEach((item, index) => {
      item.index = index;
    });

    onChange({ ...value, days: newDays });
  }

  function handleTimeChange(name) {
    return newTime => {
      onChange({ ...value, [name]: newTime });
    };
  }

  function handleCheckboxChange(event) {
    onChange({ ...value, [event.target.name]: event.target.checked });
  }

  function onAddDay() {
    onChange({
      ...value,
      days: [...value.days, { ...dayOff, index: value.days.length }],
    });
  }

  function handleTypeChange(e) {
    const newType = e.target.value;
    let daysToAssign = [];

    if (newType === weeklyType.id) {
      daysToAssign = getWeeklyDays();
    }
    onChange({ ...value, type: newType, days: daysToAssign });
  }

  const allowCustomDays =
    !readOnly && value.type && value.type !== weeklyType.id;

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={12}>
        <ZeptoTextField
          value={value.name}
          label="Nome"
          required
          onChange={handleChange('name')}
          errorMessage={errors ? errors('journey.name') : null}
          inputProps={inputProps.max20}
          disabled={disabled || readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <ZeptoBorder>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={3.1}>
              {value.type !== 'WEEKLY' && (
                <ZeptoCheckbox
                  checked={value.ignoresHolidays}
                  label="Ignora feriados"
                  onChange={handleCheckboxChange}
                  name="ignoresHolidays"
                  disabled={readOnly}
                />
              )}
            </Grid>
            {value.type !== 'WEEKLY' && (
              <Grid xs={6} style={{ marginTop: 5 }}>
                <HiQuestionMarkCircle size={22} style={{ cursor: 'pointer' }} onClick={() => setIgnoresHolidays(!showIgnoresHolidays)} />
              </Grid>
            )}
            {showIgnoresHolidays && (
              <Grid>
                <Typography>
                  O sistema passa a tratar os feriados como dias de trabalho caso haja previsão de trabalho para aquele dia da semana (em caso de turnos semanais) ou dia da escala (em caso de turnos cíclicos).
                </Typography>
                <Typography style={{ fontWeight: 'bold' }}>
                  ATENÇÃO:
                </Typography>
                <Typography>
                  Antes de usar essa configuração verifique se o seu "Acordo Coletivo de Trabalho" permite esse tipo de jornada. Em caso de dúvidas entre em contato com seu sindicato, contador ou advogado antes de realizar essa configuração.
                </Typography>
              </Grid>
            )}
            <Grid item xs={3.1}>
              <ZeptoCheckbox
                checked={value.allowExtraHoursOnBreak}
                label="Mostrar Hora Extra do intervalo na coluna EI50%"
                onChange={handleCheckboxChange}
                name="allowExtraHoursOnBreak"
                disabled={readOnly}
              />
            </Grid>
            <Grid xs={6} style={{ marginTop: 5 }}>
              <HiQuestionMarkCircle size={22} style={{ cursor: 'pointer' }} onClick={() => setShowExtraHours(!showExtraHours)} />
            </Grid>
            {showExtraHours && (
              <Grid>
                <Typography>
                  Habilitando essa opção, a hora extra realizada no intervalo, aparecerá em campo específico no relatório espelho de ponto na coluna EI50%.
                </Typography>
                <Typography style={{ fontWeight: 'bold' }}>
                  ATENÇÃO:
                </Typography>
                <Typography>
                  Antes de selecionar essa configuração, verifique com a sua contabilidade/advogado/sindicato se a sua empresa pode utilizar essa opção.
                </Typography>
              </Grid>
            )}
            <Grid item xs={3.1}>
              <ZeptoCheckbox
                checked={value.allowBankedHours}
                label="Permite banco de horas"
                onChange={handleCheckboxChange}
                name="allowBankedHours"
                disabled={readOnly}
              />
            </Grid>
            <Grid xs={6} style={{ marginTop: 5 }}>
              <HiQuestionMarkCircle size={22} style={{ cursor: 'pointer' }} onClick={() => setShowBankedHours(!showBankedHours)} />
            </Grid>
            {showBankedHours && (
              <Grid item xs={12}>
                <Typography >
                  Essa opção só deve ser selecionada se a sua empresa trabalha com o sistema que consiste na compensação do período excedente trabalhado pelo colaborador, com folgas a serem tiradas. Dessa forma, o colaborador no lugar de receber o pagamento pelas horas extras, ele vai receber descanso proporcional às horas acumuladas no banco de horas.
                </Typography>
                <Typography style={{ fontWeight: 'bold' }}>
                  ATENÇÃO:
                </Typography>
                <Typography>
                  Antes de selecionar essa configuração, verifique com a sua contabilidade/advogado/sindicato se a sua empresa possui o acordo individual ou coletivo para utilizar essa opção.
                </Typography>
              </Grid>
            )}
            {value.allowBankedHours && (
              <>
                <Grid item xs={3.1}>
                  <ZeptoCheckbox
                    checked={value.allowIntervalBankedHours}
                    label="Permite banco de horas em intervalos"
                    onChange={handleCheckboxChange}
                    name="allowIntervalBankedHours"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid xs={6} style={{ marginTop: 5 }}>
                  <HiQuestionMarkCircle size={22} style={{ cursor: 'pointer' }} onClick={() => setShowIntervalBankedHours(!showIntervalBankedHours)} />
                </Grid>
              </>
            )}
            {showIntervalBankedHours && value.allowBankedHours && (
              <Grid item xs={12}>
                <Typography >
                  Habilitando essa opção, você estará afirmando que o sistema deve computar as horas excedentes ou faltantes do intervalo. Por exemplo: O colaborador tem o horário de intervalo de descanso de 12:00 horas a 14:00 horas, se ele sai ás 12:30 horas, com a opção habilitada o sistema vai computar esses 30 minutos como saldo positivo no banco de horas, com a opção desabilitada o sistema não vai computar esses 30 minutos excedentes no banco de horas.
                </Typography>
                <Typography style={{ fontWeight: 'bold' }}>
                  ATENÇÃO:
                </Typography>
                <Typography>
                  Antes de selecionar essa configuração, verifique com a sua contabilidade/advogado/sindicato se a sua empresa pode utilizar essa opção.
                </Typography>
              </Grid>
            )}
          </Grid>
        </ZeptoBorder>
      </Grid>

      <Grid item xs={12}>
        <ZeptoTimePicker
          label="Virada da Jornada"
          value={value.shiftTime}
          onChange={handleTimeChange('shiftTime')}
          errorMessage={errors ? errors('journey.shiftTime') : null}
          disabled={disabled || readOnly}
        />
      </Grid>

      <Grid item xs={12} md={allowCustomDays ? 10 : 12}>
        <ZeptoSelect
          required
          label="Tipo"
          value={value.type}
          onChange={handleTypeChange}
          disabled={disabled || readOnly}
          errorMessage={errors ? errors('journey.type') : null}
        >
          {journeyTypes.map(r => (
            <MenuItem key={r.id} value={r.id}>
              {r.label}
            </MenuItem>
          ))}
        </ZeptoSelect>
      </Grid>

      {allowCustomDays && (
        <Grid item xs={12} md={2}>
          <ZeptoButton
            label="Dia"
            startIcon={<AddIcon />}
            onClick={onAddDay}
            disabled={disabled}
          />
        </Grid>
      )}

      {value.days
        .slice()
        .sort(byIndex)
        .map(day => (
          <Grid item xs={12} key={day.index}>
            <JourneyDayInput
              value={day}
              onChange={handleJourneyDayChange}
              onRemove={onRemove}
              disabled={disabled}
              readOnly={readOnly}
              journeyType={value.type}
              errors={errors}
              path={`journey.days.${day.index}`}
              allowCustomDays={allowCustomDays}
            />
          </Grid>
        ))}
    </Grid>
  );
});

JourneyInput.propTypes = {
  value: PropTypes.shape({}),
  onChange: PropTypes.func,
};

export default JourneyInput;
