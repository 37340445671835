import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ZeptoTextField, ZeptoMaskedTextField } from '../../../../components';

const phoneMask = {
  mask: [
    '(',
    /\d/,
    /\d/,
    ')',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
};

const cpfMask = {
  mask: [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
};
function BillingDataInput(props) {
  const { value, onUpdate, name, disabled, getError } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ZeptoTextField
          value={value.name}
          onUpdate={onUpdate}
          name={`${name}.name`}
          label="Responsável"
          disabled={disabled}
          errorMessage={getError(`${name}.name`)}
        />
      </Grid>
      <Grid item xs={12}>
        <ZeptoTextField
          name={`${name}.email`}
          value={value.email}
          onUpdate={onUpdate}
          label="Email"
          type="email"
          disabled={disabled}
          errorMessage={getError(`${name}.email`)}
        />
      </Grid>
      <Grid item xs={12}>
        <ZeptoMaskedTextField
          name={`${name}.phone`}
          value={value.phone}
          onUpdate={onUpdate}
          label="Celular"
          inputProps={phoneMask}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <ZeptoMaskedTextField
          name={`${name}.cpf`}
          value={value.cpf}
          onUpdate={onUpdate}
          label="CPF"
          inputProps={cpfMask}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
}

BillingDataInput.propTypes = {
  value: PropTypes.shape({}).isRequired,
};

export default memo(BillingDataInput);
