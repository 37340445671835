import { Creators as UserWSActions } from '../ducks/userWorkSchedules';
import { jsonPost, jsonDelete } from '../utils/http';
import { Creators as AppActions } from '../ducks/app';

const apiPath = userId =>
  `${process.env.REACT_APP_API_PATH}/api/users/${userId}/userWorkSchedules`;

export function createUserWorkSchedule(userId, userWS) {
  return dispatch => {
    dispatch(UserWSActions.saveWorkScheduleRequest());

    return jsonPost(apiPath(userId), userWS)
      .then(json => {
        dispatch(UserWSActions.saveWorkScheduleSuccess(json));
        dispatch(AppActions.showSuccess('Registro salvo com sucesso.'));
      })
      .catch(error => {
        dispatch(UserWSActions.saveWorkScheduleFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao salvar a escala de usuário: ${error.message}`
          )
        );
      });
  };
}

export function deleteUserWorkSchedule(userId, userWS) {
  return dispatch => {
    dispatch(UserWSActions.deleteWorkScheduleRequest());

    return jsonDelete(`${apiPath(userId)}/${userWS.id}`)
      .then(json => {
        dispatch(UserWSActions.deleteWorkScheduleSuccess(json));
        dispatch(AppActions.showSuccess('Registro excluído com sucesso.'));
      })
      .catch(error => {
        dispatch(UserWSActions.deleteWorkScheduleFailure(error.message));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao excluir a escala de usuário: ${error.message}`
          )
        );
      });
  };
}

export function deleteWs() {}
