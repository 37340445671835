import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { ZeptoMaskedTextField, ZeptoTextField } from '../../components';

const inputProps = {
  max255: {
    maxLength: 255,
  },
  max20: {
    maxLength: 20,
  },
  max11: {
    maxLength: 11,
  },
};

const UserInput = props => {
  const { onChange, user, errors } = props;

  function handleChange(event) {
    onChange({ ...user, [event.target.name]: event.target.value });
  }

  return (
    <Grid container spacing={2}>
      {/* Workaroud para impedir o chrome de autocompletar a senha */}
      <input type="password" style={{ opacity: 0, position: 'absolute' }} />
      <input name="email" style={{ opacity: 0, position: 'absolute' }} />

      <Grid item xs={12}>
        <ZeptoTextField
          name="name"
          value={user.name}
          label="Nome"
          onChange={handleChange}
          errorMessage={errors('name')}
          required
          inputProps={inputProps.max255}
        />
      </Grid>
      <Grid item xs={12}>
        <ZeptoTextField
          name="email"
          value={user.email}
          label="Email"
          type="email"
          required
          onChange={handleChange}
          errorMessage={errors('email')}
          inputProps={inputProps.max255}
        />
      </Grid>

      <Grid item xs={12}>
        <ZeptoMaskedTextField
          name="phone"
          value={user.phone}
          label="Celular"
          required
          onChange={handleChange}
          errorMessage={errors('phone')}
          inputProps={{
            mask: [
              '(',
              /\d/,
              /\d/,
              ')',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ],
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <ZeptoTextField
          name="password"
          value={user.password}
          label="Senha"
          type="password"
          onChange={handleChange}
          errorMessage={errors('password')}
          inputProps={inputProps.max20}
          autoComplete="off"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <ZeptoTextField
          name="password_confirmation"
          value={user.password_confirmation}
          label="Confirme a senha"
          type="password"
          onChange={handleChange}
          errorMessage={errors('password_confirmation')}
          inputProps={inputProps.max20}
          autoComplete={false}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <ZeptoMaskedTextField
          name="cpf"
          value={user.cpf}
          label="CPF"
          required
          onChange={handleChange}
          errorMessage={errors('cpf')}
          inputProps={{
            mask: [
              /\d/,
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
            ],
          }}
        />
      </Grid>
    </Grid>
  );
};

UserInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
};

export default memo(UserInput);
