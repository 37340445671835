import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Grid,
  LinearProgress,
  Toolbar,
  Typography,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { push } from 'react-router-redux';
import moment from 'moment';
import classnames from 'classnames';
import {
  loadUser,
  disableUser,
  enableUser,
  editUser,
} from '../../operators/usersOperator';
import { ZeptoConfirmDialog } from '../../components';
import { deleteUserWorkSchedule } from '../../operators/userWorkSchedulesOperator';
import UserMenu from './UserMenu';
import { dateFormat } from '../../utils/Constants';
import UserAllowances from './allowances/UserAllowances';
import UserJourneys from './journeys/UserJourneys';
import TimeAdjustments from './time-adjustments/TimeAdjustments';

const useStyles = makeStyles({
  title: {
    flexGrow: 1,
  },
  disabled: {
    opacity: 0.5,
    color: 'red',
  },
});

const UserViewer = props => {
  const classes = useStyles();
  const {
    loadUser,
    params,
    selected,
    processingLoadUser,
    lastDeleted,
    deletingWorkSchedule,
    lastSaved,
    disableUser,
    enableUser,
    editUser,
    processingDisableUser,
    processingEnableUser,
  } = props;

  const loading = processingDisableUser || processingEnableUser;

  const [confirmOptions, setConfirmOptions] = React.useState({
    open: false,
  });

  useEffect(() => {
    loadUser(params.id);
  }, [lastDeleted, lastSaved]);

  const closeConfirmation = () => setConfirmOptions({ open: false });

  useEffect(() => {
    closeConfirmation();
  }, [lastDeleted]);

  const onEditUser = () => {
    editUser(selected.id);
  };

  const onDisableUserConfirm = () => {
    disableUser(selected);
    closeConfirmation();
  };

  const onDisableUser = () => {
    setConfirmOptions({
      open: true,
      onConfirm: () => onDisableUserConfirm(),
      content: `Deseja realmente desabilitar o usuário '${selected.name}'?`,
    });
  };

  const onEnableUserConfirm = () => {
    enableUser(selected);
    closeConfirmation();
  };

  const onEnableUser = () => {
    setConfirmOptions({
      open: true,
      onConfirm: () => onEnableUserConfirm(),
      content: `Deseja realmente habilitar o usuário '${selected.name}'?`,
    });
  };

  function onCreateAllowance() {
    const { push } = props;
    push(
      `/users/${selected.id}/allowances/new?date=${moment().format(dateFormat)}`
    );
  }

  function titleClass() {
    if (!selected) return '';

    return classnames(classes.title, selected.enabled ? '' : classes.disabled);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {processingLoadUser && <LinearProgress />}
      </Grid>

      {!processingLoadUser && selected && (
        <>
          <Grid item xs={12} zeroMinWidth>
            <Paper>
              <Toolbar>
                <Typography
                  component="h1"
                  variant="h5"
                  className={titleClass()}
                >
                  {selected.name}
                </Typography>

                <UserMenu
                  loading={loading}
                  user={selected}
                  onDisable={onDisableUser}
                  onEnable={onEnableUser}
                  onEdit={onEditUser}
                  onCreateAllowance={onCreateAllowance}
                />
              </Toolbar>
            </Paper>
          </Grid>

          {selected.role === 'USER' && (
            <>
              <Grid item xs={12}>
                <TimeAdjustments employer={selected} />
              </Grid>

              <Grid item xs={12} lg={6}>
                <UserJourneys user={selected} />
              </Grid>

              <Grid item xs={12} lg={6}>
                <UserAllowances user={selected} />
              </Grid>
            </>
          )}
        </>
      )}

      <ZeptoConfirmDialog
        open={confirmOptions.open}
        onConfirm={confirmOptions.onConfirm}
        onCancel={closeConfirmation}
        content={confirmOptions.content}
        loading={deletingWorkSchedule}
      />
    </Grid>
  );
};

const mapStateToProps = store => ({
  selected: store.users.selected,
  processingLoadUser: store.users.processingLoadUser,
  lastDeleted: store.userWorkSchedules.lastDeleted,
  lastSaved: store.userWorkSchedules.lastSaved,
  deletingWorkSchedule: store.userWorkSchedules.deletingWorkSchedule,
  deleteUserWorkSchedule: PropTypes.func.isRequired,
  processingDisableUser: store.users.processingDisableUser,
  processingEnableUser: store.users.processingEnableUser,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadUser,
      disableUser,
      enableUser,
      editUser,
      deleteUserWorkSchedule,
      push,
    },
    dispatch
  );

UserViewer.propTypes = {
  selected: PropTypes.shape({
    id: PropTypes.number.isRequired,
    companyId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    enabled: PropTypes.bool,
    role: PropTypes.string.isRequired,
    userHasWorkSchedules: PropTypes.arrayOf(PropTypes.shape({}).isRequired)
      .isRequired,
  }),
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  loadUser: PropTypes.func.isRequired,
  deleteUserWorkSchedule: PropTypes.func.isRequired,
  processingLoadUser: PropTypes.bool,
  deletingWorkSchedule: PropTypes.bool,
  lastDeleted: PropTypes.number,
  lastSaved: PropTypes.shape({}),
  disableUser: PropTypes.func.isRequired,
  enableUser: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  processingDisableUser: PropTypes.bool,
  processingEnableUser: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(UserViewer));
