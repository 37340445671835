import { createActions, createReducer } from 'reduxsauce';

/* #region Action types & creators */
export const { Types, Creators } = createActions({
  showSuccess: ['message'],
  showError: ['message'],
  showWarn: ['message'],
  showInfo: ['message'],
  hideMessage: null,
});
/* #endregion */

const INITIAL_STATE = {
  alertMessage: undefined,
};

/* #region Show succes */

const showSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  alertMessage: {
    text: action.message,
    variant: 'success',
  },
});

const showError = (state = INITIAL_STATE, action) => ({
  ...state,
  alertMessage: {
    text: action.message,
    variant: 'error',
  },
});

const showWarn = (state = INITIAL_STATE, action) => ({
  ...state,
  alertMessage: {
    text: action.message,
    variant: 'warning',
  },
});

const showInfo = (state = INITIAL_STATE, action) => ({
  ...state,
  alertMessage: {
    text: action.message,
    variant: 'info',
  },
});

const hideMessage = (state = INITIAL_STATE) => ({
  ...state,
  alertMessage: null,
});

/* #endregion */

export default createReducer(INITIAL_STATE, {
  [Types.SHOW_SUCCESS]: showSuccess,
  [Types.SHOW_ERROR]: showError,
  [Types.SHOW_WARN]: showWarn,
  [Types.SHOW_INFO]: showInfo,
  [Types.HIDE_MESSAGE]: hideMessage,
});
