import React from 'react';
import { TableCell, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ZeptoTable from '../../../../components/ZeptoTable';

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2),
  },
  tableCell: {
    paddingRight: 0,
    paddingLeft: 0,
    width: '5%',
  },
  disabled: {
    opacity: 0.5,
    color: 'red',
  },
}));
const JourneyList = props => {
  const {
    journeys,
    selectable,
    onSelectionChange,
    loading,
    headRows,
    renderCells,
  } = props;
  const classes = useStyles();
  return (
    <ZeptoTable
      {...props}
      title="Jornadas"
      selectable={selectable}
      loading={loading}
      headRows={[
        {
          id: 'name',
          label: 'Name',
        },
        ...headRows,
      ]}
      renderCells={companyJourney => (
        <>
          <TableCell className={!companyJourney.enabled ? classes.disabled : null}>
            {companyJourney.journey.name}
          </TableCell>
          {renderCells && renderCells(companyJourney)}
        </>
      )}
      rows={journeys}
      onSelectionChange={ids => onSelectionChange && onSelectionChange(ids)}
    />
  );
};

JourneyList.propTypes = {
  journeys: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectable: PropTypes.bool,
  loading: PropTypes.bool,
  renderCells: PropTypes.func,
  headRows: PropTypes.arrayOf(PropTypes.shape({ id: String, label: String })),
  onSelectionChange: PropTypes.func,
};

JourneyList.defaultProps = {
  headRows: [],
};

export default JourneyList;
