import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { RequestType } from '../../model/Request';
import { green, red } from '@material-ui/core/colors';

const useStyles = makeStyles({
  root: {
    margin: '30px 0px 10px 0px',
  },
  open: { color: green[400] },
  disable: { color: red[400] },
  requestText: {
    opacity: 0.5,
  },
});

const RequestViewer = (props) => {
  const { request } = props;

  const classes = useStyles();

  return (
    <Grid className={classes.root} container spacing={0} direction="column">
      <Grid item xs={12}>
        <Grid container spacing={0} alignItems="center" direction="row">
          <Grid
            item
            xs={12}
            className={
              request.type === RequestType.enable
                ? classes.open
                : classes.disable
            }
          >
            {`${moment(request.point.timestamp).format(
              'DD/MM/YYYY',
            )} às ${moment(request.point.timestamp).format('HH:mm')}`}
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <span className={classes.requestText}>Justificativa: </span>
        {request.requestText}
      </Grid>
    </Grid>
  );
};
RequestViewer.propTypes = {
  request: PropTypes.shape({}).isRequired,
};

export default RequestViewer;
