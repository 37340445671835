import { createContext } from 'react';
import { decorate, observable } from 'mobx';

class CompanyStore {
  company;

  setCompany = company => {
    this.company = company;
  };
}

decorate(CompanyStore, {
  company: observable,
});

export const companyStore = new CompanyStore();

export default createContext(companyStore);
