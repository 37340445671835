import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Lock } from '@material-ui/icons';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    position: 'relative',
    display: 'flex',
  },
  iconParent: {
    backgroundColor: blue[400],
    position: 'absolute',
    left: 24,
    top: -32,
    width: 64,
    height: 64,
  },
  icon: {
    fontSize: '2.1875rem',
    color: 'white',
  },
  spacer: {
    paddingBottom: theme.spacing(5),
  },
}));

const ZeptoPaper = props => {
  const classes = useStyles();
  const { children, square, id, className, variant } = props;

  function getIconByVariant() {
    return (
      <>
        <Avatar variant="rounded" className={classes.iconParent}>
          {variant === 'protected' && <Lock className={classes.icon} />}
        </Avatar>
        <div className={classes.spacer} />
      </>
    );
  }
  return (
    <Paper id={id} square={square} className={clsx(classes.root, className)}>
      {variant && getIconByVariant()}
      {children}
    </Paper>
  );
};

ZeptoPaper.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  square: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.string,
};

export default ZeptoPaper;
