import { createContext } from 'react';
import { decorate, observable } from 'mobx';

class MenuStore {
  mobileDrawerOpen;

  setMobileDrawerOpen = open => {
    this.mobileDrawerOpen = open;
  };
}

decorate(MenuStore, {
  mobileDrawerOpen: observable,
});

export const menuStore = new MenuStore();

export default createContext(menuStore);
