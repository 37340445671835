import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  LinearProgress,
  DialogContent,
  Fab,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';
import Formsy from 'formsy-react';
import moment from 'moment';

import { loadUsersWPoints } from '../../../operators/usersOperator';
import {
  ZeptoFormTextField,
  ZeptoFormDateTimePicker,
  ZeptoPaper,
  ZeptoDialog,
} from '../../../components';
import ZeptoButton from '../../../components/ZeptoButton';
import { createRequest } from '../../../operators/requestsOperator';
import { showWarn } from '../../../operators/appOperator';
import UserWorkDay from '../../users/list/UserPointsList';
import { debounce } from 'lodash';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  buttonArea: {
    width: '100%',
    paddingTop: 10,
    paddingBottom: 15,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const CompanyUsersList = props => {
  const { company } = props;

  const [enableSubmit, setEnableSubmit] = React.useState(false);
  const [showAddJorney, setShowAddJorney] = useState(false);
  const [user, setUser] = useState()
  const [state, setState] = useState({ date: new Date() });
  const classes = useStyles();
  const [request] = React.useState({ requestText: 'Ajuste', timestamp: '' });
  const { date } = state;
  const { usersWPoints, processingLoadUsersWPoints } = props;

  function onChangeDate(newDate) {
    setState({ ...state, date: newDate });
  }

  const getData = debounce(onChangeDate, 2000)

  React.useEffect(() => {
    const { loadUsersWPoints, company } = props;
    loadUsersWPoints(company.id, date);
  }, [company, date]);

  const handleSubmit = data => {
    const { createRequest } = props;

    const req = {
      ...data,
      timestamp: moment(data.timestamp, 'DD/MM/YYYY HH:mm').format(
        'YYYY-MM-DD HH:mm'
      ),
      type: 'ENABLE',
      pointId: null,
      user
    };

    createRequest(req);
  };

  const onClick = (user) => {
    setShowAddJorney(true)
    setUser(user)
  }

  return (
    <>
      {showAddJorney && <ZeptoDialog
        open
        title="Adicionar ajuste de jornada"
        onClose={() => setShowAddJorney(false)}
        renderAction={() => (
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item>
              <Fab
                onClick={() => setShowAddJorney(false)}
                size="small"
                color="secondary"
              >
                <CloseIcon />
              </Fab>
            </Grid>
          </Grid>
        )
        }
      >
        <DialogContent>
          <Formsy
            onValidSubmit={handleSubmit}
            onValid={() => setEnableSubmit(true)}
            onInvalid={() => setEnableSubmit(false)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ZeptoFormDateTimePicker
                  name="timestamp"
                  value={request.timestamp}
                  label="Horário"
                  required
                  validations="isExisty,isValidDateTime"
                  validationError="Horário inválido"
                />
              </Grid>
              <Grid item xs={12}>
                <ZeptoFormTextField
                  name="requestText"
                  value={request.requestText}
                  label="Justificativa"
                  validations="isExisty,minLength:1,maxLength:255"
                  validationError="Justificativa inválida"
                  required
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Grid>
              <div className={classes.buttonArea}>
                <ZeptoButton
                  label="Salvar"
                  type="submit"
                  loading={!enableSubmit}
                />
              </div>
            </Grid>
          </Formsy>
        </DialogContent>
      </ZeptoDialog >
      }
      <ZeptoPaper>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6">Pontos do dia</Typography>
          </Grid>
          <Grid item xs={6}>
            <KeyboardDatePicker
              format="DD/MM/YYYY"
              autoOk
              fullWidth
              value={moment(date, 'DD/MM/YYYY')}
              onChange={getData}
              disabled={processingLoadUsersWPoints}
            />
          </Grid>

          <Grid item xs={12}>
            {processingLoadUsersWPoints && <LinearProgress />}
          </Grid>

          <Grid item xs={12}>
            {usersWPoints &&
              usersWPoints.map(userWorkDay => (
                <Grid container justify="space-between">
                  <Grid item>
                    <UserWorkDay
                      key={userWorkDay.user.id}
                      userWorkDay={userWorkDay}
                    />
                  </Grid>

                  <Grid item style={{ marginTop: '50px' }}>
                    <Fab
                      onClick={() => onClick(userWorkDay.user)}
                      size="small"
                      color="primary"
                      disabled={false}
                    >
                      <AddIcon />
                    </Fab>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </ZeptoPaper>
    </>
  );
};

const mapStateToProps = store => ({
  usersWPoints: store.users.usersWPoints,
  processingLoadUsersWPoints: store.users.processingLoadUsersWPoints,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createRequest,
      showWarn,
      loadUsersWPoints,
    },
    dispatch
  );

CompanyUsersList.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  usersWPoints: PropTypes.arrayOf(PropTypes.shape({})),
  processingLoadUsersWPoints: PropTypes.bool,
  createRequest: PropTypes.func.isRequired,
  showWarn: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(CompanyUsersList));
