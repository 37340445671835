import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContent,
  DialogActions,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ZeptoDialog, ZeptoButton, ZeptoTextField } from '../../components';
import RequestViewer from './RequestViewer';
import {
  approveRequest,
  rejectRequest,
} from '../../operators/requestsOperator';

const RequestConfirmDialog = (props) => {
  const {
    request,
    open,
    onClose,
    toApprove,
    processingApproveRequest,
    processingRejectRequest,
  } = props;

  const loading = () => processingApproveRequest || processingRejectRequest;

  const [reviewText, setReviewText] = React.useState('');

  const handleSubmit = (data) => {
    const { approveRequest, rejectRequest } = props;
    const req = {
      ...request,
      reviewText,
    };

    if (toApprove) {
      approveRequest(req, onClose);
    } else {
      rejectRequest(req, onClose);
    }
  };

  return (
    <ZeptoDialog open={open} title="Confirmação" onClose={onClose}>
      <DialogContent dividers>
        {loading() && <LinearProgress />}
        <Typography>
          {`Deseja realmente ${
            toApprove ? 'aprovar' : 'rejeitar'
          } a solicitação abaixo?`}
        </Typography>
        <RequestViewer request={request} />
        {!toApprove && (
          <ZeptoTextField
            name="reviewText"
            label="Justificativa"
            value={reviewText}
            onChange={e => setReviewText(e.target.value)}
            required
            inputProps={{
              maxLength: 200,
            }}
            errorMessage={!reviewText ? 'A justificatória é obrigatória.' : ''}
          />
        )}
        {loading() && <LinearProgress />}
      </DialogContent>
      <DialogActions>
        <ZeptoButton
          onClick={onClose}
          loading={loading()}
          label="Cancelar"
          color="secondary"
        />
        <ZeptoButton
          onClick={() => handleSubmit()}
          color="primary"
          label="Confirmar"
          loading={loading() || (!toApprove && !reviewText)}
        />
      </DialogActions>
    </ZeptoDialog>
  );
};

RequestConfirmDialog.propTypes = {
  request: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func,
  approveRequest: PropTypes.func,
  rejectRequest: PropTypes.func,
  open: PropTypes.bool,
  toApprove: PropTypes.bool,
  processingApproveRequest: PropTypes.bool,
  processingRejectRequest: PropTypes.bool,
};

const mapStateToProps = store => ({
  processingApproveRequest: store.requests.processingApproveRequest,
  processingRejectRequest: store.requests.processingRejectRequest,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    approveRequest,
    rejectRequest,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(RequestConfirmDialog));
