import { createActions, createReducer } from 'reduxsauce';

/* #region Action types & creators */
export const { Types, Creators } = createActions({
  loadCompaniesRequest: [null],
  loadCompaniesSuccess: ['companies'],
  loadCompaniesFailure: ['error'],

  loadCompanyRequest: [null],
  loadCompanySuccess: ['company'],
  loadCompanyFailure: ['error'],

  createCompanyRequest: [null],
  createCompanySuccess: ['company'],
  createCompanyFailure: ['error'],

  updateCompanyRequest: [null],
  updateCompanySuccess: ['company'],
  updateCompanyFailure: ['error'],

  disableCompanyRequest: [null],
  disableCompanySuccess: [null],
  disableCompanyFailure: [null],
});
/* #endregion */

/* #region Initial State */
const INITIAL_STATE = {
  companies: [],
};
/* #endregion */

/* #region Companies list */

const loadCompaniesRequest = (state = INITIAL_STATE) => ({
  ...state,
  processingLoadCompanies: true,
  loadCompaniesErrorMessage: '',
  companies: [],
});

const loadCompaniesSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  processingLoadCompanies: false,
  loadCompaniesErrorMessage: '',
  companies: action.companies,
});

const loadCompaniesFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  processingLoadCompanies: false,
  loadCompaniesErrorMessage: action.error,
});
/* #endregion */

/* #region Company load */

const loadCompanyRequest = (state = INITIAL_STATE) => ({
  ...state,
  processingLoadCompany: true,
  loadCompanyErrorMessage: '',
  selected: null,
});

const loadCompanySuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  processingLoadCompany: false,
  loadCompanyErrorMessage: '',
  selected: action.company,
});

const loadCompanyFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  processingLoadCompany: false,
  loadCompanyErrorMessage: action.error,
});
/* #endregion */

/* #region Company creation */

const createCompanyRequest = (state = INITIAL_STATE) => ({
  ...state,
  processingCreateCompany: true,
  createCompanyErrorMessage: '',
});

const createCompanySuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  processingCreateCompany: false,
  createCompanyErrorMessage: '',
  selected: action.company,
});

const createCompanyFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  processingCreateCompany: false,
  createCompanyErrorMessage: action.error,
});
/* #endregion */
/* #region Company update */

const updateCompanyRequest = (state = INITIAL_STATE) => ({
  ...state,
  processingUpdateCompany: true,
  updateCompanyErrorMessage: '',
});

const updateCompanySuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  processingUpdateCompany: false,
  updateCompanyErrorMessage: '',
  selected: action.company,
});

const updateCompanyFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  processingUpdateCompany: false,
  updateCompanyErrorMessage: action.error,
});

/* #endregion */

/* region Disable Company */

const disableCompanyRequest = (state = INITIAL_STATE) => ({
  ...state,
  processingDisableCompany: true,
  disableCompanyErrorMessage: '',
});

const disableCompanySuccess = (state = INITIAL_STATE) => ({
  ...state,
  processingDisableCompany: false,
  disableCompanyErrorMessage: '',
  selected: undefined,
});

const disableCompanyFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  processingDisableCompany: false,
  disableCompanyErrorMessage: action.error,
});

/* #endregion */

export default createReducer(INITIAL_STATE, {
  [Types.LOAD_COMPANIES_REQUEST]: loadCompaniesRequest,
  [Types.LOAD_COMPANIES_SUCCESS]: loadCompaniesSuccess,
  [Types.LOAD_COMPANIES_FAILURE]: loadCompaniesFailure,

  [Types.LOAD_COMPANY_REQUEST]: loadCompanyRequest,
  [Types.LOAD_COMPANY_SUCCESS]: loadCompanySuccess,
  [Types.LOAD_COMPANY_FAILURE]: loadCompanyFailure,

  [Types.CREATE_COMPANY_REQUEST]: createCompanyRequest,
  [Types.CREATE_COMPANY_SUCCESS]: createCompanySuccess,
  [Types.CREATE_COMPANY_FAILURE]: createCompanyFailure,

  [Types.UPDATE_COMPANY_REQUEST]: updateCompanyRequest,
  [Types.UPDATE_COMPANY_SUCCESS]: updateCompanySuccess,
  [Types.UPDATE_COMPANY_FAILURE]: updateCompanyFailure,

  [Types.DISABLE_COMPANY_REQUEST]: disableCompanyRequest,
  [Types.DISABLE_COMPANY_SUCCESS]: disableCompanySuccess,
  [Types.DISABLE_COMPANY_FAILURE]: disableCompanyFailure,
});
