import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ZeptoNumberInput = props => {
  const {
    name,
    autoFocus,
    type,
    label,
    required,
    value,
    onChange,
    inputProps,
    errorMessage,
    disabled,
  } = props;

  return (
    <FormControl
      error={!!errorMessage}
      margin="normal"
      fullWidth
      variant="outlined"
    >
      <InputLabel required={required} variant="outlined">
        {label}
      </InputLabel>
      <OutlinedInput
        labelWidth={name.length * 10 + 5}
        name={name}
        value={value || ''}
        onChange={onChange}
        aria-describedby="input-text"
        type={type}
        autoFocus={autoFocus}
        inputProps={inputProps}
        inputComponent={NumberFormatCustom}
        disabled={disabled}
      />
      {errorMessage && (
        <FormHelperText id="input-text">{errorMessage}</FormHelperText>
      )}
    </FormControl>
    // <ZeptoTextField
    //   error={!!errorMessage}
    //   label={label}
    //   helperText={errorMessage}
    //   name={name}
    //   value={value || ''}
    //   onChange={onChange}
    //   required={required}
    //   autoFocus={autoFocus}
    //   type={type}
    //   inputProps={inputProps}
    //   disabled={disabled}
    //   inputComponent={NumberFormatCustom}
    // />
  );
};

ZeptoNumberInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  inputProps: PropTypes.shape({}),
};

export default ZeptoNumberInput;
