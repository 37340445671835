import React from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';
import ZeptoDateTimePicker from './ZeptoDateTimePicker';

const ZeptoFormTimePicker = (props) => {
  const {
    setValue, getValue, getErrorMessage, isPristine,
  } = props;
  const changeValue = (date) => {
    setValue(date ? date.format('DD/MM/YYYY HH:mm') : '');
  };
  const errorMessage = isPristine() ? '' : getErrorMessage();

  return (
    <ZeptoDateTimePicker
      {...props}
      value={getValue() || null}
      onChange={changeValue}
      errorMessage={errorMessage}
      error={!!errorMessage}
    />
  );
};

ZeptoFormTimePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  setValue: PropTypes.func,
  getValue: PropTypes.func,
  isPristine: PropTypes.func,
  getErrorMessage: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
};

export default withFormsy(ZeptoFormTimePicker);
