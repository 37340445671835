import React, { memo, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { goTo } from '../../operators/routesOperator';

const MobilePointForm = memo(props => {
  const { goTo, token } = props;

  function goToMe() {
    goTo('/me');
  }

  function registerPoint() {
    window.onMobileRegisterPoint = goToMe;
    window.onMobileRegisterPointCancel = goToMe;

    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'REGISTER_POINT', token })
    );
  }

  function onUmount() {
    window.onMobileRegisterPoint = undefined;
    window.onMobileRegisterPointCancel = undefined;
  }

  useEffect(() => {
    registerPoint();
    return onUmount;
  }, []);

  return <div>Carregando...</div>;
});

MobilePointForm.propTypes = {
  goTo: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  token: state.auth.token,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goTo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MobilePointForm);
