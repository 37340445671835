import { Creators as AppActions } from '../ducks/app';

export function showWarn(text) {
  return dispatch => {
    dispatch(AppActions.showWarn(text));
  };
}

export function showError(text) {
  return dispatch => {
    dispatch(AppActions.showError(text));
  };
}

export function showSuccess(text) {
  return dispatch => {
    dispatch(AppActions.showSuccess(text));
  };
}
