import { push } from 'react-router-redux';
import { Creators as SignupActions } from '../ducks/signup';
import { get, jsonPost } from '../utils/http';
import { Creators as AppActions } from '../ducks/app';
import { stripCNPJ } from '../utils/StringUtils';

const apiPath = `${process.env.REACT_APP_API_PATH}/api/signup`;

export function clearCNPJCheck() {
  return dispatch => {
    dispatch(SignupActions.clearCNPJCheck());
  };
}

export function clearUserCheck() {
  return dispatch => {
    dispatch(SignupActions.clearUserCheck());
  };
}

export function clearLastError() {
  return dispatch => {
    dispatch(SignupActions.clearLastError());
  };
}

export function checkCNPJ(cnpj) {
  return async dispatch => {
    dispatch(SignupActions.checkCNPJRequest());

    const result = await get(`${apiPath}/companies/${stripCNPJ(cnpj)}`);

    const { status } = result;

    if (status === 404) {
      dispatch(SignupActions.checkCNPJSuccess({ exists: false }));
    } else if (status === 204) {
      dispatch(SignupActions.checkCNPJFailure());
      dispatch(
        AppActions.showError(
          `Já existe uma empresa cadastrada com o CNPJ informado.`
        )
      );
    } else {
      dispatch(SignupActions.checkCNPJFailure());
      dispatch(
        AppActions.showError(`Ocorreu um erro ao validar o CNPJ informado.`)
      );
    }
  };
}

export function checkUser(user) {
  return async dispatch => {
    dispatch(SignupActions.checkUserRequest());

    const result = await get(`${apiPath}/users/?email=${user.email}`);

    const { status } = result;

    if (status === 404) {
      dispatch(SignupActions.checkUserSuccess({ exists: false }));
    } else if (status === 204) {
      dispatch(SignupActions.checkUserFailure());
      dispatch(
        AppActions.showError(
          `Já existe um usuário cadastrado com o email informado.`
        )
      );
    } else {
      dispatch(SignupActions.checkUserFailure());
      dispatch(
        AppActions.showError(`Ocorreu um erro ao validar o email informado.`)
      );
    }
  };
}

export function signup(data) {
  return dispatch => {
    dispatch(SignupActions.signupRequest());

    return jsonPost(apiPath, data)
      .then(() => {
        dispatch(SignupActions.signupSuccess());
        dispatch(
          AppActions.showSuccess(
            `Empresa cadastrada com sucesso. Você pode fazer login usando as credenciais informadas.`
          )
        );
        dispatch(push('/login'));
      })
      .catch(error => {
        dispatch(SignupActions.signupFailure(error));
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao cadastrar a empresa: ${error.message}`
          )
        );
      });
  };
}
