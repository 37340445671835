import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import 'react-html5-camera-photo/build/css/index.css';

import { LinearProgress } from '@material-ui/core';
import {
  registerPoint,
  clearLastPoint,
  clearLastError,
} from '../../operators/pointsOperator';
import ZeptoCamera from '../../components/ZeptoCamera';
import { ZeptoLocation } from '../../components';
import downscaleImage from '../../utils/ImageUtils';
import MobilePointForm from './MobilePointForm';
import { goTo } from '../../operators/routesOperator';

const PointForm = props => {
  const {
    processingRegisterPoint,
    lastPoint,
    lastError,
    goTo,
    clearLastPoint,
    clearLastError,
  } = props;

  const [state, setState] = useState({
    mobile: !!window.ReactNativeWebView,
  });

  const { photo, loadingLocation, mobile } = state;

  function onTakePhoto(photo) {
    setState({ ...state, photo, loadingLocation: true });
  }

  function onCancelPhoto() {
    const { goTo } = props;
    goTo('/me');
  }

  function onLoadLocation(position) {
    setState({
      ...state,
      loadingLocation: false,
    });

    const { registerPoint } = props;

    downscaleImage(state.photo).then(newData =>
      registerPoint({
        lat: position.coords.latitude,
        long: position.coords.longitude,
        dataUri: newData,
      })
    );
  }

  function onLoadLocationCancel() {
    setState({ ...state, loadingLocation: false });
  }

  useEffect(() => {
    if (lastPoint) goTo('/me');

    return clearLastPoint;
  }, [lastPoint]);
  useEffect(() => {
    if (lastError) goTo('/me');

    return clearLastError;
  }, [lastError]);

  if (mobile) return <MobilePointForm onCancel={onCancelPhoto} />;

  return (
    <Grid container spacing={2}>
      {processingRegisterPoint && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}
      {!photo && (
        <ZeptoCamera onTakePhoto={onTakePhoto} onCancel={onCancelPhoto} />
      )}

      {loadingLocation && (
        <ZeptoLocation
          onLoad={onLoadLocation}
          onCancel={onLoadLocationCancel}
        />
      )}
    </Grid>
  );
};

PointForm.propTypes = {
  params: PropTypes.shape({}).isRequired,
  processingRegisterPoint: PropTypes.bool,
  registerPoint: PropTypes.func.isRequired,
  lastPoint: PropTypes.instanceOf(Object),
  lastError: PropTypes.instanceOf(Object),
  goTo: PropTypes.func.isRequired,
  clearLastPoint: PropTypes.func.isRequired,
  clearLastError: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  processingRegisterPoint: state.points.processingRegisterPoint,
  lastPoint: state.points.lastPoint,
  lastError: state.points.lastError,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      registerPoint,
      clearLastPoint,
      clearLastError,
      goTo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PointForm);
