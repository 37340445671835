import React from 'react';
import moment from 'moment';
import { Chip, Typography, TableCell, Tooltip } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import Out from '@material-ui/icons/CallMade';
import In from '@material-ui/icons/CallReceived';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { RequestStatus } from '../../model/Request';

const useStyles = makeStyles({
  in: {
    color: 'green',
  },
  out: {
    color: 'red',
  },
  disabled: {
    opacity: 0.5,
  },
  chip: {
    fontSize: 10,
  },
});

const getLastRequestStatus = requests => {
  if (!requests) return null;
  if (requests.find(r => r.status === RequestStatus.open))
    return RequestStatus.open;
  if (requests.length > 0) {
    requests.sort((a, b) => {
      const momA = moment(a);
      const momB = moment(b);
      if (momA.isAfter(momB)) return 1;
      if (momA.isBefore(momB)) return -1;
      return 0;
    });
    if (requests[requests.length - 1].status === RequestStatus.rejected) {
      return RequestStatus.rejected;
    }
  }

  return null;
};

const PointItem = props => {
  const classes = useStyles();
  const { point, onPointClick, type } = props;
  const { requests } = point;
  const status = getLastRequestStatus(requests);

  return (
    <TableRow hover key={point.id} onClick={() => onPointClick(point)}>
      <TableCell padding="checkbox">
        {point.enabled && (
          <div>
            {type === 'IN' && <In className={classes.in} />}

            {type === 'OUT' && <Out className={classes.out} />}
          </div>
        )}
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography className={!point.enabled ? classes.disabled : ''}>
          {moment(point.timestamp).format('HH:mm')}
        </Typography>
      </TableCell>
      <TableCell align="right">
        {point.type === 'PRE_MARKED' && (
          <Tooltip title="Ponto Pré-assinalado">
            <Chip label="P" className={classes.chip} />
          </Tooltip>
        )}
        {status === RequestStatus.open && (
          <Chip label="PENDENTE" className={classes.chip} />
        )}

        {status === RequestStatus.rejected && (
          <Chip label="REJEITADO" color="secondary" className={classes.chip} />
        )}
      </TableCell>
    </TableRow>
  );
};

PointItem.propTypes = {
  point: PropTypes.shape({
    id: PropTypes.number,
    enabled: PropTypes.bool,
    timestamp: PropTypes.string,
    type: PropTypes.string,
    requests: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onPointClick: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default PointItem;
