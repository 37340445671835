import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import ResponsiveDrawer from './ResponsiveDrawer';
import AppTopBar from './AppTopBar';
import MenuStore from './MenuStore';
import { observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    'overflow-x': 'hidden',
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3 * 2))]: {
      padding: theme.spacing(3),
    },
  },
}));

function AppMenu(props) {
  const { children } = props;
  const classes = useStyles();

  const { mobileDrawerOpen, setMobileDrawerOpen } = useContext(MenuStore);
  const handleDrawerToggle = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  return (
    <div className={classes.root}>
      <AppTopBar onMenuClick={handleDrawerToggle} />
      <ResponsiveDrawer
        open={mobileDrawerOpen}
        onDrawerToggle={handleDrawerToggle}
      />

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

AppMenu.propTypes = {
  children: PropTypes.node,
};

export default observer(AppMenu);
