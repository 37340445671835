import React, { useEffect, memo } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { push } from 'react-router-redux';
import { Grid, Paper, Toolbar, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { ZeptoConfirmDialog } from '../../../components';
import { abreviate } from '../../../utils/StringUtils';

import {
  loadCompany,
  editCompany,
  disableCompany,
} from '../../../operators/companiesOperator';

import userIs from '../../../utils/permissionUtils';
import CompanyMenu from './CompanyMenu';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  fab: {
    margin: theme.spacing(2),
  },
}));

const CompaniesViewer = props => {
  const classes = useStyles();
  const [confirmOptions, setConfirmOptions] = React.useState({
    open: false,
  });

  const closeConfirmation = () => setConfirmOptions({ open: false });

  const {
    selected,
    processingLoadCompany,
    processingDisableCompany,
    children,
    loadCompany,
    params,
  } = props;

  /* #region Hooks */
  useEffect(() => {
    loadCompany(params.id);
  }, []);
  /* #endregion */
  /* #region Operations */

  const onEditCompany = () => {
    const { editCompany, selected } = props;
    editCompany(selected.id);
  };

  const onDisableCompanyConfirm = () => {
    const { disableCompany, selected } = props;
    disableCompany(selected.id);
    closeConfirmation();
  };

  const onDisableCompany = () => {
    const { selected } = props;
    setConfirmOptions({
      open: true,
      onConfirm: () => onDisableCompanyConfirm(),
      content: `Deseja realmente desabilitar a empresa '${selected.businnesName}'?`,
    });
  };

  /* #endregion */

  const loading = () => processingLoadCompany || processingDisableCompany;
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {loading() && <LinearProgress />}
        </Grid>

        {selected && (
          <>
            <Grid item xs={12} zeroMinWidth>
              <Paper>
                <Toolbar>
                  <Typography
                    component="h1"
                    variant="h5"
                    className={classes.title}
                  >
                    {abreviate(selected.businnesName, 100)}
                  </Typography>

                  {userIs('ADMIN') && (
                    <CompanyMenu
                      onEdit={onEditCompany}
                      onDisable={onDisableCompany}
                      disabled={loading()}
                    />
                  )}
                </Toolbar>
              </Paper>
            </Grid>
            <Grid item xs={12} zeroMinWidth>
              {children}
            </Grid>
          </>
        )}
      </Grid>
      <ZeptoConfirmDialog
        open={confirmOptions.open}
        onConfirm={confirmOptions.onConfirm}
        onCancel={closeConfirmation}
        content={confirmOptions.content}
      />
    </>
  );
};

CompaniesViewer.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  loadCompany: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    id: PropTypes.number.isRequired,
    businnesName: PropTypes.string.isRequired,
  }),
  editCompany: PropTypes.func.isRequired,
  disableCompany: PropTypes.func.isRequired,
  processingLoadCompany: PropTypes.bool,
  processingDisableCompany: PropTypes.bool,
  children: PropTypes.node,
};

const mapStateToProps = store => ({
  processingLoadCompany: store.companies.processingLoadCompany,
  processingDisableCompany: store.companies.processingDisableCompany,
  selected: store.companies.selected,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadCompany,
      editCompany,
      disableCompany,
      push,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(CompaniesViewer));
