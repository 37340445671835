import moment from 'moment';
import { Creators as ReportActions } from '../ducks/companyReports';
import { jsonFetchBlob } from '../utils/http';
import { Creators as AppActions } from '../ducks/app';

const apiPath = id =>
  `${process.env.REACT_APP_API_PATH}/api/companies/${id}/reports`;

export function generateAFD(companyId, ids, start, end) {
  const st = start ? moment(start).format('YYYY-MM-DD') : '';
  const en = end ? moment(end).format('YYYY-MM-DD') : '';

  return dispatch => {
    dispatch(ReportActions.generateAFDRequest());

    return jsonFetchBlob(
      `${apiPath(companyId)}/afd?ids=${ids}&start=${st}&end=${en}`
    )
      .then(file => {
        dispatch(ReportActions.generateAFDSuccess(file));

        const url = window.URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'AFD';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      })
      .catch(error => {
        dispatch(ReportActions.generateAFDFailure());
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao gerar o relatório: ${error.message}`
          )
        );
      });
  };
}

export function generateAFDT(companyId, ids, start, end) {
  const st = start ? moment(start).format('YYYY-MM-DD') : '';
  const en = end ? moment(end).format('YYYY-MM-DD') : '';
  return dispatch => {
    dispatch(ReportActions.generateAFDTRequest());

    return jsonFetchBlob(
      `${apiPath(companyId)}/afdt?ids=${ids}&start=${st}&end=${en}`
    )
      .then(file => {
        dispatch(ReportActions.generateAFDTSuccess(file));

        const url = window.URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'AFDT';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      })
      .catch(error => {
        dispatch(ReportActions.generateAFDTFailure());
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao gerar o relatório: ${error.message}`
          )
        );
      });
  };
}

export function generateACJEF(companyId, ids, start, end) {
  const st = start ? moment(start).format('YYYY-MM-DD') : '';
  const en = end ? moment(end).format('YYYY-MM-DD') : '';
  return dispatch => {
    dispatch(ReportActions.generateACJEFRequest());

    return jsonFetchBlob(
      `${apiPath(companyId)}/acjef?ids=${ids}&start=${st}&end=${en}`
    )
      .then(file => {
        dispatch(ReportActions.generateACJEFSuccess(file));

        const url = window.URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'ACJEF';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      })
      .catch(error => {
        dispatch(ReportActions.generateACJEFFailure());
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao gerar o relatório: ${error.message}`
          )
        );
      });
  };
}

export function generateAgreementTerm(companyId, ids) {
  return dispatch => {
    dispatch(ReportActions.generateAgreementTermRequest());

    return jsonFetchBlob(`${apiPath(companyId)}/agreement-term?ids=${ids}`)
      .then(file => {
        dispatch(ReportActions.generateAgreementTermSuccess(file));

        const url = window.URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Termo de aceite.pdf';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      })
      .catch(error => {
        dispatch(ReportActions.generateAgreementTermFailure());
        dispatch(
          AppActions.showError(
            `Ocorreu um erro ao gerar o relatório: ${error.message}`
          )
        );
      });
  };
}
