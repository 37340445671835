import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { Menu, makeStyles, MenuItem, Fab } from '@material-ui/core';

const ITEM_HEIGHT = 48;
const useStyles = makeStyles(theme => ({
  paper: {
    maxHeight: ITEM_HEIGHT * 4.5,
  },
  fab: {
    margin: theme.spacing(1.5),
    minWidth: 40,
  },
}));

const CompanyMenu = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const { onEdit, onDisable, disabled } = props;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const close = () => {
    setAnchorEl(null);
  };

  const onEditClick = () => {
    onEdit();
    close();
  };

  const onDisableClick = () => {
    onDisable();
    close();
  };

  return (
    <>
      <Fab
        className={classes.fab}
        aria-haspopup="true"
        onClick={handleClick}
        color="primary"
        size="small"
        disabled={disabled}
      >
        <MoreVertIcon />
      </Fab>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={close}
        PaperProps={{
          className: classes.paper,
        }}
      >
        <MenuItem onClick={onEditClick}>Editar</MenuItem>
        <MenuItem onClick={onDisableClick}>Desabilitar</MenuItem>
      </Menu>
    </>
  );
};

CompanyMenu.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default memo(CompanyMenu);
