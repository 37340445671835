import { AppBar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React, { memo } from 'react';
import { ZeptoButton } from '../../components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    'overflow-x': 'hidden',
  },
  appBar: {
    // width:: theme.zIndex.drawer + 1,
  },
}));

const UnloggedTopBar = memo(props => {
  const { children } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} color="default">
        <Toolbar>
          <a href="/" style={{ textDecoration: 'none' }}>
            <ZeptoButton
              label="Acessar o Site"
              color="default"
              startIcon={<ArrowBackIosIcon />}
              variant="outlined"
            />
          </a>
        </Toolbar>
      </AppBar>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
});

UnloggedTopBar.propTypes = {};

export default UnloggedTopBar;
