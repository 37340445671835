import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import 'moment/locale/pt-br';
import setUpRules from './js/utils/ValidatorUtils';
import App from './js/views/App';

setUpRules();

/* #region Render */
ReactDOM.render(<App />, document.getElementById('root'));
/* #endregion */

/* #region Service worker */
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
/* #endregion */
